<!-- Hero Section End -->
<gallery id="mixedExample" *ngIf="galleryConfig$ | async; let config" 
[thumbWidth]="config.thumbWidth"
[thumbHeight]="config.thumbHeight"
[thumbPosition]="config.thumbPosition" [thumbTemplate]="thumbTemplate" [itemTemplate]="itemTemplate" ></gallery>

<!-- Add custom template to thumbnails -->
<ng-template #thumbTemplate let-type="type">
  <span *ngIf="type === 'youtube'" class="item-type">
    <fa-icon [icon]="youtubeIcon" size="lg"></fa-icon>
  </span>
  <span *ngIf="type === 'video'" class="item-type">
    <fa-icon [icon]="videoIcon" size="lg"></fa-icon>
  </span>
</ng-template>

<!-- Add custom template to image items -->
<ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
  <div class="f-text" style="    font-size: 10px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #E1481F; 
  padding: 4px 15px;
  float: left;">Sponsorisé</div> 
  <!-- <span *ngIf="type === 'image' && index === currIndex" [@slideAnimation] class="item-text"> -->
    <span *ngIf="type === 'image' && index === currIndex" [@enterAnimationVetically] class="item-text"> 
    
 
     
    <div _ngcontent-sut-c68="" class=" "  >
      <div _ngcontent-sut-c68="" class="pd-hero-text">
        <p _ngcontent-sut-c68="" class="room-location"><i _ngcontent-sut-c68="" class="icon_pin"></i> {{data?.address}}</p>
        <h2 _ngcontent-sut-c68=""> {{data?.title}}</h2>
        <div _ngcontent-sut-c68="" class="room-price"><span _ngcontent-sut-c68="">Coût {{data?.libeleadjectif}}:</span>
          <p _ngcontent-sut-c68="">{{data?.price| currency:'XAF'}}</p>
        </div>
        <ul _ngcontent-sut-c68="" class="room-features">
          <li _ngcontent-sut-c68=""><i _ngcontent-sut-c68="" class="fa fa-arrows"></i>
            <p _ngcontent-sut-c68="">{{data?.area}} m<sup>2</sup></p>
          </li> 
          <li _ngcontent-sut-c68=""><i _ngcontent-sut-c68="" class='fas fa-couch'></i>
            <p _ngcontent-sut-c68="">{{data?.nbsalon}} Salon(s)</p>
          </li>
          <li _ngcontent-sut-c68=""><i _ngcontent-sut-c68="" class="fa fa-bed"></i>

            <p _ngcontent-sut-c68="">{{data?.nbbedroom}} Chambre(s)</p>
          </li>
          <li _ngcontent-sut-c68=""><i _ngcontent-sut-c68="" class="fa fa-bath"></i>
            <p _ngcontent-sut-c68="">{{data?.nbbath}} Douche(s)</p>
          </li>
          <li _ngcontent-sut-c68=""><i _ngcontent-sut-c68="" class="fa fa-car"></i>
            <p _ngcontent-sut-c68="">{{data?.nbgarage?'0':'1'}} Parking</p>
          </li>
        </ul>
      </div>
    </div>
   
  </span>
<br>
  <button type="submit" class="site-btn"  (click)="gotoproperty(data?.idbienimmo)"><i _ngcontent-sut-c68="" class="fa fa-eye"></i> Voir détails</button>
</ng-template>