<!--Feature Section Begin -->
<!-- <section class="blog-details-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">
          <span>TYPES DE PROPRIETES IMMOBILIERES</span>
          <h2>Top Catégories les plus recherchées {{mois}} </h2>
        </div>
      </div>
    </div> 
     <div class="row">
       <div class="col-lg-10 offset-lg-1">
           <div class="blog-details-pic cat">
               <div class="bd-pic-item small-item">
                    <a [routerLink]="['/properties','cat', '1']" class="cat1">
                        <img src="./assets/img/categories/logement.jpg" />
                   </a>
                </div>
               <div class="bd-pic-item small-item set-bg"><a [routerLink]="['/properties','cat', '6']"><img src="./assets/img/categories/terrain1.jpg" /></a></div>
               <div class="bd-pic-item large-item set-bg" data-setbg="./"><a [routerLink]="['/properties','cat','2']"><img src="./assets/img/categories/bureau.jpg" /></a></div>
               <div class="bd-pic-item wide-item set-bg"  data-setbg=""><a [routerLink]="['/properties','cat','3']"><img src="./assets/img/categories/meuble.jpg" /></a></div>
               <div class="bd-pic-item small-item set-bg"><a [routerLink]="['/properties','cat', '5']"><img src="./assets/img/categories/sallefete.jpg" /></a></div>
               <div class="bd-pic-item small-item set-bg"><a [routerLink]="['/properties','cat', '4']"><img src="./assets/img/categories/magasin.jpeg" /></a></div>
               <div class="bd-pic-item small-item set-bg"><a [routerLink]="['/properties','cat', '7']"><img src="./assets/img/categories/garage2.jpg" /></a></div>
            </div>
       </div>
     </div>
 </div>
 </section> -->



<!--Feature Section Begin -->

<!--Explore Start
<nb-layout>
  <nb-layout-header fixed>
    <nb-search type="rotate-layout"></nb-search>
  </nb-layout-header>
  <nb-sidebar>
  </nb-sidebar>

  <nb-layout-column class="colored-column-basic">
    <nb-card accent="info">
      <nb-card-header>You searched for:</nb-card-header>
      <nb-card-body>
        <h3>{{ value || '-' }}</h3>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>-->
<section class="explore_two" style="background-image: url( )">
  <div class="container">
    <div class="col-lg-12">
      <div class="section-title">
        <span>Parcourez vos biens en fonction des types de logements</span>
        <h2>Parcourir par categories</h2>
      </div>
    </div>
    <div class="explore_two_bottom">
      <div class="row">
        <div class="col-xl-6 col-lg-6" *ngFor="let cat of Categories | slice:0:2">
          <!--Explore Two Bottom Single--> 
          <div class="explore_two_top_single cat">
            <div class="explore_two_top_img">
              <img [src]="cat?.photocategorie" [alt]="cat?.descriptioncategorie">
              <div class="explore_two_top_text">
                <p><a [routerLink]="['/properties/categorie/', cat?.idcategorie]">{{cat.nomcategorie}}</a></p>
              </div>
              <div class="explore_two_top_properties">
                <!--<button type="submit" class="site-btn" ><i _ngcontent-sut-c68="" class="fa fa-eye"></i> 2 biens</button>-->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="explore_two_top">
      <div class="row">
        <div class="col-xl-4 col-lg-4" *ngFor="let cat of Categories | slice:2:5">
          <!--Explore Two Top single-->
          <div class="explore_two_top_single cat">
            <div class="explore_two_top_img">
              <img [src]="cat?.photocategorie" [alt]="cat?.descriptioncategorie">
              <div class="explore_two_top_text">
                <p><a [routerLink]="['/properties/categorie/', cat?.idcategorie]">{{cat.nomcategorie}}</a></p>
              </div>
              <div class="explore_two_top_properties">
                <!--<button type="submit" class="site-btn" ><i _ngcontent-sut-c68="" class="fa fa-eye"></i> 2 biens</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="explore_two_bottom">
      <div class="row">
        <div class="col-xl-6 col-lg-6" *ngFor="let cat of Categories | slice:5:7">
          <!--Explore Two Bottom Single--> 
          <div class="explore_two_top_single cat">
            <div class="explore_two_top_img">
              <img [src]="cat?.photocategorie" [alt]="cat?.descriptioncategorie">
              <div class="explore_two_top_text">
                <p><a [routerLink]="['/properties/categorie/', cat?.idcategorie]">{{cat.nomcategorie}}</a></p>
              </div>
              <div class="explore_two_top_properties">
                <!--<button type="submit" class="site-btn" ><i _ngcontent-sut-c68="" class="fa fa-eye"></i> 2 biens</button>-->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<!--Explore End-->




<!-- Feature Properties -->
<!-- <section id="feature-property" class="feature-property">
 
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="main-title text-center">
          <h2>Find Properties in These Cities</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
     
      <div class="row"  >
      <div class="{{((math.floor(num/2))%2==0 && num%2==0)||((math.floor(num/2))%2==1 && num%2==1)?'col-xl-6 col-lg-6':'col-xl-6 col-lg-6'}}" *ngFor="let cat of Categories; let num = index; let num2 = index;" >
        <div class="properti_city home5">
          <div class="thumb"><img class="img-fluid w100" [src]="cat?.photocategorie" alt="pc1.jpg"></div>
          <div class="overlay">
            <div class="details">
              <div class="left"><h4>Miami</h4></div>
              <p>{{(math.floor(num/2))%2}} {{num%2}} {{((math.floor(num/2))%2==0 && num%2==0)||((math.floor(num/2))%2==1 && num%2==1)}}Properties</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row" > 
      <div class="col-lg-8 col-xl-8">
        <div class="properti_city home5">
          <div class="thumb"><img class="img-fluid w100" src="https://creativelayers.net/themes/findhouse-html/images/property/pc3.jpg" alt="pc3.jpg"></div>
          <div class="overlay">
            <div class="details">
              <div class="left"><h4>New York</h4></div>
              <p>89 Properties</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4">
        <div class="properti_city home5">
          <div class="thumb"><img class="img-fluid w100" src="https://creativelayers.net/themes/findhouse-html/images/property/pc4.jpg" alt="pc4.jpg"></div>
          <div class="overlay">
            <div class="details">
              <div class="left"><h4>Florida</h4></div>
              <p>47 Properties</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

Property Cities -->