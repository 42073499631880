import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.css']
})
export class CallComponent implements OnInit {
  serviceclient={
    nom:"Service client easyHome",
    pays:"Cameroun",
    indicepays:"+237",
    phone:"670912166/",
    whatsapp:"https://wa.me/670912166"
  };
  constructor() { }

  ngOnInit(): void {
  }

}
