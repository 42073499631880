<!-- Property Details Hero Section Begin -->
<div *ngFor="let image2d of Bien?.image2ds | slice:0:1">
    <section class="pd-hero-section set-bg" attr.data-setbg="{{image2d?.srcimg}}"
            [ngStyle]="{'background-image': 'url(' + image2d?.srcimg + ')'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="pd-hero-text">
                        <p class="room-location"><i class="icon_pin"></i>{{Bien?.ville?.nomville}} - {{Bien?.quartier?.nomquartier}}</p>
                        <h2> {{Bien?.designation}} {{Bien?.estmoderne?'Moderne':'Simple'}}</h2>
                        <div class="room-price">
                            <span>Coût {{Bien?.unitecout?.libeleadjectif}} : </span>
                            <p>{{Bien?.coutloyermensuel| currency:'XAF'}} </p>
                        </div>
                        <ul class="room-features">
                            <li>
                                <i class="fa fa-arrows"></i>
                                <p>{{Bien?.espacelocatif}} m<sup>2</sup></p>
                            </li>
                            <li>
                                <i class='fas fa-couch'></i>
                                <p>{{Bien?.nbrsalon}} Salon(s)</p>
                            </li>
                            <li>
                                <i class="fa fa-bed"></i>
                                <p>{{Bien?.nbrchambreacoucher}} Chambre(s)</p>
                            </li>
                            <li>
                                <i class="fa fa-bath"></i>
                                <p>{{Bien?.nbrsallebain}} Salle(s) de bain</p>
                            </li>
                            <li>
                                <i class="fa fa-car"></i>
                                <p>{{(Bien?.parking || Bien?.garage)?'1':'0'}} Garage/Parking</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>