
<!-- Property Section Begin -->
<!--
<section class="property-details-section spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <app-side-search></app-side-search>
            </div>
            <div class="col-lg-9">
                <div class="pd-details-text">      
                    <div class="pd-details-tab">
                        <div class="tab-item">
                            <ul class="nav" role="tablist">
                                <li>
                                    <a class="active" data-toggle="tab" href="#tab-1" role="tab">Overview</a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#tab-2" role="tab">Tableau</a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#tab-3" role="tab">
                                        Liste</a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-item-content">
                            <div class="tab-content">
                                <div class="tab-pane fade-in active" id="tab-1" role="tabpanel"> 
                                        <div class="row">
                                                <div  class= "col-md-4 col-lg-4 mb-4"  *ngFor="let bien of Biens | slice:0:10">
                                                    <div class="feature-carousel" style="margin-top: 25px;">
                                                        <div class="feature-item" >
                                                            <app-poperty-card [top]="bien"></app-poperty-card>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                </div>
                                <div class="tab-pane fade" id="tab-2" role="tabpanel">
                                        <section class="outbreak-area">
                                            <div class="container">
                                                <div class="outbreak-content">
                                                    <img src="assets/img/banner-map.png" alt="image">
                                                  
                                                    <div class="table-responsive table-hover">
                                                        <table id="outbreakTable" class="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 5px;">N°</th>
                                                                    <th>Vue</th>
                                                                    <th>Type</th>
                                                                    <th>Catégorie</th>
                                                                    <th>Ville</th>
                                                                    <th>Quartier</th>
                                                                    <th>Détails</th>
                                                                    <th>Prix</th>
                                                                    <th>Superficie</th>
                                                                    <th>Contrat</th>
                                                                    <th>Etat</th>
                                                                    <th>Plus d'Infos</th>
                                                                </tr>
                                                            </thead> 
                                                            <tbody>
                                                                <tr *ngFor="let bien of TopProprietes | slice:0:10">
                                                                    <td style="width: 5px;"><a href="#">{{bien.id}}</a></td>
                                                                    <td><img class="profiler" [src]="bien.img" /></td>
                                                                    <td class="new_case">{{bien.type}}</td>
                                                                    <td>{{bien.subcat}}</td>
                                                                    <td class="new_death">{{bien.ville}}</td>
                                                                    <td>{{bien.quartier}}</td>
                                                                    <td>{{bien.details}}</td>
                                                                    <td>{{bien.prix}}</td>
                                                                    <td>{{bien.superficie}}</td>
                                                                    <td>{{bien.contrat}}</td>
                                                                    <td>{{bien.etat}}</td>
                                                                    <td><a [routerLink]="['/property', bien.id]">Plus d'Infos</a></td> 
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                </div>
                                <div class="tab-pane fade" id="tab-3" role="tabpanel">
                                   
                                    <div class="top-properties-section spad">
                                        <div class="container">
                                            <div class="top-properties-carousel owl-carousel">  
                                                <property-card-list *ngFor="let prop of TopProprietes | slice:0:10" [bien]="prop" style="height: 200px;"></property-card-list>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- Property Details Section End -->

<!--
<tableau></tableau>-->

<!--Début du tab panel nbPopover="Ranger par card" nbPopoverTrigger="hover"-->
<nb-layout>
    <nb-sidebar responsive style="z-index: 9250px; background-color: rgba(250, 244, 244, 0);">
        <app-side-search></app-side-search>
    </nb-sidebar>
    <nb-layout-column  class="colored-column-info">   
            <nb-card [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="giant">
                <nb-card-body>
            
                <nb-tabset>
                    <nb-tab tabIcon="grid-outline" >
                        <div class="row" infinite-scroll  >
                            <div  class= "col-md-4 col-lg-4 mb-4"  *ngFor="let bien of Biens | slice:0:10">
                                <div class="feature-carousel" style="margin-top: 25px;">
                                    <div class="feature-item" >
                                        <app-poperty-card [top]="bien"></app-poperty-card>
                                    </div>
                                </div>
                            </div>
                        </div><hr>
                        <h5> {{msgresult}} <b *ngIf="nbresult>0">{{nbresult}}</b></h5>
                    </nb-tab>
                    <nb-tab tabIcon="keypad-outline">
                        <div class="table-responsive table-hover">

                            <!--<table id="outbreakTable" class="table table-striped table-bordered mt-1">
                                <thead>
                                    <tr>
                                        <th style="width: 5px;">N°</th>
                                        <th>Apperçu</th>
                                        <th>Type</th>
                                        <th>Catégorie</th>
                                        <th>Ville</th>
                                        <th>Quartier</th>
                                        <th>Détails</th>
                                        <th>Prix</th>
                                        <th>Superficie</th>
                                        <th>Contrat</th>
                                        <th>Etat</th>
                                        <th>Plus d'Infos</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr *ngFor="let bien of Biens | slice:0:10">
                                        <td style="width: 5px;"><a href="#">{{bien.idbienimmo}}</a></td>
                                        <td *ngFor="let image2d of bien.image2ds | slice:0:1"><a [routerLink]="['/property', bien.idbienimmo]"><img class="profiler" [src]="image2d.srcimg" /></a></td>
                                        <td class="new_case">{{bien.category?.nomcategorie}} : {{bien.designation}}</td>
                                        <td>{{bien.typelogement?.nomtypelogement}} - {{bien.estmoderne?'Moderne':'Simple'}}</td>
                                        <td class="new_death">{{bien.ville?.nomville}}</td>
                                        <td>{{bien.quartier?.nomquartier}}</td>
                                        <td>{{bien.courtedescription}}</td>
                                        <td>{{bien.coutloyermensuel}}</td>
                                        <td>{{bien.niveauaccessibilite}} m<sup>2</sup></td>
                                        <td>{{bien.avendre ? 'à vendre':'à louer'}}</td>
                                        <td>{{bien.nouveaute? 'Nouveau':'Occupé'}}</td>
                                        <td>
                                            <a [routerLink]="['/property', bien.idbienimmo]">  
                                                <nb-icon icon="eye-outline" status="info" nbTooltip="voir détails" nbTooltipIcon="home-outline" nbTooltipStatus="success"></nb-icon>
                                            </a>
                                        </td> 
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr> <td colspan="12"><h5> {{msgresult}} <b *ngIf="nbresult>0">{{nbresult}}</b></h5></td>  </tr>
                                </tfoot>
                            </table>-->
                            <div id="layoutSidenav_content">
                                <main>
                                    <div class="container-fluid px-4">
                                 
                                        <div class="card mb-4">
                                            <div class="card-body">
                                                <table id="outbreakTable" class="table table-striped table-bordered mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 5px;">N°</th>
                                                            <th>Apperçu</th>
                                                            <th>Type</th>
                                                            <th>Catégorie</th>
                                                            <th>Ville</th>
                                                            <th>Quartier</th>
                                                            <th>Détails</th>
                                                            <th>Prix</th>
                                                            <th>Superficie</th>
                                                            <th>Contrat</th>
                                                            <th>Etat</th>
                                                            <th>Plus d'Infos</th>
                                                        </tr>
                                                    </thead> 
                                                    <tfoot>
                                                        <tr>
                                                            <th style="width: 5px;">N°</th>
                                                            <th>Apperçu</th>
                                                            <th>Type</th>
                                                            <th>Catégorie</th>
                                                            <th>Ville</th>
                                                            <th>Quartier</th>
                                                            <th>Détails</th>
                                                            <th>Prix</th>
                                                            <th>Superficie</th>
                                                            <th>Contrat</th>
                                                            <th>Etat</th>
                                                            <th>Plus d'Infos</th>
                                                        </tr>
                                                    </tfoot> 
                                                    <tbody>
                                                        <tr *ngFor="let bien of Biens | slice:0:10">
                                                            <td style="width: 5px;"><a [routerLink]="['/property', bien.idbienimmo]">{{bien.idbienimmo}}</a></td>
                                                            <td *ngFor="let image2d of bien.image2ds | slice:0:1"><a [routerLink]="['/property', bien.idbienimmo]"><img class="profiler" [src]="image2d.srcimg" /></a></td>
                                                            <td class="new_case">{{bien.category?.nomcategorie}} : {{bien.designation}}</td>
                                                            <td>{{bien.typelogement?.nomtypelogement}} - {{bien.estmoderne?'Moderne':'Simple'}}</td>
                                                            <td class="new_death">{{bien.ville?.nomville}}</td>
                                                            <td>{{bien.quartier?.nomquartier}}</td>
                                                            <td>{{bien.courtedescription}}</td>
                                                            <td>{{bien.coutloyermensuel}}</td>
                                                            <td>{{bien.niveauaccessibilite}} m<sup>2</sup></td>
                                                            <td>{{bien.avendre ? 'à vendre':'à louer'}}</td>
                                                            <td>{{bien.nouveaute? 'Nouveau':'Occupé'}}</td>
                                                            <td>
                                                                <a [routerLink]="['/property', bien.idbienimmo]">  
                                                                    <nb-icon icon="eye-outline" status="info" nbTooltip="voir détails" nbTooltipIcon="home-outline" nbTooltipStatus="success"></nb-icon>
                                                                </a>
                                                            </td> 
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                                <br><hr><h5> {{msgresult}} <b *ngIf="nbresult>0">{{nbresult}}</b></h5>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </nb-tab>
                    <nb-tab tabIcon="list-outline">
                        <div class="top-properties-section spad">
                            <div class="container">
                                <div class="top-properties-carousel owl-carousel">  
                                <div class="card">
                                    <property-card-list *ngFor="let prop of Biens | slice:0:10"  [bien]="prop" ></property-card-list>
                                </div>
                                <h5> {{msgresult}} <b *ngIf="nbresult>0">{{nbresult}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </nb-tab>
                </nb-tabset>
            
                </nb-card-body>
            </nb-card>

        <app-footer></app-footer>
    </nb-layout-column>
  </nb-layout>

