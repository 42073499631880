import { Directive } from '@angular/core';

@Directive({
  selector: '[appCountUp]'
})
export class CountUpDirective {

  constructor() { }

}
