import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import * as Rx from "rxjs/Rx";
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';  
import { AppglobalService } from './appglobal.service';
import { Bienimmobilier } from '.././models/bienimmobilier';//Typelogement


@Injectable({
  providedIn: 'root'
})
export class BiensService {
  
  constructor(private http: HttpClient, private lien: AppglobalService) {

  }


  //Récuperation de tout les bioenbs immo
  getbiensimmobiliers(): Observable<any> {
    return this.http
      .get<Bienimmobilier[]>(this.lien.ApiUrl + 'bienimmobiliers')
  }

  //Récuperation d'un seul bien par identifiant
  getOnebiensimmobiliers(id: number): Observable<any> {
    return this.http
      .get<Bienimmobilier>(this.lien.ApiUrl + 'bienimmobiliers/' + id);
  }

  //Récuperation des biens en promotion
  getbiensEnpromotion(): Observable<any> {
    ////console.log(this.lien.ApiUrl+'filtrebienimmobiliers');
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        enprommotion: true,
      }))
      .map((data: Bienimmobilier[]) => data);
  }
  //Récuperation des biens les plus recent
  getbiensNouveaute(): Observable<any> {
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        nouveaute: true,
      }))
      .map((data: Bienimmobilier[]) => data);
  }
  getbiensByville(id: number): Observable<any> {
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        ville: id,
      }))
      .map((data: Bienimmobilier[]) => data);
  }
  getbiensBycategories(id: number): Observable<any> {
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        category: id,
      }))
      .pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError('Something went wrong!');
        })
      );
  }
  getbiensBytypelogement(id: number): Observable<any> {
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        typelogement: id,
      }))
      .map((data: Bienimmobilier[]) => data);
  }
  //Récuperation des biens relatifs à un bien dont on a consulté les détails
  getRelatedbiensimmobiliers(B): Observable<any> {
    //console.log("Param Biens relatifs à un bien dont on a consulté les détails");//console.log(B)
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify(B))
      .map((data: Bienimmobilier[]) => data);
  }
   //Récuperation des biens relatifs à un bien dont on a consulté les détails
   getBiendisponible(dispo): Observable<any> {
        return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
          JSON.stringify({
            estdisponible : true, 
          }))
          .map((data: Bienimmobilier[]) => data);
  }
  //
  getBienparTypelogement(id): Observable<any> {
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl + 'filtrebienimmobiliers',
      JSON.stringify({
        typelogement : id, 
      }))
      .map((data: Bienimmobilier[]) => data);
}
}
