import {ChangeDetectionStrategy, Component,Inject, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import {Router, ActivatedRoute} from '@angular/router'
import { DOCUMENT } from '@angular/common';
import {AppglobalService} from '../../services/appglobal.service';
import {SearchService} from '../../services/search.service'
import {Search} from '../../models/search';
import {Bienimmobilier} from '../../models/bienimmobilier';
import {HomeSearchComponent} from '../../layouts/home-search/home-search.component'
@Component({
  selector: 'app-search-result',
  changeDetection: ChangeDetectionStrategy.OnPush, 
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
/*changeDetection: ChangeDetectionStrategy.OnPush, */
export class SearchResultComponent implements OnInit {
  public TopProprietes = [];
  public searchData:Search;
  public resultSearch:Bienimmobilier[];
  public datafound:boolean=false; 
  public mois; loading=true; error='';
  msgresult = "Aucun Bien trouvé !!!!!";
  nbresult = 0;
 
  constructor(private searchRep:SearchService,
               @Inject(DOCUMENT) private _document: Document,
                private gData:AppglobalService,
                private router:Router,
                private _Activatedroute:ActivatedRoute)
           { 
            this._Activatedroute.paramMap.subscribe(params => {
                    this.ngOnInit();
                  });
  }
  ngOnInit(): void {
    
    this.ResultatsSearch();
   
  }
  

  refreshPage() {
    this._document.defaultView.location.reload();
    //@Inject(DOCUMENT) private _document: Document,
    //import { DOCUMENT } from '@angular/common'; this.router.navigate(['/search']);//this.reloadComponent();
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  
  toggleLoadingAnimation() {
    this.loading = true;
    setTimeout(() => this.loading = false, 6000);
  }

private ResultatsSearch(){
  let error = "";
  let datas = JSON.parse(localStorage.getItem("searchData"));
  this.resultSearch = [];
  this.searchRep
        .rechercher(datas)
          .subscribe(
            (response) => {  
                this.resultSearch = response; 
                document.body.scrollTop = 1; // For Safari
                document.documentElement.scrollTop = 1;
                this.configMsg();    
            },
            (error) => {                              //error() callback
              //console.error('Search Request failed with error'+error);
              error = error;
              this.loading = false;
            },
            () => {
              //console.error('Search Request completed') ;   
              if(this.resultSearch.length>0){
                this.datafound = true;
              }else{
                this.datafound = false;
              }
              this.loading = false; 
            })
}

configMsg(){
  if(this.resultSearch.length==0){
    this.msgresult = "Aucun Bien trouvé !!!!!";
    this.nbresult = 0;
  }else{
    this.msgresult = "Nombre total de biens trouvés est  :  ";
    this.nbresult = this.resultSearch.length;
  }
}
}
