
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators'; 
import { NbIconConfig , NbSearchService } from '@nebular/theme';
import { NbMenuItem } from '@nebular/theme';
import { Router, Event,ActivatedRoute, ParamMap } from '@angular/router';
import {AppglobalService} from '../../services/appglobal.service';
import {CategoriesService} from '../../services/categories.service';
import {BiensService} from '../../services/biens.service';
import { Categorie } from '../../models/categorie';
import { Typelogement } from '../../models/typelogement';
import { Ville } from '../../models/ville';
import { Quartier } from '../../models/quartier';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { Observable, throwError } from 'rxjs'; 

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent implements OnInit {
  nomuser="";
  loading;error;
  items = [ 
    { title: 'Profile' },
    { title: 'Déconnexion'},
  ];
  public  Typelogement =[];
  itemlogements = [];
  itemmeubles = [];
 public  Categories =[];
  itemproprietes = [];
  public  Villes =[];
  public BiensProperties = [];
  itemsvilles = [];
  disabledIconConfig: NbIconConfig = { icon: 'settings-2-outline', pack: 'eva' };
  value = '';
  constructor(private gsvc:AppglobalService , 
              private http: HttpClient,
              private router: Router,
              private nbMenuService: NbMenuService, 
               @Inject(NB_WINDOW) private window,
               private catRep:CategoriesService,
               private bienRep:BiensService,private searchService: NbSearchService) {

                //recherche textuelle
                this.searchService.onSearchSubmit()
                                  .subscribe((data: any) => {
                                    this.value = data.term;
                                    localStorage.setItem("textData", this.value);
                                    this.router.navigate(['/research']);
                })//window.alert(this.value);

                    //recuperation de l'utilisateur connecté
              this.userdatas = JSON.parse(JSON.stringify(localStorage.getItem("user")));
              if(this.userdatas && this.userdatas.token){
                this.router.events.subscribe((event: Event) => {
                  this.nomuser = this.userdatas.user.username;
                  window.alert('Online user : '+this.nomuser);
                  this.router.onSameUrlNavigation;
                });
                
                
              }
  
  }
  itemsmenu1: NbMenuItem[] = [
    {
      title: 'Accueil',
      icon: '',
    }
  ];
  itemsmenu2: NbMenuItem[] = [
    {
      title: 'Logements',
      icon: '',
    }
  ];
  itemsmenu22: NbMenuItem[] = [
    {
      title: 'Espace Meublé',
      icon: '',
    }
  ];
  itemsmenu3: NbMenuItem[] = [
    {
      title: 'Biens Immobiliers',
      icon: '',
    }
  ];

  itemsmenu4: NbMenuItem[] = [
    {
      title: 'Villes',
      icon: '',
    }
  ];
  itemsmenu5: NbMenuItem[] = [
    {
      title: 'Blog',
      icon: '',
    }
  ];
  itemsmenu6: NbMenuItem[] = [
    {
      title: 'Contact',
      icon: '',
    }
  ];
  itemsmenu7 : NbMenuItem[]  = [
    {title : "FAQ\'S", icon: ''}
  ]
  public userdatas;


  ngOnInit() {
   
    this.itemsvilles = this.gsvc.MenuVilles;
    this.itemproprietes = this.gsvc.Cathegories;
    this.itemlogements = this.gsvc.Categories;
    this.itemmeubles = this.gsvc.MenuMeubles;


    window.onload = () => {
      this.formatMeubles();
      this.formatTypeslogements();  
      this.formatCategories(); 
      this.formatVilles();
    }
    
    //recuperation de l'utilisateur connecté
    let datas = JSON.parse(JSON.stringify(localStorage.getItem("user")));
    if(datas && datas.token){
      this.nomuser = datas.user.username;
    }

    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'context-user'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => {
      this.logout();
    });


    //recupération du sous-menu des logements
    this.nbMenuService.onItemHover()
    .pipe(
      filter(({ tag }) => tag === 'context-logements'),
         map(({ item: { link } }) => link),
    )
    .subscribe(link => {
      //this.window.alert(`logement: ${link} was clicked!`);
      //this.getPropertiesByTypelogement(link);

    });
      //recupération du sous-menu des logements
      this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'context-meubles'),
        map(({ item: { link } }) => link),
      )
      .subscribe(link => {
        //this.window.alert(`logement: ${link} was clicked!`);
        //this.getPropertiesByTypelogement(link);
  
      });

 //recupération du sous-menu des Catégories ou Proprietés
    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'context-proprietes'),
      map(({ item: { link } }) => link),
    )
    .subscribe(link => {
      
      //this.window.alert(`propriete: ${link} was clicked!`)
      //this.getPropertiesByCategories(link);

    });

     //recupération du sous-menu des Villes
    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'context-villes'),
      map(({ item: {link } }) => link),
    )
    .subscribe(link => {
      //this.window.alert(`ville: ${link} was clicked!`)
      //this.getPropertiesByvilles(link);
    });

  }
 /**this.window.alert(`${title} was clicked!`) */
 public isconnected():boolean{
    return !localStorage.getItem('user');
  }
login() {
  localStorage.removeItem('user');
  this.router.navigate(['/login']);
 // this.window.alert(`login was clicked!`) 

}

logout() {
  localStorage.removeItem('user');
  this.router.navigate(['/']);
}


register() {
  //localStorage.setItem('user', "lllllllllll");  
  localStorage.removeItem('user');
  this.router.navigate(['/register']);
}

public formatVilles(){
  this.itemsvilles =[];
  //console.log(this.MenuVilles)
    for(let ville of this.MenuVilles){
      this.itemsvilles.push(Object.assign( { title: ville?.nomville, link: "/properties/ville/"+ville?.idville, icon: 'pin-outline', id:ville?.idville}) );
    }
    this.itemsvilles.sort((a, b) => (a?.id > b?.id ? 1 : -1));
}
public formatCategories(){
  this.itemproprietes =[];
  //console.log(this.MenuProperties);
    for(let cat of this.MenuProperties){
      this.itemproprietes.push(Object.assign( { title: cat?.nomcategorie, link: "/properties/categorie/"+cat?.idcategorie, icon: cat?.icon,id:cat?.idcategorie }) );
    }
  }
public formatTypeslogements(){
  this.itemlogements =[];
  //console.log(this.MenuLogements);
      for(let logement of this.MenuLogements){
              this.itemlogements.push(Object.assign( { title: logement?.nomtypelogement, link: "/properties/logement/"+logement?.idtypelogement, icon: 'home-outline', id:logement?.idtypelogement} ));
      }
}
public formatMeubles(){
  this.itemmeubles =[];
  //console.log(this.MenuLogements);
      for(let meuble of this.MenuMeubles){
              this.itemmeubles.push(Object.assign( { title: meuble?.nomtypelogement, link: "/properties/meubles/"+meuble?.idtypelogement, icon: 'layers-outline', id:meuble?.idtypelogement} ));
      }
      console.log()
}
public fetchData(){
    this.formatMeubles();
    this.formatTypeslogements();  
    this.formatCategories(); 
    this.formatVilles();
}
/*
public trierTab(){
  this.Villes.sort((a, b) => (a?.idville > b?.idville ? 1 : -1));
  //console.table(this.Villes);
}
  
//////////////////////////////////////////////////////////////
      this.loading = true;
      this.error = "";
      this.catRep
          .getTypeLogements()
            .subscribe(
              (response) => {                           //next() callback
                this.Typelogement = response; 
                this.formatTypeslogements();  // this.formatCategories(); //this.formatVilles();              
              },
              (error) => {                              //error() callback
                console.error('Request Typelogement failled with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                console.error('Request Typelogement top header completed')      //This is actually not needed 
                this.loading = false; 
              })

  ////////////////////////////////////////////////////////////////
   /           this.loading = true;
              this.error = "";
              this.catRep
                    .getVilles()
                      .subscribe(
                        (response) => {                           //next() callback
                          this.Villes = response;    
                          this.formatVilles();
                        },
                        (error) => {                              //error() callback
                          console.error('Request Villes failed with error')
                          this.error = error;
                          this.loading = false;
                        },
                        () => {                                   //complete() callback
                          console.warn('Request Villes top header completed')      //This is actually not needed 
                          this.loading = false; 
                        })
////////////////////////////////////////////
                    this.loading = true;
                    this.error = "";
                    this.catRep
                          .getcategories()
                            .subscribe(
                              (response) => {                           //next() callback
                                this.Categories = response; 
                                this.formatCategories(); //this.formatVilles();
                              },
                              (error) => {                              //error() callback
                                console.error('Request Categories failed with error')
                                this.error = error;
                                this.loading = false;
                              },
                              () => {                                   //complete() callback
                                console.error('Request Categories top header completed')      //This is actually not needed 
                                this.loading = false; 
                              })


             
    } */

   
    


   public  MenuVilles = [
      {
          "idville": 3,
          "nomville": "Bafoussam",
          "descriptionville": "Ouest",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531163632822287-bafoussamok.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      },
      {
          "idville": 4,
          "nomville": "Bertoua",
          "descriptionville": "Est",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531161153890219-bertoua1.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-11T00:00:00+01:00"
      },
      {
          "idville": 2,
          "nomville": "Douala",
          "descriptionville": "Littoral",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531162032677246-douala3.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      },
      {
          "idville": 5,
          "nomville": "Ebolowa",
          "descriptionville": "Sud",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531165651637776-ebolowa2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-23T00:00:00+01:00"
      },
      {
          "idville": 8,
          "nomville": "Garoua",
          "descriptionville": "Nord",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531160442989346-garoua3.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-04-08T00:00:00+02:00"
      },
      {
          "idville": 7,
          "nomville": "Maroua",
          "descriptionville": "Extrême-Nord",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531155710497650-maroua1.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-04-08T00:00:00+02:00"
      },
      {
          "idville": 6,
          "nomville": "Ngaoundéré",
          "descriptionville": "Adamaoua",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531153831834871-ngoundere2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-26T00:00:00+01:00"
      },
      {
          "idville": 1,
          "nomville": "Yaoundé",
          "descriptionville": "Centre",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531154037677578-yaounde2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      }
  ];
public MenuLogements =[
  {
    "idtypelogement": 1,
    "nomtypelogement": "Chambre",
    "descriptiontypelogement": "Maison à une pièce avec eventuellement une cuisine et une douche",
    "phototypelogement": "ddddddddd",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2020-11-21T00:00:00+01:00",
    "categorie": null,
    "icon": 'home-outline'
},
{
    "idtypelogement": 2,
    "nomtypelogement": "Studio",
    "descriptiontypelogement": "Studio",
    "phototypelogement": "ddddddddd",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2020-11-21T00:00:00+01:00",
    "categorie": null,
    "icon": 'home-outline'
},
{
    "idtypelogement": 3,
    "nomtypelogement": "Appartement",
    "descriptiontypelogement": "Appartement",
    "phototypelogement": "ddddddddd",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2020-11-21T00:00:00+01:00",
    "categorie": null,
    "icon": 'home-outline'
},

{
    "idtypelogement": 10,
    "nomtypelogement": "Duplex",
    "descriptiontypelogement": "Duplex",
    "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603153514515094-appartement.jpg",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2023-06-03T00:00:00+02:00",
    "categorie": null,
    "icon": 'home-outline'
},

{
    "idtypelogement": 12,
    "nomtypelogement": "Villa",
    "descriptiontypelogement": "Villas",
    "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603153701742718-appartement.jpg",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2023-06-03T00:00:00+02:00",
    "categorie": null,
    "icon": 'home-outline'
},

{
    "idtypelogement": 15,
    "nomtypelogement": "Château",
    "descriptiontypelogement": "Château",
    "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603153902911420-appartement.jpg",
    "activated": true,
    "deleted": false,
    "updated": false,
    "dateajout": "2023-06-03T00:00:00+02:00",
    "categorie": null,
    "icon": 'home-outline'
}

];
  public MenuProperties = [
    {
        "idcategorie": 1,
        "nomcategorie": "Habitation",
        "descriptioncategorie": "HABITATION",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/habitationv.png",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-09T00:00:00+02:00",
        "typeslogement": [  ],
        "icon": 'activity-outline'
    },
  
    {
        "idcategorie": 9,
        "nomcategorie": "Bâtiments industriels",
        "descriptioncategorie": "Bâtiment industriel",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531154345932997-batimentsidustriel.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-05-30T00:00:00+02:00",
        "typeslogement": [ ],
        "icon": 'globe-outline'
    },
    {
        "idcategorie": 2,
        "nomcategorie": "Bureaux",
        "descriptioncategorie": "bureau modaine",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/v1/web/images/20210219193454000000-bureau.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-09T00:00:00+02:00",
        "typeslogement": [ ],
        "icon": 'cloud-download-outline'
    },
    {
        "idcategorie": 3,
        "nomcategorie": "Espace commercial",
        "descriptioncategorie": "CENTRE COMMERCIAL",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/centre_commercial.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-09T00:00:00+02:00",
        "typeslogement": [],
        "icon": 'shopping-cart-outline'
    },
    {
        "idcategorie": 4,
        "nomcategorie": "Espace meublé",
        "descriptioncategorie": "ESPACE MEUBLE",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/espace_meuble.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-09T00:00:00+02:00",
        "typeslogement": [ ],
        "icon": 'copy-outline'
    },
      {
        "idcategorie": 5,
        "nomcategorie": "Parking",
        "descriptioncategorie": "PARKING",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/parking.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-09T00:00:00+02:00",
        "typeslogement": [],
        "icon": 'car-outline'
    },
    {
        "idcategorie": 6,
        "nomcategorie": "Salles d'événements",
        "descriptioncategorie": "SALLE DE FETE",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/sallefete.png",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-12T00:00:00+02:00",
        "typeslogement": [ ],
        "icon": 'archive-outline'
    },
    {
        "idcategorie": 7,
        "nomcategorie": "Terrains",
        "descriptioncategorie": "TERRAIN",
        "photocategorie": "http://api-easyhome.destiny-innovation.com/img/terrain.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2020-10-12T00:00:00+02:00",
        "typeslogement": [ ],
        "icon": 'map-outline'
    }
  ];
  
  MenuMeubles = [
      {
          "idtypelogement": 29,
          "nomtypelogement": "Chambre meublée",
          "descriptiontypelogement": "Chambre meublée",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160027202261-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      },
      {
          "idtypelogement": 30,
          "nomtypelogement": "Studio meublé",
          "descriptiontypelogement": "Studio meublé",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160114062579-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      },
      {
          "idtypelogement": 31,
          "nomtypelogement": "Appartement meublé",
          "descriptiontypelogement": "Appartement meublé",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160145845673-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      },
      {
          "idtypelogement": 32,
          "nomtypelogement": "Penthouse meublé",
          "descriptiontypelogement": "Penthouse meublé",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160241809765-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      },
      {
          "idtypelogement": 33,
          "nomtypelogement": "Duplex meublé",
          "descriptiontypelogement": "Duplex meublé",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160327253629-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      },
      {
          "idtypelogement": 34,
          "nomtypelogement": "Triplex meublé",
          "descriptiontypelogement": "Triplex meublé",
          "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160404882208-appartement.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2023-06-03T00:00:00+02:00",
          "categorie": null
      }
  ];
}
