<head-single-property [idbien]="idprop"></head-single-property>
<!-- Property Details Hero Section Begin -->

<!-- Property Details Section Begin -->
<section class="property-details-section spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="pd-details-text">
                    <div class="pd-details-social">
                        <a href="#"><i class="fa fa-facebook"></i></a>
                        <a href="#"><i class="fa fa-send"></i></a>
                        <a href="#"><i class="fa fa-star"></i></a>
                        <a href="#"><i class="fa fa-print"></i></a>
                        <a href="#"><i class="fa fa-cloud-download"></i></a>
                    </div>

                    <nb-card>
                        <div class="property-more-pic">

                            <nb-tabset>
                                <nb-tab tabTitle="APERÇU PHOTOS" tabIcon="camera-outline" responsive>
                                    <gallery id="propertyGallery" [thumbTemplate]="thumbTemplate"
                                        [itemTemplate]="itemTemplate">
                                    </gallery>

                                    <!-- Add custom template to thumbnails -->
                                    <ng-template #thumbTemplate let-type="type">
                                        <span *ngIf="type === 'youtube'" class="item-type">
                                            <fa-icon [icon]="youtubeIcon" size="lg"></fa-icon>
                                        </span>
                                        <span *ngIf="type === 'video'" class="item-type">
                                            <fa-icon [icon]="videoIcon" size="lg"></fa-icon>
                                        </span>
                                    </ng-template>

                                    <!-- Add custom template to image items -->
                                    <ng-template #itemTemplate let-index="index" let-type="type" let-data="data"
                                        let-currIndex="currIndex">


                                        <span *ngIf="type === 'image' && index === currIndex" [@enterAnimation]
                                            class=" ">
                                            <div _ngcontent-wxd-c51="" class="pic-tag" style="-webkit-tap-highlight-color: transparent;
                                                                                                background: #20c997;
                                                                                                display: inline-block;
                                                                                                padding: 4px 15px; ">
                                                <div _ngcontent-wxd-c51="" class="f-text"><b _ngcontent-wxd-c51="">
                                                        {{data?.title}}</b>
                                                </div>
                                            </div>
                                        </span>
                                    </ng-template>
                                </nb-tab>
                                <nb-tab tabTitle="VISITE VIRTUELLE" tabIcon="eye-outline" ng-hide="visite360" responsive
                                    disabled="visite360">
                                    <gallery id="propertytourGallery" [thumb]="false" [itemTemplate]="itemTemplate">
                                    </gallery>
                                    <!-- Add custom template to image items -->
                                    <ng-template #itemTemplate let-index="index" let-type="type" let-data="data"
                                        let-currIndex="currIndex">
                                        <span *ngIf="type === 'image' && index === currIndex" [@slideAnimation]
                                            class="item-text">
                                            <div _ngcontent-sut-c68="" class="pd-hero-text">
                                                {{data?.title}}
                                            </div>
                                        </span>
                                    </ng-template>
                                </nb-tab>
                            </nb-tabset>

                        </div>
                    </nb-card>



                    <nb-card>
                        <nb-card-body>
                            <div class="pd-desc">
                                <h4>Description</h4>
                                  <div class="row">
                                      <div class="cole-lg-6 col-md-6 col-sm-12">
                                        
                                        <nb-list>
                                            <nb-list-item *ngFor='let elt of descriptifs.split("\n") | slice:0:9'>
                                                <nb-icon icon="checkmark-circle-outline" status="success"></nb-icon>
                                                    {{elt }}
                                            </nb-list-item>
                                        </nb-list>
                                      </div>
                                      <div class="cole-lg-6 col-md-6 col-sm-12">
                                        <nb-list>
                                            <nb-list-item *ngFor='let elt of descriptifs.split("\n") | slice:9:18'>
                                                <nb-icon icon="checkmark-circle-outline" status="success"></nb-icon>
                                                  {{ elt }}
                                            </nb-list-item>
                                        </nb-list>
                                      </div>
                                  </div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card>
                        <nb-card-header>
                            <h4>Caractéristiques</h4>
                        </nb-card-header>
                        <nb-card-body>

                            <nb-accordion multi>
                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Pièces
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <i class="fa fa-bed"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Chambres:</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbrchambreacoucher}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-bath"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Salles de bain:</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbrsallebain}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-coffee"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Pieces:</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbrpiece}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-coffee"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Localisation  :</b>&nbsp;&nbsp;&nbsp; {{Bien?.localisationetage}}<sup>ème </sup>étage
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon icon="globe-2"
                                                            style="    font-size: 24px; color: #2cbdb8;"></nb-icon>&nbsp;&nbsp;<b>
                                                            Nombre chambres :</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbrchambreacoucher}}
                                                    </nb-list-item>

                                                </nb-list>
                                            </div>
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <nb-icon icon="home"
                                                            style="    font-size: 24px; color: #2cbdb8;"></nb-icon>
                                                        &nbsp;&nbsp;<b> Salon:</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbrsalon}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon icon="activity"
                                                            style="    font-size: 24px; color: #2cbdb8;"></nb-icon>
                                                        &nbsp;&nbsp;<b> Salon:</b>&nbsp;&nbsp;&nbsp; {{Bien?.nbreetage}}
                                                    </nb-list-item> 
                                                    <nb-list-item>
                                                        <i class="fa fa-car"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Parking:</b>&nbsp;&nbsp;&nbsp; {{Bien?.parking?'Oui':'Non'}}

                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-reorder"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Garage:</b>&nbsp;&nbsp;&nbsp; <b>{{Bien?.garage?'Oui':'Non'}} (01) </b>
                                                    </nb-list-item> 

                                                </nb-list>
                                            </div>

                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>

                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Bail / Achat
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <i class="fa fa-money"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;
                                                        &nbsp;&nbsp;<b> Coût {{unitecout}}:</b>&nbsp;&nbsp;&nbsp;
                                                        <strong>{{coutloyermensuel | currency:'XAF'}} </strong> <br><i>Payement en {{Bien?.modepaiement}}</i>
                                                        
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-file-text"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Contrat :</b>&nbsp;&nbsp;&nbsp; <b>{{avendre?'à vendre':'à louer'}}</b>
                                                    </nb-list-item>

                                                </nb-list>
                                            </div>
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <i class="fa fa-exclamation-circle"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Mois d'avance:</b>&nbsp;&nbsp;&nbsp; {{Bien?.avances}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-ban"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Mois de caution:</b>&nbsp;&nbsp;&nbsp; {{Bien?.avances/5}}

                                                    </nb-list-item>

                                                </nb-list>
                                            </div>

                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>

                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Localisation
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>

                                        <div class="row">
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <i class="fa fa-map-marker"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;
                                                        &nbsp;&nbsp;<b> Pays:</b>&nbsp;&nbsp;&nbsp; Cameroun
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-map-marker"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Region :</b>&nbsp;&nbsp;&nbsp; Centre
                                                    </nb-list-item>

                                                </nb-list>
                                            </div>
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <i class="fa fa-map-marker"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                            Ville : </b>&nbsp;&nbsp;&nbsp; {{Bien?.ville?.nomville}}
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <i class="fa fa-map-marker"
                                                            style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;Quartier : 
                                                           &nbsp;&nbsp;&nbsp;
                                                        <b> {{Bien?.quartier?.nomquartier}} </b>
                                                    </nb-list-item>

                                                </nb-list>
                                            </div>

                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>

                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Commodités
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.sallebainpartage" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.sallebainpartage" icon="minus-square-outline" status="danger"></nb-icon>
                                                         Salle bain partagée : <b>{{Bien?.sallebainpartage?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item> 
                                                        <nb-icon *ngIf="Bien?.estmeuble" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.estmeuble" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Est meublé : <b>{{Bien?.estmeuble?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.achauffage" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.achauffage" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Avec chauffage : <b>{{Bien?.achauffage?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.balcon" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.balcon" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Avec Balcon : <b>{{Bien?.balcon?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.terasse" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.terasse" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Terasse : <b>{{Bien?.terasse?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                </nb-list>
                                            </div>
                                            <div class="col-lg-6  ">
                                                <nb-list>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.buanderie" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.buanderie" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Buanderie : <b>{{Bien?.buanderie?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.estdisponible" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.estdisponible" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Disponible : <b>{{Bien?.estdisponible?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.terasse" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.terasse" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Terasse : <b>{{Bien?.terasse?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.balcon" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.balcon" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Avec Balcon : <b>{{Bien?.balcon?'Oui':'Non'}}</b>
                                                    </nb-list-item>
                                                    <nb-list-item>
                                                        <nb-icon *ngIf="Bien?.terasse" icon="checkmark-square-2-outline" status="success"></nb-icon>
                                                        <nb-icon *ngIf="!Bien?.terasse" icon="minus-square-outline" status="danger"></nb-icon>
                                                        Terasse : <b>{{Bien?.terasse?'Oui':'Non'}}</b>
                                                    </nb-list-item>

                                                </nb-list>
                                            </div>
                                        </div>
                                    </nb-accordion-item-body>

                                </nb-accordion-item>
                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Type/Catégorie du Bien
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <nb-list>
                                            <nb-list-item>
                                                <i class="fa  fa-exclamation-circle"
                                                    style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;
                                                &nbsp;&nbsp;<b> Type {{unitecout}}:</b>&nbsp;&nbsp;&nbsp;
                                                <strong>{{Bien?.category?.nomcategorie}} </strong> <br><br>
                                            </nb-list-item>
                                            <nb-list-item>
                                                <i class="fa fa-ban"
                                                    style="    font-size: 24px; color: #2cbdb8;"></i>&nbsp;&nbsp;<b>
                                                    Catégorie :</b>&nbsp;&nbsp;&nbsp; <b>{{Bien?.typelogement?.nomtypelogement}}</b>
                                            </nb-list-item>

                                        </nb-list>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>
                            </nb-accordion>
                        </nb-card-body>
                    </nb-card>

                    <div class="property-map">
                        <h4>Map</h4>
                        <div class="map-inside">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d11423.007218931214!2d11.496943495291859!3d3.865484486404728!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m5!1s0x108bcfe0eb54b96f%3A0xe6ee22b708f811a5!2sTECHNIPOLE%20SUP-VALOR%2C%20Ecole%20Polytechnique%20Melen%2C%20Rte%20de%20Melen%2C%20Yaounde%2C%20Cameroon!3m2!1d3.8633477!2d11.4986041!4m0!5e0!3m2!1sen!2sbd!4v1596316108414!5m2!1sen!2sbd"
                                height="320" style="border:0;" allowfullscreen=""></iframe>
                            <div class="icon">
                                <i class="fa fa-home"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3">
                <button nbTooltip="Cliquez pour Reservez ce Bien" nbTooltipStatus="primary" (click)="openWindow()" style="font-family: 'Franklin Gothic Medium'; color:white; background-color: red; border-radius: 10px;" nbButton >
                <!--<button (click)="openWindow()" status="info" nbButton style="background-image: url('assets/img/reserver.png'); border-radius: 8px;"> -->   
                    <nb-icon icon="attach-2-outline" style="color: #ffffff;"></nb-icon><strong>Reservez ce Bien</strong></button>
                <div class="property-sidebar">
                    <app-side-search></app-side-search>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Property Details Section End -->
<!--related propertyes start-->
<section class="feature-section spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                    <span>BIENS IMMOBILIERS</span>
                    <h2>Ces biens pourraient aussi vous interresser</h2>
                </div>
            </div>
        </div>
        <div class="row"><!---->
            <div class="col-md-3 col-lg-3 mb-3" *ngFor="let top of RelatedBiens | slice:0:4">
                <div class="feature-carousel" style="margin-top: 25px;">
                    <div class="feature-item">
                        <app-poperty-card [top]="top"></app-poperty-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Related Properties end-->



<ng-template #contentTemplate1 let-data let-ref="dialogRef">
    <div class="row">
        <div class="col-md-12">
            <p><i>Vous êtes interressé par </i> : <b> {{data?.designation}} - {{data?.estmoderne? 'Moderne': 'Simple'}}</b>  
            Située à : <b>{{data?.ville}} -{{data?.quartier}}</b></p>
            <p>Coût/Prix {{unitecout}} :<b><i>{{data?.coutloyermensuel | currency:'XAF'}}</i></b></p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form  [formGroup]="firstForm" #dossierForm="ngForm"  id="formId">
                        <nb-form-field> 
                            <div class="row">
                                 <hr>
                                 <div class="col-md-4">
                                    Date de visite :
                                 </div>
                                <div class="col-md-4">
                                  <input nbInput type="date" min="2021-01-01" placeholder="Date Descente" class="form-control" 
                                        formControlName="datedescente" shape="semi-round" fieldSize="small"
                                        [ngClass]="{ 'is-invalid': submitted && f.datedescente.errors }" fullWidth required>
                                </div>
                                 <div class="col-md-4">
                                        <input nbInput type="time" placeholder="Heuret" class="form-control" 
                                                formControlName="heuredescente" shape="semi-round" fieldSize="small"
                                              [ngClass]="{ 'is-invalid': submitted && f.heuredescente.errors }" fullWidth required>
                                 </div>
                            </div><hr>
                            <div class="row" *ngIf="data.estmeuble">
                                <div class="col-md-4">
                                    Période de Reservation
                                 </div>
                                <div class="col-md-4">
                                    <input nbInput type="date" min="2021-01-01" placeholder="Date début reservation" class="form-control" 
                                        formControlName="datedebutreservation" shape="semi-round" fieldSize="small"
                                        [ngClass]="{ 'is-invalid': submitted && f.datedebutreservation.errors }" fullWidth >
                                </div>
                                 <div class="col-md-4">
                                     <input nbInput type="date" placeholder="date fin reservation" class="form-control"
                                                formControlName="datefinreservation" shape="semi-round" fieldSize="small"
                                              [ngClass]="{ 'is-invalid': submitted && f.datefinreservation.errors }" fullWidth >
                                 </div>
                            </div>
                      </nb-form-field><br />
                        <nb-form-field>
                            <div class="row">
                                <div class="col-md-4" style="text-align: center;">
                                    Un mot à dire??
                                    <p>
                                        Taille Max <b>500</b> Cararctères<br />
                                       <b>{{input?.value?.length || 0}}</b>/500
                                    </p>
                                </div>
                                <div class="col-md-8">
                                    <textarea  rows="4" placeholder="" nbInput
                                        fullWidth shape="semi-round" #descriptiondossier maxlength="500" id="descriptiondossier" name="descriptiondossier"
                                        formControlName="descriptiondossier" #input style="color: red;" class="form-control" >
                                    </textarea>
                                 </div>
                            </div>
                        </nb-form-field>
                        <br />
                        <nb-form-field>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Votre numéro joignable </label>
                                </div>
                                 <div class="col-md-8">
                                     <input nbInput type="number" placeholder="numéro joignable" class="form-control" 
                                            formControlName="telclient" shape="semi-round" fieldSize="small" [ngModel]="data?.telclient" 
                                              [ngClass]="{ 'is-invalid': submitted && f.telclient.errors }"  fullWidth required>
                                 </div>
                            </div>
                            
                        </nb-form-field>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <button nbButton (click)="close()"
                                    class="btn btn-danger  px-4 pull-left" status="danger">Fermer</button>

                            </div>
                            <div class="col-md-6">
                                <button nbButton  [disabled]="!dossierForm.form.valid"
                                    class="btn  px-4 pull-right" status = "primary" (click)="rdvSubmit($event.target.valeu); close()" >Valider</button>
                            </div>
                        </div>
                    </form>
        </div>
    </div>
</ng-template>