
import { Component} from '@angular/core';
 

import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'EasyHome 3D';
  public isLoggedIn:boolean=true;
  constructor(private router: Router ) {
   
  }

 affiche():boolean {
  var splitted = this.router.url.split("/", 3); 
  return !((splitted[1] === 'properties')||(splitted[1]  === 'research')||(splitted[1]  === 'search'));

}

}
