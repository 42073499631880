
    <div class="single-agent">
        <div class="sa-pic">
            <img [src]="Top_ag.img" alt="picture">
            <div class="hover-social">
            <a href="neds" class="twitter"><i class="fa fa-twitter"></i></a>
            <a href="neds" class="instagram"><i class="fa fa-instagram"></i></a>
            <a href="neds" class="facebook"><i class="fa fa-facebook"></i></a>
            </div>
        </div>
        <h5>{{Top_ag.noms}}  <span>{{Top_ag.ville}}, {{Top_ag.zone}}</span></h5>
</div>
