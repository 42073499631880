<section class="emergency-contact-area">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <!--<div class="col-lg-3 col-md-6">
                    <img src="assets/img/man.png" alt="image">
                </div>-->
                <div class="col-lg-8 col-md-6">
                    <div class="btn-box">
                            <i class=""></i><!--flaticon-open-book-->
                        <a [href]="serviceclient.phone" class="default-btn"><i><img src="assets/img/phone/phone.png" class="phone1"/></i>Service Client</a>
                        <!--<a routerLink="/equipes" class="default-btn"><i><img src="assets/img/phone/agent.png" class="agent"/></i> Rencontrer un Agent</a>-->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-cta">
                        <a [href]="serviceclient.whatsapp">

                            <i><img src="assets/img/phone/whatsapp-1.png" class="phone"/></i>
                            {{serviceclient.indicepays}} {{serviceclient.phone}}
                        </a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section>
