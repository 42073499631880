<div class="property-sidebar" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;" >
  <br>
 <!--  <h4>Chercher un bien  </h4> -->
    
    <div class="sidebar-btn" >
      <div class="bt-item">
      <input type="radio" (click)="active1()" name="s-type" id="st-buy" checked="">
      <label for="st-buy" style="font-size: 16px;"> <i class="fa fa-search"></i>
        Location</label>
      </div>
      <div class="bt-item">
      <input type="radio" (click)="active2()"  name="s-type" id="st-rent" >
      <label for="st-rent"><i class="fa fa-home"></i>
        Vente </label>
      </div>
      </div>
<form class="sidebar-search" #searchForm="ngForm" (ngSubmit)="search(searchForm.value)" id="formId">
        
                         <div class="first-row ">
                                <select attr.ngModel="{{idcategorie}}" name="categorie" (change)="selectType($event.target.value)" id="categorie" class="nice-select"  required>
                                      <option class="current"  [value]="0">Catégorie du bien</option>
                                      <option class="current" *ngFor="let cat of Categories; let id = index" [value]="cat.idcategorie">{{cat?.nomcategorie}}</option>
                                </select>
                                <select attr.ngModel="{{idtypelogement}}" name="typelogement" (change)="selectSubcat($event.target.value)" id="typelogement" class="nice-select">
                                      <option  class="current" [value]="0">Type de bien</option>
                                      <option (click)="selectType($event.target.value)" class="current" *ngFor="let type of Typelogements" [value]="type.idtypelogement">{{type?.nomtypelogement}}</option>
                                </select>
                                <select attr.ngModel="{{idville}}" name="ville" (change)="selectVille($event.target.value)"  id="ville" class="nice-select"  required>
                                      <option class="current" [value]="idville">Villes</option>
                                      <option class="current" *ngFor="let v of Villes" [(value)]="v.idville">{{v.nomville}}</option>
                                </select>
                                <select attr.ngModel="{{idquartier}}" name="quartier" (change)="selectQuartier($event.target.value)" id="quartier" class="nice-select">
                                      <option class="current" [value]="idquartier">Quatirer</option>
                                      <option class="current" *ngFor="let qt of Quartiers" [value]="qt.idquartier">{{qt.nomquartier}}</option>
                                </select>
                          </div>
                        <div class="second-row">
                            <div class="price-range-wrap">
                                <div class="price-text">
                                  <label for="priceRange">Distance avec la route:[{{mindist}}-{{maxdist}}]m</label>
                                  <ng5-slider [(value)]="mindist" [(highValue)]="maxdist" [options]="optionsdist"></ng5-slider>
                                </div>
                              </div>
              
                          <div class="price-range-wrap">
                            <div class="price-text">
                              <label for="priceRange">Price:[{{minValueprix}}-{{maxValueprix}}]FCFA</label>
                              <ng5-slider [(value)]="minValueprix" [(highValue)]="maxValueprix" [options]="optionsprix"></ng5-slider>
                            </div>
                            <div id="price-range" class="slider"></div>
                          </div>
                          <div class="price-range-wrap">
                            <div class="price-text">
                              <label for="priceRange">Surface:[{{minValuesup}}-{{maxValuesup}}]m<sup>2</sup></label>
                              <ng5-slider [(value)]="minValuesup" [(highValue)]="maxValuesup" [options]="optionssup"></ng5-slider>
                            </div>
                          </div>
              
                          <button class="search-btn" type="submit" [disabled]="!searchForm.form.valid">Search</button>
                        </div>
            
    </form>

</div>
