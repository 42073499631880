import { NgModule } from '@angular/core'; 
import { Routes, RouterModule } from '@angular/router';
import {SearchResultComponent} from './pages/search-result/search-result.component';
import {SearchTextResultComponent} from './pages/search-text-result/search-text-result.component';
import {SinglePopertyComponent} from './pages/single-poperty/single-poperty.component';
import {ContactusComponent} from './contactus/contactus.component';
import {NewsComponent} from './pages/news/news.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {FaqsComponent} from './pages/faqs/faqs.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { SoumettreDesirsComponent } from './pages/soumettre-desirs/soumettre-desirs.component';
import { HomeComponent } from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {ProfileComponent} from './auth/profile/profile.component';
import {RegisterComponent} from './auth/register/register.component';
import { DemenagementsComponent } from './pages/demenagements/demenagements.component';
import { DetailsDemenagementComponent } from './pages/details-demenagement/details-demenagement.component';
import {AutorisationGuard} from './services/guards/autorisation.guard'


const routes: Routes = [
   { path: 'faqs', component: FaqsComponent },
   { path: 'search', component: SearchResultComponent },
   { path: 'research', component: SearchTextResultComponent },
   { path: 'property/:id', component: SinglePopertyComponent },
   { path: 'properties', component: PropertiesComponent},
   { path: "properties/ville/:id", component: PropertiesComponent},
   { path: "properties/logement/:id", component: PropertiesComponent},
   { path: "properties/meubles/:id", component: PropertiesComponent},
   { path: "properties/categorie/:id", component: PropertiesComponent},
   { path: 'contactus', component: ContactusComponent },
   { path: 'news', component: NewsComponent },
   { path: 'news-deatails', component: NewsDetailsComponent },
   { path: '', component: HomeComponent },
   {path: 'login', component: LoginComponent,  },
   {path: 'profile', component: ProfileComponent,  },
   {
         path: 'register', 
         component: RegisterComponent,  
         /*canActivate: [AutorisationGuard]*/
      }, 
   {path:'desirs',   component:SoumettreDesirsComponent},
   {path:'demenagement',   component:DemenagementsComponent},
   {path:'demenagement/details/:id',   component:DetailsDemenagementComponent},
   {path: 'module-name', loadChildren: () => import('./loading-data/loading-data.module').then(m => m.LoadingDataModule) },
 ];
 /**{ path: "site/:id/:name/:address", component: SiteDetailsComponent }, */
@NgModule({ 
   imports: [
      RouterModule.forRoot(routes,{ useHash: true,onSameUrlNavigation: 'reload' })
   ],
   exports: [RouterModule] 
}) 
export class AppRoutingModule { }
