import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Bienimmobilier } from 'src/app/models/bienimmobilier';
import {AppglobalService} from '../../services/appglobal.service';
import {BiensService} from '../../services/biens.service';
import {SearchService} from '../../services/search.service';
import {HomeComponent} from '../../home/home.component';
import { NbIconConfig, NbSidebarService , NbTreeGridModule,  } from '@nebular/theme';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';



@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {


  bellIconConfig: NbIconConfig = { icon: 'bell-outline', pack: 'eva' };
  @Input() Infos =["Liste Propriétés", "/properties", "assets/img/banner-img4.png"];
  public nomville="null";
  public TopProprietes =[];
  public lieu="";  public categorie="";  public logement="";
  public bien;
  Biens:Bienimmobilier[];
  public souscat="";
  public idprop;
  private H:HomeComponent;
  loading = true; error = ""; BiensProperties:Bienimmobilier[] =[];
  msgresult="";nbresult=0;
  dataSource: NbTreeGridDataSource<any>;

  //private route: ActivatedRoute
  constructor(private sidebarService: NbSidebarService, private _Activatedroute:ActivatedRoute,
     private bienRep: BiensService, private gs:AppglobalService, 
     private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>,
     private router : Router,
     private searchService : SearchService){
            this.dataSource = this.dataSourceBuilder.create(this.Biens);
            this._Activatedroute.paramMap.subscribe(params => {
              this.ngOnInit();
              //window.location.reload();
          });
            
      }
     
      reloadComponent() {
        let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
        }
  ngOnInit(): void {
        //recupération du paramètre    ////console.log(this._Activatedroute.snapshot.url[0].path)
        let lien = window.location.href;
        localStorage.setItem("currentUrl", lien);
        let urltoArray =  lien.split("/");
        let id;
        for(id of urltoArray){  }
        this.Biens = [];
        if(this._Activatedroute.snapshot.url[1]){
            if(this._Activatedroute.snapshot.url[1].path=='ville'){
                  this.getPropertiesByvilles(id);this.souscat='dans la Ville'
            }
            if(this._Activatedroute.snapshot.url[1].path=='categorie'){
              this.getPropertiesByCategories(id);
            }
            if(this._Activatedroute.snapshot.url[1].path=='logement' || this._Activatedroute.snapshot.url[1].path=='meubles'){
                this.getPropertiesByTypelogement(id);this.souscat='Logements'
            }
           // window.location.reload();
           //this.router.navigate([lien]);
       }else{
          this.getBienImmobiliers();
       }
      ////console.table(this.Biens);
      ///this.bien = this.gs.TopProprietes[+(1)];
    //  window.location.reload();
   
  }
 load(): Boolean{

return  this.loading  ;
  }
  toggleLoadingAnimation() {
    this.loading = true;
    setTimeout(() => this.loading = !this.loading, 5000)
  }
  toggle() {
    this.sidebarService.toggle();
  }
   getBienImmobiliers() {
    let loading = true;
    this.Biens =[];
    let error = "";
    this.bienRep
          .getbiensimmobiliers()
            .subscribe(
              (response) => {                           //next() callback
                this.Biens = response; 
                this.configMsg();
                this.loading = false;
              },
              (error) => {                              //error() callback
                //console.error('Request failed with error')
                error = error;
                loading = true;
              },
              () => {                                   //complete() callback
                //console.info('Request getbiensimmobiliers completed');
                ////console.table(this.Biens);
                loading = true; 
              })
}
configMsg(){
  if(this.Biens.length==0){
    this.msgresult = "Aucun Bien ("+this.souscat+") trouvé !!!!!";
    this.nbresult = 0;
  }else{
    this.msgresult = "Nombre Total de Bien(s) (" + this.souscat + ")  trouvés est  :  ";
    this.nbresult = this.Biens.length;
  }
}
public traiterBien(DesBiens:Bienimmobilier[]){
  for(let B of DesBiens){
       if(B.image2ds[0].srcimg){
        B.image2ds[0].srcimg = B.image2ds[0].srcimg;
      }else{  
        B = Object.assign(B, {image2ds:"assets/img/img_2.jpg"} );
      }
      if(B.category.nomcategorie){
        B.category.nomcategorie = B.category.nomcategorie;
      }else{
        B = Object.assign(B, {category: {nomcategorie: "Indisponible"}} );
      }
      if(!(B.quartier)){//hasOwnProperty(prop)
        B = Object.assign(B, {quartier:{nomquartier: "Indisponible"}} );       
      }else{
        B.quartier.nomquartier = B.quartier.nomquartier; 
      }
      if(B.ville.nomville){
        B.ville.nomville = B.ville.nomville;
      }else{
        B = Object.assign(B, {ville: {nomville: "Indisponible"}} );
      }
  }
}

public getPropertiesByvilles(id){
  this.loading = true;
  this.Biens =[];
  this.error = "";
   this.bienRep
        .getbiensByville(Number(id))
          .subscribe(
            (response) => {                           //next() callback
              this.Biens = response; 
              document.body.scrollTop = 10; // For Safari
              document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
              this.configMsg();
            },
            (error) => {                              //error() callback
              //console.error('Request by ville failed with error')
              this.error = error;
              this.loading = false;
            },
            () => {                                   //complete() callback
             // console.info('Request biens by ville completed')      //This is actually not needed 
              //console.table(this.Biens);
              this.loading = false; 
            }); 
}//getBienparTypelogement
public getPropertiesByTypelogement(id){
    this.loading = true;
    this.error = "";
    this.Biens =[];
    this.bienRep
        .getBienparTypelogement(Number(id))
          .subscribe(
            (response) => {                           //next() callback
              this.Biens = response; 
              document.body.scrollTop = 10; // For Safari
              document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
              this.configMsg();
            },
            (error) => {                              //error() callback
              //console.error('Request by typelogement failed with error');
              this.error = error;
              this.loading = false;
            },
            () => {                                   //complete() callback
              //console.info('Request Biens by typelogement completed')  ;   
              //console.table(this.Biens);
              this.loading = false; 
            }); 
    }
    //searchService
    public getallBienbyLogement(terme){
      this.loading = true;
      this.error = "";
      this.Biens =[];
      this.searchService
          .rechercheTextuelle(terme)
            .subscribe(
              (response) => {                           //next() callback
                this.Biens = response; 
                document.body.scrollTop = 10; // For Safari
                document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
                this.configMsg();
              },
              (error) => {                              //error() callback
                //console.error('Request by typelogement failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Request Biens by typelogement completed')  ;   
                //console.table(this.Biens);
                this.loading = false; 
              }); 
      }
  private getPropertiesByCategories(id){
      this.loading = true;
      this.Biens =[];
      this.error = ""; //BiensProperties
      this.bienRep
          .getbiensBycategories(Number(id))
            .subscribe(
              response=> {                           //next() callback
               this.Biens = response; 
               document.body.scrollTop = 10; // For Safari
               document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
                this.configMsg();
              },
              (error) => {                              //error() callback
               // console.error('Request by categorie failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Request by categorie completed');
                ////console.table(this.Biens);
                this.loading = false; 
              }); 
}
 
}
