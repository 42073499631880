<br>
       <!-- <nb-card size="medium"  status="primary">
                <div class="">
                    <main class="main d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="card-group">
                            <div class="card p-6">
                                <div class="card-body">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <h1>Connexion</h1>
                                    <p class="text-muted">Accédez à votre compte</p>
                                    <p style="text-align: center; color: red; font-style: bold;" *ngIf="etatconnexion">{{message}}</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                        </div>
                                        <input  type="text" nbInput formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  placeholder="Nom d'utilisateur" autocomplete="current-username"  required>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                        </div>
                                        <input type="password" nbInput formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  class="form-control" placeholder="Mot de passe" autocomplete="current-password" required>
                                    </div>
                                    <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <button [disabled]="loading" class="btn fond  px-4" style="color:white;">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-6 text-right">
                                       <button type="button" class="btn btn-link px-0" nbTooltip="Pas encore pris en compte, BV créer un nouveau compte. Merci" nbTooltipPlacement="top" nbTooltipStatus="warning" nbButton status="">Mot de pass oublié?</button>
                                    </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                            <div class="card text-white fond py-5 d-md-down-none" style="width:44%;">
                                <div class="card-body text-center">
                                <div>
                                    <h2>Créez un compte easyHome</h2>
                                    <p>Si vous n'avez pas encore crée un compte sur easyHome,nous vous invitons à le faire maintenant afin d'être informé sur toutes nos annonces par mail ou par SMS</p>
                                    <button type="button" class="btn btn-primary active mt-3" routerLink="/register" style="color:white;">S'inscrire Maintenant!</button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </main>
                </div>
        </nb-card>-->
    <div class="corps">
   
        <div class="container ">
            <div class="row">
              <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card border-0 shadow rounded-3 my-5">
                  <div class="card-body p-4 p-sm-5">
                    <h5 class="card-title text-center mb-5 fw-light fs-5">Connexion/Sign In</h5>
                     <p class="text-muted">Accédez à votre compte</p>
                    <form [formGroup]="loginForm"   (ngSubmit)="onSubmit()">
                      <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                            </div>
                            <input  type="text"  formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  placeholder="Nom d'utilisateur" autocomplete="current-username"  required>
                        </div>
                        <div class="input-group mb-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                            </div>
                            <input type="password"  formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  class="form-control" placeholder="Mot de passe" autocomplete="current-password" required>
                        </div><hr class="my-4">
        
                            <div class="form-check mb-3">
                                <input checked class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck">
                                <label class="form-check-label" for="rememberPasswordCheck">
                                Rester connecté/Remain Online
                                </label>
                            </div>
                      <div class="row"><br>
                        <div class="col-6">
                            <div class="form-group">
                                <button [disabled]="loading" class="btn fond  px-4" style="color:white;">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Login
                                </button>
                            </div>
                        </div>
                        <div class="col-6 text-right">
                           <button type="button" class="btn btn-link px-0"  routerLink="/register" >S'inscrire/Sign Up!</button>
                       </div>
                        </div>
                      
                      <!--<div class="d-grid mb-2 alignement">
                        <button class="btn btn-google btn-login text-uppercase fw-bold" type="submit">
                          <i class="fab fa-google me-2"></i> Sign in with Google
                        </button>
                      </div>
                      <div class="d-grid mb-2 alignement">
                        <button class="btn btn-facebook btn-login text-uppercase fw-bold" type="submit">
                          <i class="fab fa-facebook-f me-2"></i> Sign in with Facebook
                        </button>
                      </div>-->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
</div>