import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

//import { Observable, throwError } from 'rxjs';

import { catchError, retry } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import {AppglobalService} from './appglobal.service';
import { Categorie } from '.././models/categorie';//Typelogement
import { Typelogement } from '.././models/typelogement';//Typelogement
import {Ville} from '.././models/ville'
import {Search} from '.././models/search';
import { Bienimmobilier } from '.././models/bienimmobilier';//Typelogement

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http:HttpClient, private lien: AppglobalService ) { }



getResultSearch(): Observable<any> {
      return this.http
            .get<Search[]>(this.lien.ApiUrl+'bienimmobiliers')/*
            .map((data: Response) => data.json()); */
    } 


  public rechercher(s: Search): Observable<any> {
    //console.log('voici les données de recherche coté service recu du component');
    //console.log(s);
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl+'filtrebienimmobiliers', 
            JSON.stringify(s))
            .map((data: Bienimmobilier[]) => data); 
  }
  public rechercheTextuelle(t:string): Observable<any> {
    //console.log('voici le text de recherche coté service recu du component');
    //console.log(t);
    return this.http.post<Bienimmobilier[]>(this.lien.ApiUrl+'bienimmobilierssearch', 
            JSON.stringify({keywords:t}))
            .map((data: Bienimmobilier[]) => data); 
  }


}