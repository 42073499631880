<div class="property-contactus">
    <h4>Contact Us</h4>
    <div class="row">
        <div class="col-lg-5">
            <div class="agent-desc">
                <img src="./assets/img/properties/agent-contact.jpg" alt="">
                <div class="agent-title">
                    <h5>Adam Smith</h5>
                    <span>Saler Marketing</span>
                </div>
                <div class="agent-social">
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                    <a href="#"><i class="fa fa-google-plus"></i></a>
                    <a href="#"><i class="fa fa-envelope"></i></a>
                </div>
                <p>In today’s net-savvy world it has become common for any business to have a
                    website which they use mostly for advertising their products and services.
                </p>
            </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
            <form action="#" class="agent-contact-form">
                <input type="text" placeholder="Name*">
                <input type="text" placeholder="Email">
                <textarea placeholder="Messages"></textarea>
                <button type="submit" class="site-btn">Send Message</button>
            </form>
        </div>
    </div>
</div>
