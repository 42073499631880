import {ChangeDetectionStrategy, Component,Inject, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import {Router, ActivatedRoute} from '@angular/router'
import { DOCUMENT } from '@angular/common';
import {AppglobalService} from '../../services/appglobal.service';
import {SearchService} from '../../services/search.service'
import {Search} from '../../models/search';
import {Bienimmobilier} from '../../models/bienimmobilier';
import {HomeSearchComponent} from '../../layouts/home-search/home-search.component'
@Component({
  selector: 'search-text-result',
  changeDetection: ChangeDetectionStrategy.OnPush, 
  templateUrl: './search-text-result.component.html',
  styleUrls: ['./search-text-result.component.css']
})
/*changeDetection: ChangeDetectionStrategy.OnPush, */
export class SearchTextResultComponent implements OnInit {
  public TopProprietes = [];
  public searchData:Search;
  public resultSearchT:Bienimmobilier[];
  public datafound:boolean=false;
  public mois; loading=true; error='';
  msgresult = "Aucun Bien trouvé !!!!!";
  nbresult = 0;


  constructor(private searchRep:SearchService,
               @Inject(DOCUMENT) private _document: Document,
                private gData:AppglobalService,
                private router:Router,
                private _Activatedroute:ActivatedRoute) { 
                this._Activatedroute.paramMap.subscribe(params => {
                          this.ngOnInit();
                        });
    //this.toggleLoadingAnimation();
  }
  ngOnInit(): void {
  /*if(this.router.url === '/search'){
      this.reloadComponent()
    }*/
    this.SearchText();
   
  }
 
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

    
  public SearchText(){
    this.loading = true;
    let t = localStorage.getItem("textData");
    this.resultSearchT = [];
          this.error = "";
          this.searchRep
              .rechercheTextuelle(t)
                .subscribe(
                  (response) => {                           //next() callback
                    this.resultSearchT = response;     
                    document.body.scrollTop = 10; // For Safari
                    document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera  
                    this.configMsg();       
                  },
                  (error) => {                              //error() callback
                    //console.error('Request text search failled with error')
                    this.error = error;
                    this.loading = false;
                  },
                  () => {                                   //complete() callback
                    //console.error('search text Request completed');
                    console.table(this.resultSearchT);
                    if(this.resultSearchT.length>0){
                      this.datafound = true;
                    }else{
                      this.datafound = false;
                    }
                    this.loading = false; 
                  })
    
  }


  configMsg(){
    if(this.resultSearchT.length==0){
      this.msgresult = "Aucun Bien trouvé !!!!!";
      this.nbresult = 0;
    }else{
      this.msgresult = "Nombre total de biens trouvés est  :  ";
      this.nbresult = this.resultSearchT.length;
    }
  }
}
