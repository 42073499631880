import { Component, OnInit } from '@angular/core';
import {CategoriesService} from '../services/categories.service';
import { Bienimmobilier } from '../models/bienimmobilier';
import { Categorie } from '.././models/categorie';//Typelogement
import { Typelogement } from '.././models/typelogement';//Typelogement
import {Ville} from '.././models/ville'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  loading = true;
  error = "";
  Villes:Ville[];
  annee = 2000;
  constructor(private catRep:CategoriesService) { }

  ngOnInit(): void {
    this.getvilles();
    this.annee = new Date().getFullYear();
  }
  public getvilles() {
    this.loading = true;
    this.error = "";
    this.catRep
          .getVilles()
            .subscribe(
              (response) => {                           //next() callback
                this.Villes = response;  
                this.trierTab();  
              },
              (error) => {                              //error() callback
                console.error('Get footer ville Request failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
               // console.error('Get footer ville Request completed')      //This is actually not needed 
                this.loading = false; 
              })
}

public trierTab(){
  this.Villes.sort((a, b) => (a?.idville > b?.idville ? 1 : -1));
  //console.table(this.Villes);
}
}
