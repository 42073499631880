import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var sliderImages = document.querySelectorAll('.slider-image');
    var currentImageIndex = 0;

    function showNextImage() {
      //sliderImages[currentImageIndex].style.display = 'none';
      currentImageIndex = (currentImageIndex + 1) % sliderImages.length;
      //sliderImages[currentImageIndex].style.display = 'block';
    }

    setInterval(showNextImage, 3000); // Change d'image toutes les 3 secondes
  }

}
