

    <div>
      <br><br>
      <div class="container ">
          <div class="row">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
              <div class="card border-0 shadow rounded-3 my-9">
                <div class="card-header">
                  <h4 style="text-align: center;"><b>Création de compte sur EasyHome</b></h4>
                </div>
                <div class="card-body p-4 p-sm-5">
                   <form [formGroup]="firstForm" (ngSubmit)="onFirstSubmit()">
                            <hr>
                            <div class="row mb-3">
                                <div class="col-md-6  col-sm-12">
                                    <div class="input-group mb-4">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                      </div>
                                      <input nbInput type="text"  class="form-control" formControlName="nomuser" placeholder="Nom complèt" autocomplete="nomuser"
                                          [ngClass]="{ 'is-invalid': submitted && f.nomuser.errors }" required><br/>
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="input-group mb-4">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                      </div>
                                      <input  type="text" placeholder="Prénom(s)" class="form-control" formControlName="prenomuser" autocomplete="prenomuser"
                                            [ngClass]="{ 'is-invalid': submitted && f.prenomuser.errors }" required><br/>
                                  </div>
                                </div>
                            </div>
                            <hr>
                        <div class="row mb-3">
                              <div class="col-md-6 col-sm-12">
                                  <div class="form-floating mb-3 mb-md-0">
                                    <input type="date" placeholder="Date de naissance" class="form-control" formControlName="datenaisuser" autocomplete="datenaisuser"
                                        [ngClass]="{ 'is-invalid': submitted && f.datenaisuser.errors }"  required><br />
                                  </div>
                              </div>
                              <div class="col-md-6 col-sm-12"> 
                                  <div class="input-group mb-4">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text">Sexe</span>
                                      </div>
                                      <select placeholder="Sexe" formControlName="sexe" class="form-control"  
                                                [ngClass]="{ 'is-invalid': submitted && f.sexe.errors }" ([ngModel])="sexe" required >Sexe
                                                  <option value="F">Masculin</option>
                                                  <option value="M">Feminin</option>
                                      </select><br />
                                  </div>
                                  
                              </div>
                          </div>
                          <hr>

                            <div class="row mb-3">
                                <div class="col-md-6 col-sm-12">
                                    <div class="input-group mb-4">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><nb-icon icon="log-in-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                      </div>
                                      <input nbInput type="text" placeholder="Nom d'utilisateur(login)" class="form-control" formControlName="login"
                                              [ngClass]="{ 'is-invalid': submitted && f.login.errors }" required>
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="input-group mb-4">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><nb-icon icon="phone-call-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                      </div>
                                      <input type="number" placeholder="Numéro de téléphone" class="form-control" formControlName="phonenumber"
                                              [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" required>
                                  </div>
                                </div>
                            </div>
                            <br/>
                            <hr>
                            <div class="form-floating mb-3">
                                <!--<div class="form-group">
                                    <input type="email" placeholder="Entrez adresse Email" class="form-control" formControlName="email" fullWidth
                                          [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                </div>-->
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">@</span>
                                    </div>
                                    <input type="email" placeholder="Entrez adresse Email" class="form-control" formControlName="emailuser" fullWidth
                                            [ngClass]="{ 'is-invalid': submitted && f.emailuser.touched && f.emailuser.errors }">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><nb-icon icon="email-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                    </div>
                                </div>
                            </div><br>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-md-6 col-sm-12">
                                    <div class="input-group mb-4">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                          </div>
                                          <input  type="password" placeholder="Saisir  mot de passe (Password)" class="form-control" formControlName="password1"
                                                [ngClass]="{ 'is-invalid': submitted && f.password1.errors }" required>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                        </div>
                                        <input  type="password" value="nemi" placeholder="Resaisir  mot de passe (retype Password)" class="form-control" formControlName="password2"
                                                [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" required>
                                    </div>
                                </div>
                                <p *ngIf="f.password1.value!==f.password2.value" style="color: red; font-style:italic; text-align: center; float: center;">
                                  <a class="btn btn-block" ><b>les deux mots de Passe ne correspondent Pas</b></a>
                                </p>
                            </div>
                            <hr class="my-4">
        
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" formControlName="cgu"
                                        [ngClass]="{ 'is-invalid': submitted && f.cgu.errors }" required>
                                 <label class="form-check-label" for="cgu"><strong>Accepter les conditions générales d'Utilisation (CGU)</strong>
                                  <a href="https://api-easyhome.destiny-innovation.com/v1/web/cgueasyhome/CGUEasyHome2023fr.pdf" target="_blank">Cliquez ici pour oir CGU </a>
                                </label>
                            </div>
                            <br><hr>
                            <div class="row mb-3">
                                  <div class="col-md-3 col-sm-5">
                                      <div class="form-floating mb-3 mb-md-0">
                                        <div class="d-grid mb-2">
                                          <button class="btn btn-lg btn-danger btn-login fw-bold" type="reset">Annuler</button>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-floating mb-3 mb-md-0">
                                      <div class="d-grid mb-2"></div>
                                    </div>
                                </div>
                                  <div class="col-md-3  col-sm-5">
                                      <div class="form-floating mb-3 mb-md-0">
                                        <div class="d-grid mb-2">
                                          <button class="btn btn-lg btn-success btn-login fw-bold" type="submit">Register</button>
                                        </div>
                                      </div>
                                  </div>
                          </div>

                </form>

                <!--<div class="row mb-3">
                    <div class="col-md-5 col-sm-12">
                        <div class="form-floating mb-3 mb-md-0">
                            <div class="d-grid mb-2">
                                <div class="d-grid mb-2">
                                    <button class="btn btn-lg btn-google btn-login fw-bold" type="submit">
                                      <i class="fab fa-google me-2"></i> Sign up with Google
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating mb-3 mb-md-0">
                        <div class="d-grid mb-2">
                        </div>
                      </div>
                  </div>
                    <div class="col-md-5 col-sm-12">
                        <div class="form-floating mb-3 mb-md-0">
                          <div class="d-grid mb-2">
                              <div class="d-grid">
                                  <button class="btn btn-lg btn-facebook btn-login fw-bold" type="submit">
                                    <i class="fab fa-facebook-f me-2"></i> Sign up with Facebook
                                  </button>
                              </div>
                          </div> 
                        </div>
                    </div>
               </div>-->
               <div class="medium" routerLink="/login"><a class="btn btn-block" ><b><u>Avez-vous un compte? Connectez-vous</u></b></a></div>
                </div>
              </div>
            </div>
          </div>

        </div>

<br><br>
</div>


<!--Start   Good register code with z-index-->

<!--<div class="container">
  <div class="row justify-content-center">
      <div class="col-lg-7 col-md-12">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
              <div class="card-header">
                <h4 style="text-align: center;"><b>Création de compte sur EasyHome</b></h4>
              </div>
              <div class="card-body">
                  <form [formGroup]="firstForm" (ngSubmit)="onFirstSubmit()">
                      <hr>
                      <div class="row mb-3">
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                </div>
                                <input nbInput type="text"  class="form-control" formControlName="nomuser" placeholder="Nom complèt" autocomplete="nomuser"
                                    [ngClass]="{ 'is-invalid': submitted && f.nomuser.errors }" required><br/>
                            </div>
                          </div>
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><nb-icon icon="person-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                </div>
                                <input  type="text" placeholder="Prénom(s)" class="form-control" formControlName="prenomuser" autocomplete="prenomuser"
                                       [ngClass]="{ 'is-invalid': submitted && f.prenomuser.errors }" required><br/>
                            </div>
                          </div>
                      </div>
                      <hr>
                   <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="form-floating mb-3 mb-md-0">
                              <input type="date" placeholder="Date de naissance" class="form-control" formControlName="datenaisuser" autocomplete="datenaisuser"
                                   [ngClass]="{ 'is-invalid': submitted && f.datenaisuser.errors }"  required><br />
                            </div>
                        </div>
                        <div class="col-md-6"> 
                            <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Sexe</span>
                                </div>
                                <select placeholder="Sexe" formControlName="sexe" class="form-control"  
                                          [ngClass]="{ 'is-invalid': submitted && f.sexe.errors }" ([ngModel])="sexe" required >Sexe
                                            <option value="F">Masculin</option>
                                            <option value="M">Feminin</option>
                                </select><br />
                            </div>
                            
                        </div>
                    </div>
                    <hr>

                      <div class="row mb-3">
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><nb-icon icon="log-in-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                </div>
                                <input nbInput type="text" placeholder="Nom d'utilisateur(login)" class="form-control" formControlName="login"
                                        [ngClass]="{ 'is-invalid': submitted && f.login.errors }" required>
                            </div>
                          </div>
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><nb-icon icon="phone-call-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                </div>
                                <input type="number" placeholder="Numéro de téléphone" class="form-control" formControlName="phonenumber"
                                        [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" required>
                            </div>
                          </div>
                      </div>
                      <br/>
                      <hr>
                      <div class="form-floating mb-3">
                          <div class="input-group mb-4">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">@</span>
                              </div>
                              <input type="email" placeholder="Entrez adresse Email" class="form-control" formControlName="email" fullWidth
                                      [ngClass]="{ 'is-invalid': submitted && f.email.touched && f.email.errors }">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><nb-icon icon="email-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                              </div>
                          </div>
                      </div><br>
                      <hr>
                      <div class="row mb-3">
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                    </div>
                                    <input  type="password" placeholder="Saisir  mot de passe (Password)" class="form-control" formControlName="password1"
                                          [ngClass]="{ 'is-invalid': submitted && f.password1.errors }" required>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="input-group mb-4">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text"><nb-icon icon="lock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon></span>
                                  </div>
                                  <input  type="password" placeholder="Resaisir  mot de passe (retype Password)" class="form-control" formControlName="password2"
                                          [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" required>
                              </div>
                          </div>
                      </div>
                      
                      <br><hr>
                      <div class="row mb-3">
                        <div class="col-md-3">
                            <div class="form-floating mb-3 mb-md-0">
                              <div class="d-grid mb-2">
                                <button class="btn btn-lg btn-danger btn-login fw-bold" type="reset">Annuler</button>
                              </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating mb-3 mb-md-0">
                            <div class="d-grid mb-2"></div>
                          </div>
                      </div>
                        <div class="col-md-3">
                            <div class="form-floating mb-3 mb-md-0">
                              <div class="d-grid mb-2">
                                <button class="btn btn-lg btn-success btn-login fw-bold" type="submit">Register</button>
                              </div>
                            </div>
                        </div>
                    </div>

                  </form>
              </div>
              
             <div class="row mb-3">
                    <div class="col-md-5">
                        <div class="form-floating mb-3 mb-md-0">
                            <div class="d-grid mb-2">
                                <div class="d-grid mb-2">
                                    <button class="btn btn-lg btn-google btn-login fw-bold" type="submit">
                                      <i class="fab fa-google me-2"></i> Sign up with Google
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating mb-3 mb-md-0">
                        <div class="d-grid mb-2">
                        </div>
                      </div>
                  </div>
                    <div class="col-md-5">
                        <div class="form-floating mb-3 mb-md-0">
                          <div class="d-grid mb-2">
                              <div class="d-grid">
                                  <button class="btn btn-lg btn-facebook btn-login fw-bold" type="submit">
                                    <i class="fab fa-facebook-f me-2"></i> Sign up with Facebook
                                  </button>
                              </div>
                          </div> 
                        </div>
                    </div>
               </div>
                    
                <div class="row mb-3">
                      <div class="col-md-3">
                          <div class="form-floating mb-3 mb-md-0"><div class="d-grid mb-2"></div>
                          </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating mb-3 mb-md-0">
                          <div class="d-grid mb-2">
                            <div class="small" routerLink="/login"><a class="btn btn-block" ><b><u>Avez-vous un compte? Connectez-vous</u></b></a></div>
                          </div>
                        </div>
                    </div>
                      <div class="col-md-3">
                          <div class="form-floating mb-3 mb-md-0"><div class="d-grid mb-2"></div> </div>
                      </div>
               </div>
          </div>
      </div>
  </div>
</div>-->
<!--Start   Good register code with z-index-->

