import { Component, OnInit } from '@angular/core';
import {AppglobalService} from '../../services/appglobal.service'
@Component({
  selector: 'partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css']
})
export class PartenairesComponent implements OnInit {
public Partenaires = [];
  constructor(private gsvc:AppglobalService) { }

  ngOnInit(): void {
    this.Partenaires = this.gsvc.Partenaires;
  }

}
