import { Component, OnInit } from '@angular/core';
import {Router, Route} from '@angular/router';
import {Vehicule} from '../../models/vehicule';//public Vehicules:Vehicule[];
import {Modelevehicule} from '../../models/modelevehicule'; 
import {DesirsService} from '../../services/desirs.service';

@Component({
  selector: 'app-demenagements',
  templateUrl: './demenagements.component.html',
  styleUrls: ['./demenagements.component.css']
})
export class DemenagementsComponent implements OnInit {
  public tab:any[]=["i","j","k", "i","k"];
  loading = true;
  error = "";
  public VehiculeSelected:number=0;
  public Vehicules:Vehicule[];
  public Modelevehicules:Modelevehicule[];
  public idclient=0;
  constructor(private DesirsRep: DesirsService, private router:Router) { }

  ngOnInit(): void {
    this.getVoitures();
    let lien = window.location.href;
    localStorage.setItem("currentUrl", lien);
    //console.log(lien)
    let user = JSON.parse(localStorage.getItem("user"));
    if(user && user.token){
      this.idclient = user.user.id;
      //window.alert("User connected is : "+this.idclient);
    }else{
      window.alert("Bien vouloir vous connectez ou de créer un compte si vous n'en disposez pas un. Merci");
      this.router.navigate(['/login']);
    }
  }
  public selectOption(id){
      window.alert("Model Vehicule selected is "+id);
      this.VehiculeSelected=parseInt(id);
  }
  public getVoitures() {
  
    ///Get all Vehicule
    this.DesirsRep
          .getVehicules()
            .subscribe(
              (response) => {                           //next() callback
                this.Vehicules = response; 
                //console.log("Véhicules de demenagements  sont : ");
                //console.log(this.Vehicules);
              },
              (error) => {                              //error() callback
                //console.error('Véhicules de demenagements Request failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Véhicules de demenagements Request completed');      //This is actually not needed 
                this.loading = false; 
              })
//Get All Vehicules models
 this.DesirsRep
          .getModeleVehicule()
            .subscribe(
              (response) => {                           //next() callback
                this.Modelevehicules = response; 
                //console.log("Modele Véhicules de demenagements  sont : ");
                //console.log(this.Vehicules);
              },
              (error) => {                              //error() callback
                //console.error('Modele Véhicules de demenagements Request failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Modele Véhicules de demenagements Request completed');      //This is actually not needed 
                this.loading = false; 
              })
  }
}
