
   <a [routerLink]="['/demenagement/details', voiture?.idvehicule]" class="prop-entry d-block" style="background-color: rgb(13, 168, 168); border-radius: 5px;">
    <figure>
    <img src="{{voiture?.photovehicule}}" alt="{{voiture?.descriptionvehicule}}" class="img-fluid">
    </figure>
    <div class="prop-text">
    <div class="inner">
        <span class="price rounded">  {{voiture?.nomvehicule}}, {{voiture?.typevehicule}}</span>
        <h3 class="title">Immat : {{voiture?.matriculevehicule}}</h3>
        <p class="location">{{voiture?.villevehicule?.nomville}} - {{voiture?.quartiervehicule?.nomquartier}}</p>
    </div>
    <div class="prop-more-info">
        <div class="inner d-flex">
        <div class="col">
            <span>Tonage:</span>
            <strong>240 Kg</strong>
        </div>
        <div class="col">
            <span>PV:</span>
            <strong>2</strong>
        </div>
        <div class="col">
            <span>Nb sièges</span>
            <strong>2</strong>
        </div>
        <div class="col">
            <span> Nb portières:</span>
            <strong>3</strong>
        </div>
        </div>
    </div>
    </div>
</a>