import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'demenagement',
  templateUrl: './demenagement.component.html',
  styleUrls: ['./demenagement.component.css']
})
export class DemenagementComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
