<!-- How It Works Section Begin -->
<section class="howit-works spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <span>Procéssus retrouver un Bien immobilier sur easyhome</span>
            <h2>Comment ça marche ?</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="single-howit-works">
            <img src="./assets/img/howit-works/howit-works-1.png" alt="">
            <h4>Parcourir </h4>
            <p>Parcourez les biens disponibles à travers les différents menus et formulaires de recherche.</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="single-howit-works">
            <img src="./assets/img/howit-works/howit-works-2.png" alt="">
            <h4>Visiter</h4>
            <p>Visitez et analysez le bien à travers une visite virtuelle et une description sommaire des caractéristiques de celui-ci.</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="single-howit-works">
            <img src="./assets/img/howit-works/howit-works-3.png" alt="">
            <h4>Valider</h4>
            <p>Une fois interressé, soumettez la prise de rendez-vous ou discuter avec un agent en ligne.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- How It Works Section End -->