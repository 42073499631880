
    <!-- Breadcrumb Section Begin -->
    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <h2>Blog List</h2>
                        <div class="breadcrumb-option">
                            <a href="#"><i class="fa fa-home"></i> Home</a>
                            <span>Blog Default</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Breadcrumb Section Begin -->

    <!-- Blog Section Begin -->
    <section class="blog-section blog-page spad">
        <div class="container">
            <div class="row"> 
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="sb-pic">
                            <img src="./assets/img/blog/latest-1.jpg" alt="">
                        </div>
                        <div class="sb-text">
                            <ul>
                                <li><i class="fa fa-user"></i> Adam Smith</li>
                                <li><i class="fa fa-clock-o"></i> 12th Feb, 2019</li>
                            </ul>
                            <h4><a routerLink="/news-deatails">The Small Change That Creates Massive Results</a></h4>
                        </div>
                    </div>
                </div>    
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="sb-pic">
                            <img src="./assets/img/blog/latest-3.jpg" alt="">
                        </div>
                        <div class="sb-text">
                            <ul>
                                <li><i class="fa fa-user"></i> Adam Smith</li>
                                <li><i class="fa fa-clock-o"></i> 12th Feb, 2019</li>
                            </ul>
                            <h4><a routerLink="/news-deatails">The Small Change That Creates Massive Results</a></h4>
                        </div>
                    </div>
                </div>
               
             
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="sb-pic">
                            <img src="./assets/img/blog/latest-8.jpg" alt="">
                        </div>
                        <div class="sb-text">
                            <ul>
                                <li><i class="fa fa-user"></i> Adam Smith</li>
                                <li><i class="fa fa-clock-o"></i> 12th Feb, 2019</li>
                            </ul>
                            <h4><a routerLink="/news-deatails">The Small Change That Creates Massive Results</a></h4>
                        </div>
                    </div>
                </div>

                
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="sb-pic">
                            <img src="./assets/img/blog/latest-5.jpg" alt="">
                        </div>
                        <div class="sb-text">
                            <ul>
                                <li><i class="fa fa-user"></i> Adam Smith</li>
                                <li><i class="fa fa-clock-o"></i> 12th Feb, 2019</li>
                            </ul>
                            <h4><a routerLink="/news-deatails">The Small Change That Creates Massive Results</a></h4>
                        </div>
                    </div>
                </div>
               
                <div class="col-lg-12">
                    <div class="loadmore">
                        <a href="#" class="primary-btn">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Blog Section End -->
