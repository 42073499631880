import { Component, OnInit, Input,TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AppglobalService} from '../../services/appglobal.service';
import {FaqService} from '../../services/faq.service';
import {HomeComponent} from '../../home/home.component';
import { NbIconConfig, NbSidebarService , NbTreeGridModule,  } from '@nebular/theme';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { Faq } from './../../models/faq';
import { CategorieFaq } from './../../models/categoriefaq';
import { NbWindowService , NbDialogRef} from '@nebular/theme'; 


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
loading=false;
error='';
public FAQS:Faq[]; public cats:CategorieFaq[];
public Faqbycat:Faq[]; idcatfaq=0;
  constructor(private faqRep: FaqService, ) { }

  ngOnInit(): void {
    this.getAllFaq();
    this.getCategorieFaq();
  }

public selectFaqCat(id){
    //window.alert("Cat Faq ID selected is "+id);
    this.idcatfaq=parseInt(id);
    this.getfaqBycat(this.idcatfaq);
}

public getfaqBycat(id:number){
  this.FAQS = [];
  this.faqRep
      .getFaqBycategorie(Number(id))
        .subscribe(
          (response) => {                           //next() callback
            this.FAQS = response; 
            document.body.scrollTop = 10; // For Safari
            document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
          },
          (error) => {                              //error() callback
           // console.error('Request des FAQ By cat failed with error'+error)
            this.error = error;
            this.loading = false;
          },
          () => {                                   //complete() callback
           // console.info('Request des FAQ By cat( '+id+' ) completed')      //This is actually not needed 
            console.table(this.Faqbycat);
            //this.fullfilFac();
            this.loading = false; 
      });
  
}

  public getAllFaq(){
    this.loading = true;
    this.FAQS =[];
    this.error = "";
     this.faqRep
          .getFaq()
            .subscribe(
              (response) => {                           //next() callback
                this.FAQS = response; 
                document.body.scrollTop = 20; // For Safari
                document.documentElement.scrollTop = -20; // For Chrome, Firefox, IE and Opera
                document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Oper
              },
              (error) => {                              //error() callback
                //console.error('Request des FAQ failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                this.loading = false; 
              }); 
  }

  public getCategorieFaq(){
    this.loading = true;
   // this.CategorieFaq =[];
    this.error = "";
     this.faqRep
          .getcategorieFaq()
            .subscribe(
              (response) => {                           //next() callback
                this.cats = response; 
                document.body.scrollTop = 20; // For Safari
                document.documentElement.scrollTop = -20; // For Chrome, Firefox, IE and Opera
                document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Opera
            },
              (error) => {                              //error() callback
                //console.error('Request des categories  failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Request des  Categories completed')      //This is actually not needed 
                this.loading = false; 
               this.getfaqBycat(Number(this.cats[0].idcategoriefaq));
              }); 
  }
}
