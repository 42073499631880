import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  public monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
  "Juilet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
]; //this.mois = this.monthNames[new Date().getMonth()]

   mois:string='';
   annee:any;
  allbiensMax:number = 1500 ; allbiens:number = 0; 
  biensdispoMax:number = 379;biensdispo:number =0;
  nouveautesMax:number = 169; nouveautes:number=0;
  tauxLogementMax:number = 47 ; tauxLogement:number=0;

  allbiensStop:any = setInterval(()=>{
    this.allbiens++;
    if(this.allbiens==this.allbiensMax){
      clearInterval(this.allbiensStop);
    }
  },10) 

  biensdispoStop:any = setInterval(()=>{
    this.biensdispo++;
    if(this.biensdispo==this.biensdispoMax){
      clearInterval(this.biensdispoStop);
    }
  },10) 
  nouveautesStop:any = setInterval(()=>{
    this.nouveautes++;
    if(this.nouveautes==this.nouveautesMax){
      clearInterval(this.nouveautesStop);
    }
  },500) 
  tauxLogementStop:any = setInterval(()=>{
    this.tauxLogement++;
    if(this.tauxLogement==this.tauxLogementMax){
      clearInterval(this.tauxLogementStop);
    }
  },100) 

  constructor() { }

  ngOnInit(): void {
      this.mois = this.monthNames[new Date().getMonth()]
      this.annee = new Date().getFullYear();
  }

}
