import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {throwError } from 'rxjs';
import {AppglobalService} from './appglobal.service';
import {Faq} from '.././models/faq';
import {CategorieFaq} from '.././models/categoriefaq';
import { map, catchError } from 'rxjs/operators';  


@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient, private appglobal: AppglobalService) { }

   //Récuperation de plusieurs FAQ
 getFaq(): Observable<any> {
  return this.http
        .get<Faq>(this.appglobal.ApiUrl+'faqs');
}
getFaqLocal(): Observable<any> {
  return this.http
        .get<Faq>('../../assets/data/faqs.json');
}
   //Récuperation des categories  FAQ
   getcategorieFaq(): Observable<any> {
    return this.http
          .get<CategorieFaq[]>(this.appglobal.ApiUrl+'categoriefaqs')
  }
  getFaqBycategorie(id:number): Observable<any> {
    return this.http
          .get<Faq[]>(this.appglobal.ApiUrl+'faqsbycategorie/'+id);
  }
}
