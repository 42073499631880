import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {AppglobalService} from './appglobal.service';
import {Client} from '.././models/client';
import {Dossier} from '.././models/dossier';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient, private appglobal: AppglobalService) { }


  //Créer un nouveau client
  public creerClient(client: Client) {
    //console.log("data du Client coté service");//console.log(client);
    return this.http.post<Client>(this.appglobal.ApiUrl+'clients',
          JSON.stringify(client))
            .map((clientRep: Client) => clientRep);
  }
    //Verification si un utilisateur est un client
    public estClient(iduser: number) {
      
      return this.http.post<Client>(this.appglobal.ApiUrl+'estclients',
            JSON.stringify(iduser))
              .map((clientRep: Client) => clientRep);
    }
     //Verification si un utilisateur est un client
     public ouvrirDossier(dossier) {
       //console.log('data dossier client sur un bien');
       //console.log(dossier);
          return this.http.post<Dossier>(this.appglobal.ApiUrl+'dossiers',
                JSON.stringify(dossier))
                  .map((dossierResp: Dossier) => dossierResp);
    }
  }