<div class="section-title">
    <span class="sub-title">Faq's</span>
    <h2>Des Questions que vous vous posez certainement</h2>
</div>
<div class="row">
    <div class="col-md-4" style="float: right;"> </div>
    <div class="col-md-4" style="float: right;"> 
        <form>
            <div class="form-group">
                    <select  (change)="selectFaqCat($event.target.value)" id="idcatfaq" class="form-control">
                                <option value="0"> Catégorie Faq</option> 
                                <option *ngFor="let c of cats" value="{{c?.idcategoriefaq}}">{{c.nomcategoriefaq}}</option>
                    </select>
            </div>
        </form>  
    </div>
    <div class="col-md-4" style="float: right;"> </div>
</div>
<nb-layout>
    <nb-layout-column  class="colored-column-info">   
      <nb-card [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="giant">
         <nb-card-body>
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-content">
                            <nb-accordion multi class="tab-iten">
                                <nb-accordion-item  class="card faq-accordion-content" *ngFor="let faq of FAQS | slice:0:10">
                                <nb-accordion-item-header class="card-header" role="tab">
                                    {{faq.questionfaq}}
                                </nb-accordion-item-header>
                                <nb-accordion-item-body class="" role="tabpanel">
                                    {{faq.reponsefaq}}.
                                </nb-accordion-item-body>
                                </nb-accordion-item>
                            </nb-accordion>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="tab-content">
                            <nb-accordion multi class="tab-iten">
                                <nb-accordion-item  class="card faq-accordion-content" *ngFor="let faq of FAQS | slice:10:20">
                                <nb-accordion-item-header class="card-header" role="tab">
                                    {{faq.questionfaq}}
                                </nb-accordion-item-header>
                                <nb-accordion-item-body class="" role="tabpanel">
                                    {{faq.reponsefaq}}.
                                </nb-accordion-item-body>
                                </nb-accordion-item>
                            </nb-accordion><br>
                    </div>
                </div>
        </div>
               </nb-card-body>
      </nb-card>
    </nb-layout-column>
</nb-layout>