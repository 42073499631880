
<nb-layout>

   <nb-layout-header fixed responsive style="background-color:white;"> 
    
    <app-top-header></app-top-header>

  </nb-layout-header>  

    <!-- subheader 
  <nb-layout-header subheader>
  <app-header></app-header>
  </nb-layout-header>-->


  <nb-layout-column class="layout-window-mode-max-width	"  style=" background-color: white;        padding: 0px;">
<!--     <app-top-header></app-top-header>
    <br>  -->
    <app-header *ngIf="affiche()"></app-header>
  
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
    <!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
    <!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
    <!-- * * * * * * * to get started with your project! * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    
  
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
    <!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
    <!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    
    
     <!-- <loader></loader>-->
    <router-outlet></router-outlet>
  
  </nb-layout-column>

 

</nb-layout>
<app-footer *ngIf="affiche()"></app-footer> 