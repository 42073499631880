import {Quartier} from './quartier';
import {Ville} from './ville';

export class Vehicule{
    public   idvehicule:number;
    public   nomvehicule:string;
    public   matriculevehicule:string;
    public   typevehicule: string;
    public   descriptionvehicule:string;
    public   photovehicule: string;
    public   concessionnaire: string;
    public   villevehicule: Ville;
    public   quartiervehicule:Quartier;
    public   marquevehicule:string;
    public   modelevehicule:string;
    
   constructor(){

    }
}
