<div class="survoler">
  <a [routerLink]="['/property', top.idbienimmo]" *ngFor="let image2d of top.image2ds | slice:0:1">
        <div class="fi-pic set-bg" 
                [ngStyle]="{'background-image': 'url(' + image2d?.srcimg + ')'}" 
                attr.data-setbg="{{image2d?.srcimg}}">

            <div class="pic-tag">
                <div class="f-text"><b>{{top.nouveaute?'Nouveau':'Disponible'}} </b></div>
                <div class="s-text"><strong><em>{{top.avendre?'à vendre':'à louer'}}</em></strong></div>
            </div>
  
             <!----><div class="feature-author">
              <div class="fa-pic">
                <img src="assets/img/logo.png" alt="easyHome">
              </div>
              <div class="fa-text">
                <span>@easyhome</span>
              </div>
          </div> 
      </div>
  </a>
         
          <div class="fi-text">
            <div class="inside-text"><!-- .substring(0, 12)  - {{top?.estmoderne?'Moderne':'Simple'}}-->
              <h4>{{top?.designation }} </h4>
              <h6>{{top?.category?.nomcategorie}} - {{top?.typelogement?.nomtypelogement.substring(0, 12)}}</h6>
              <ul>
                <li><i class="fa fa-map-marker"></i> {{top?.ville?.nomville}} - {{top?.quartier?.nomquartier}}</li><br>
                <li><i class="fa fa-tag"></i>{{top?.courtedescription }} </li>
              </ul>
              <h5 class="price">{{top?.coutloyermensuel| currency:'XAF'}}</h5>
            </div>
            <ul class="room-features">
              <li>
                <i class="fa fa-arrows"></i>
                <p> {{top?.espacelocatif}}m<sup>2</sup></p>
              </li> 
            <li>
              <i class='fas fa-couch'></i>
              <p>{{top?.nbrsalon}}</p>
            </li>
              <li>
                <i class="fa fa-bed"></i>
                <p>{{top?.nbrchambreacoucher}}</p>
              </li>
              <li>
                <i class="fa fa-bath"></i>
                <p>{{top?.nbrsallebain}}</p>
              </li>
              <li>
                <i class="fa fa-car"></i>
                <p>{{(top?.parking || top?.garage)?'1':'0'}}</p>
              </li>
      </ul>
   </div>
  </div>

  <!--

<div class="survoler">
  <a [routerLink]="['/property', top.id]">
        <div class="fi-pic set-bg" 
                [ngStyle]="{'background-image': 'url(' + top.img + ')'}" 
                attr.data-setbg="{{top.img}}"
                (click)="gotoproperty()">
            <div class="pic-tag">
                <div class="f-text"><b>{{top.etat}}</b></div>
                <div class="s-text"><strong><em>{{top.contrat}}</em></strong></div>
            </div>
  
             <div class="feature-author">
              <div class="fa-pic">
                <img [src]="top.img" alt="">
              </div>
              <div class="fa-text">
                <span>Daniel Steven</span>
              </div>
          </div> 
      </div>
  </a>
         
          <div class="fi-text">
            <div class="inside-text">
              <h4>{{top.type}} - {{top.subcat}}</h4>
              <ul>
                <li><i class="fa fa-map-marker"></i> {{top.ville}} - {{top.quartier}}</li>
                <li><i class="fa fa-tag"></i>{{top.details}} </li>
              </ul>
              <h5 class="price">FCFA : {{top.prix}}<span>/month</span></h5>
            </div>
            <ul class="room-features">
              <li>
                <i class="fa fa-arrows"></i>
                <p> {{top.superficie}}m<sup>2</sup></p>
              </li>
              <li>
                <i class="fa fa-bed"></i>
                <p>4</p>
              </li>
              <li>
                <i class="fa fa-bath"></i>
                <p>3</p>
              </li>
              <li>
                <i class="fa fa-car"></i>
                <p>2</p>
              </li>
      </ul>
   </div>
  </div>

  -->