
import { Injectable, Pipe, ErrorHandler, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User} from '../models/user';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import {AppglobalService} from './appglobal.service';
//, private url: AppGlobals   this.urlentrep = this.lien.baseAppUrl + 'entreprises';

@Injectable()
export class UsersService{
  private childComponentRef:any;
  public urlusers;

  constructor(private http: HttpClient, private appglobal: AppglobalService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector){
    this.urlusers = this.appglobal.ApiUrl
  }

 public getAlltUsers() {
      return this.http.get(this.urlusers)
                  .map((res: Response) => res.json()) ; 
  }

    /***************Code login ******************* */
  loginfire(email: string, password: string) {
      return    this.http.post(this.urlusers+'loginrest', JSON.stringify({
            email: email,
            password: password
          }))
            .map((responseData: Response) => responseData.json());
    }
    /***************Code inscription ******************* */
    register(user:User) {
      return this.http.post(this.urlusers+"createuser",
        JSON.stringify({
          login: user.login,
          password: user.password,
          nomuser: user.nomuser,
          prenomuser: user.prenomuser,
          phonenumber: user.phonenumber,
          email: user.email,
          typeuser: user.typeuser,
          sexe: user.sexe,
          datenaisuser: user.datenaisuser
        }))
        .map((data: Response) => data.json());
  
  
  
    }
  

public deleteUser(usr: User) {
    //console.log('User to be deleted. received in the service side from the user-profile component');
      //console.log(usr);
      return this.http.delete(this.urlusers+"/"+usr.id.toString())
                 .map((data: Response) => data.json()); 
        }
/*
public updateUser(usr: User) {
    //console.log('User to be updated. received in the service side from the user-profile component');
      //console.log(usr);
        return this.http.put(this.urlusers+"/"+usr.userid.toString(), 
               JSON.stringify({  })) 
               .map((data: Response) => data.json()); 
}*/

public appendComponentTo(parentId: string, child: any, childConfig?: childConfig) {
  // Create a component reference from the component 
  const childComponentRef = this.componentFactoryResolver
    .resolveComponentFactory(child)
    .create(this.injector);

  // Attach the config to the child (inputs and outputs)
  this.attachConfig(childConfig, childComponentRef);

  this.childComponentRef = childComponentRef;
  // Attach component to the appRef so that it's inside the ng component tree
  this.appRef.attachView(childComponentRef.hostView);

  // Get DOM element from component
  const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>)
    .rootNodes[0] as HTMLElement;

  // Append DOM element to the body
  document.getElementById(parentId).appendChild(childDomElem);

}

public removeComponent() {
  this.appRef.detachView(this.childComponentRef.hostView);
  this.childComponentRef.destroy();
}


private attachConfig(config, componentRef){
  let inputs = config.inputs;
  let outputs = config.outputs;
  for(var key in inputs){
    componentRef.instance[key] = inputs[key];
  }
  for(var key in outputs){
    componentRef.instance[key] = outputs[key];
  }
  
}
}
interface childConfig{
inputs:object,
outputs:object
}




/*
@Injectable()
export class DomService {

  private childComponentRef:any;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  public appendComponentTo(parentId: string, child: any, childConfig?: childConfig) {
    // Create a component reference from the component 
    const childComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(child)
      .create(this.injector);

    // Attach the config to the child (inputs and outputs)
    this.attachConfig(childConfig, childComponentRef);

    this.childComponentRef = childComponentRef;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(childComponentRef.hostView);

    // Get DOM element from component
    const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.getElementById(parentId).appendChild(childDomElem);

  }

  public removeComponent() {
    this.appRef.detachView(this.childComponentRef.hostView);
    this.childComponentRef.destroy();
  }


  private attachConfig(config, componentRef){
    let inputs = config.inputs;
    let outputs = config.outputs;
    for(var key in inputs){
      componentRef.instance[key] = inputs[key];
    }
    for(var key in outputs){
      componentRef.instance[key] = outputs[key];
    }
    
  }
}
interface childConfig{
  inputs:object,
  outputs:object
} */