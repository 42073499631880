 
<!-- Header Section Begin -->
<header class="header-section header-normal">
 
  <div class="nav-logo">
    <div class="container">
      <div class="row">
      
        <div class="col-lg-9">
          <div class="nav-logo-right">
            <ul>
              <li>
                <i class="icon_phone"></i>
                <div class="info-text">
                  <span>Téléphone:</span>
                  <p>(+237) 6 70 91 21 66</p>
                </div>
              </li>
              <li>
                <i class="icon_map"></i>
                <div class="info-text">
                  <span>Address:</span>
                  <p>Technipole - Melen,  <span>Yaoundé</span></p>
                </div>
              </li>
              <li>
                <i class="icon_mail"></i>
                <div class="info-text">
                  <span>Email:</span>
                  <p>infos@easyhome3d.com</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- Header End -->
