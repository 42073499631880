
 import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
 import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
 import { Router } from '@angular/router';
 import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 import {AuthenticationService} from '../../services/auths/authentication.service';
 import { AlertService } from '../../services/auths/alert.service';
 import {User} from '../../models/user'
 import { first } from 'rxjs/operators';
 import { DatePipe } from '@angular/common';//this.datnaiss = this.datePipe.transform(this.f.datenaisuser.value, 'dd-MMM-yyyy');
 
 @Component({
   selector: 'register',
   styleUrls: ['./register.component.css'],
   templateUrl: './register.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush,
 })
 export class RegisterComponent implements OnInit{
  //@HostBinding('class')
   linearMode = true;
   redirectDelay: number = 5000;
   redirect:string = '/'
   showMessages: any = {};
   strategy: string = '';
   loading = false;
   submitted = false;
   errors: string[] = [];
   messages: string[] = [];
   user: any;
   firstForm: FormGroup;
   etatconnexion=false;
   datnaiss;sexe="M";
   passwordegal = true;
   message = 'Problème de connexion, Impossible de créer ce compte';
   private index: number = 0;

   
   classes = 'example-items-rows';
 
   positions = NbGlobalPhysicalPosition;
 
   constructor(protected service: AuthenticationService,
               protected alertService: AlertService,
               protected cd: ChangeDetectorRef,
               protected router: Router,
               private fb: FormBuilder,
               private datePipe: DatePipe,
               private toastrService: NbToastrService) {
 
   }
 
   ngOnInit() {
     //this.redirect = this.router.url;
     this.firstForm = this.fb.group({
            nomuser: ['', Validators.required],
            prenomuser: ['', Validators.required],
            datenaisuser: ['', Validators.required],
            sexe: ['', Validators.required],
            login: ['', Validators.required],
            password1: ['', Validators.required],
            password2: ['', Validators.required],
            phonenumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),
                              Validators.minLength(9), Validators.maxLength(20)]],
            cgu: ['', Validators.required],
            emailuser: ['',[ Validators.email && Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
     });
 
 
 
    
   }
   onFirstSubmit() {
     this.firstForm.markAsDirty();
     this.datnaiss = this.datePipe.transform(this.f.datenaisuser.value, 'dd-MMM-yyyy'); //whatever format you need.
     this. register();
     //console.log(this.f.nomuser.value+'--'+this.f.prenomuser.value+'--'+this.datnaiss+'--'+this.f.sexe.value+'--')
   }
 /*
   onSecondSubmit() {
     this.secondForm.markAsDirty();
     console.log(this.f1.login.value+'--'+this.f1.password1.value+'--'+this.f1.password2.value+'--'+this.f1.typeuser.value+'--')
     }*/
 
  
   toggleLinearMode() {
     this.linearMode = !this.linearMode;
   }
   get f() { return this.firstForm.controls; }
   //get f1() { return this.secondForm.controls; }
 
 register(): void {
     this.errors = this.messages = [];
     /*
     if(this.f.password1.value !=this.f.password2.value){
          this.passwordegal = false;
          
     }*/
     if(this.f.cgu.value  && this.f.emailuser.valid){
              this.submitted = true;
              let user:User = Object.assign( {nomuser:this.f.nomuser.value } , {prenomuser:this.f.prenomuser.value},
                                        {datenaisuser:this.datnaiss},{sexe:this.f.sexe.value},
                                        {login:this.f.login.value},{password:this.f.password1.value},
                                        {typeuser:"invite"},{email:this.f.emailuser.value},
                                        {phonenumber:this.f.phonenumber.value}
              );

              this.loading = true;
              this.service.creationcompte(user)
                  .pipe(first())
                  .subscribe(
                      (response) => {
                          this.user = response;
                          if(response && response.success){
                              localStorage.setItem("user", JSON.stringify(this.user));
                              this.showToast(this.positions.TOP_RIGHT, response.message, 'success');
                              this.reloadComponent();
                              this.router.navigateByUrl("/");
                              //console.log("redirection to current url" + this.redirect);
                              // console.log(this.user);
                          }else{
                              this.etatconnexion=!this.etatconnexion;
                              //this.message = response.message;
                              this.loading =  !this.loading
                              this.showToast(this.positions.TOP_RIGHT, response.message, 'danger');
                              
                          }
                      },
                      error => {
                          this.alertService.error(error);
                          this.loading =  !this.loading;;
                 });

        }else{
          this.showToast(this.positions.TOP_RIGHT, "Vous devez accepter les conditions générales d'utilisation de EASYHOME avant de continuer", 'danger');
        }
         /*      .subscribe(( data => {
                   this.submitted = false;
             if (this.redirect) {
               setTimeout(() => {
                 return this.router.navigateByUrl(this.redirect);
               }, this.redirectDelay);
             }
             this.cd.detectChanges();
           })*/
   }
   reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
   showToast(position, msg, status) {
        this.toastrService.show(status || 'Success', msg, { position, status });
  }
 }
 
