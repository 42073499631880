<app-couroussel></app-couroussel>
<app-home-search></app-home-search>

<!-- How It Works Section Begin -->
<commentcamarche></commentcamarche>
<!-- How It Works Section End -->

<!-- Feature Section Begin -->
<section class="feature-section spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="section-title">
          <span>BIENS IMMOBILIERS</span>
          <h2>Nouveautés du mois de  {{mois}}  {{annee}}</h2>
        </div>
      </div>
    </div>
    <div class="row" infinite-scroll>
      <div class="col-md-4 col-lg-4 mb-4" *ngFor="let bien of BiensNouveaux | slice:0:6">
        <div class="feature-carousel " style="margin-top: 25px;">
          <div class="feature-item">
            <app-poperty-card [top]="bien"></app-poperty-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Feature stats start -->
<!--  <app-stats></app-stats> -->
<!-- Feature stats End -->


<!-- Feature catégorie start -->
      <categories></categories>
<!-- Feature catégorie End -->

<!-- Feature Section End -->
<!-- VIDEO -->
<section class="home__video bg-theme-v6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 mx-auto">
        <div class="home__video-area text-center">
          <a href="#" (click)="openWindow()" class="play-video video-btn popup-youtube">
            <i class="icon fa fa-play text-white"></i>
          </a>
          <h2 class="text-white">Easy Home, une nouvelle vision de l'immobilier</h2>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Video content player-->
<ng-template #videotemplate let-data>
  <frameplayer [urlvideo]="videoToken" infinite-scroll></frameplayer>
</ng-template>

<section class="popular__city-large">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-6 mx-auto">
        <div class="title__head">
          <h2 class="text-center text-capitalize">
            Villes populaires
          </h2>
          <p class="text-center text-capitalize">Rechercher des propriétés dans ces villes.</p>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-4 col-lg-3" *ngFor="let ville of Villes">
        <!-- CARD IMAGE -->
        <a [routerLink]="['/properties/ville/', ville?.idville]">
          <div class="card__image-hover-style-v3">
            <div class="card__image-hover-style-v3-thumb h-230">
              <img [src]="ville?.photoville" alt="" class="img-fluid w-100">
            </div>
            <div class="overlay">
              <div class="desc">
                <h6 class="text-capitalize">{{ville?.nomville}}</h6>
                <p class="text-capitalize"> </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- END POPULAR CITY -->



<!-- FEATURED PROPERTIES -->
<section class="featured__property bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-6 mx-auto">
        <div class="title__head">
          <h2 class="text-center ">
            Biens recommandés
          </h2>
          <p class="text-center text-capitalize">CECI POURRAIT VOUS CONVENIR.</p>

        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row">
    
      <!-- ONE -->
          <div class="col-md-6 col-lg-4 mb-4" *ngFor="let prop of Biens | slice:0:6" >
              <div class="card__image card__box" >
                <a [routerLink]="['/property', prop.idbienimmo]">
                <div class="card__image-header h-250">
                  <div class="ribbon text-capitalize">Recommandé</div>
                  <img [src]="prop?.image2ds[0]?.srcimg" alt=""
                    class="img-fluid w100 img-transition">
                  <div class="info">{{prop.avendre?'à vendre':'à louer'}}</div>
                </div>
                <div class="card__image-body">
                  <span class="badge badge-primary text-capitalize mb-2">{{prop.category?.nomcategorie}} - {{prop.typelogement?.nomtypelogement}}</span>
                  <h6 class="text-capitalize">
                    {{prop.designation| slice:0:50}} - {{prop.estmoderne?'Moderne':'Simple'}}
                  </h6>

                  <p class="text-capitalize">
                        <i class="fa fa-map-marker"></i>
                        {{prop.ville?.nomville}} - {{prop.quartier?.nomquartier}}
                  </p>
                  <ul class="list-inline card__content">
                    <li class="list-inline-item">
                      <span>
                        Douches <br>
                        <i class="fa fa-bath"></i> {{prop?.nbrsallebain}}
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <span>
                        Chambres <br>
                        <i class="fa fa-bed"></i>  {{prop?.nbrchambreacoucher}}
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <span>
                        Salons <br>
                        <i class="fas fa-couch"></i> {{prop?.nbrsalon}}
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <span>
                        Parking <br>
                        <i class="fa fa-car"></i> {{(prop?.parking || top?.garage)?'1':'0'}}
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <span>
                        Superficie <br>
                        <i class="fa fa-map"></i> {{prop?.espacelocatif}} m<sup>2</sup>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="card__image-footer">
                  <figure>
                    <img [src]="prop?.image2ds[1]?.srcimg" alt=""
                      class="img-fluid rounded-circle">
                  </figure>
                
                  <ul class="list-inline my-auto">
                      <li class="list-inline-item">
                          <strong>@EasyHome</strong>
                      </li>
                  </ul>
                  <ul class="list-inline my-auto ml-auto">
                    <li class="list-inline-item">
                      <h6>{{prop.coutloyermensuel| currency:'XAF'}}<span>/{{prop.unitecout?.libelepar}}</span></h6>
                    </li>

                  </ul>
                </div>
              </a>
              </div>
      </div>
 

    </div>
  </div>

</section>
<!-- END FEATURED PROPERTIES -->

<br>
<div class="row">
  <div class="col-md-8 col-lg-6 mx-auto">
    <div class="title__head">
      <h2 class="text-center text-capitalize">
        Nos partenaires
      </h2>
      <p class="text-center ">Ceux-ci ont fait confiance à Easy Home.</p>

    </div>
  </div>
  <div class="clearfix"></div>
</div>
 

<!-- Partner Carousel Section Begin -->
<partenaires></partenaires>
<!--ion End -->