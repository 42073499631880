  <!-- Partner Carousel Section Begin -->

  <div class="partner-section ">
    <div class="container "> 
        <div class="partner-carousel row" style="align-content: center; align-items: center;">
            <div class="col-lg-2 col-md-3" *ngFor="let p of Partenaires" style="align-content: center; align-items: center;">
                    <a  [href]="p.site" class="partner-logo">
                            <div class="partner-logo-tablecell col-lg-4" >
                                <img class="rogner" [src]="p.logo" [name]="p.site" [alt]="p.nom" width="250px">
                            </div>
                        </a>
            </div>
        </div>
    </div>
</div>
<hr>
      <!-- Partner Carousel Section End -->
    