




<nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color:white;" fixed>
    <a class="navbar-brand" href="#">
        <nb-actions size="small">
            <nb-action>
                <div class="logo">
                    <a routerLink="/"><img src="./assets/img/logo.png" alt=""></a>
                </div>
            </nb-action>
        </nb-actions>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" style="background-color:white;" id="navbarTogglerDemo01">


        <nb-menu [items]="itemsmenu1" routerLink="/">
        </nb-menu>
        <nb-menu [items]="itemsmenu2" [nbContextMenu]="itemlogements" routerLink="/properties/logement/1" nbContextMenuTag="context-logements" left
              nbContextMenuTrigger="hover"  >
        </nb-menu>
        <nb-menu [items]="itemsmenu22" [nbContextMenu]="itemmeubles" routerLink="/properties/meubles/30" nbContextMenuTag="context-meubles" left
              nbContextMenuTrigger="hover">
        </nb-menu>
        <nb-menu [items]="itemsmenu3" [nbContextMenu]="itemproprietes" routerLink="/properties/categorie/1" nbContextMenuTag="context-proprietes" left
             nbContextMenuTrigger="hover">
        </nb-menu>
        <nb-menu [items]="itemsmenu4" [nbContextMenu]="itemsvilles" routerLink="/properties/ville/1" nbContextMenuTag="context-villes" left
              nbContextMenuTrigger="hover">
        </nb-menu>
        <!--<nb-menu [items]="itemsmenu5" routerLink="/news">
        </nb-menu>-->
        <nb-menu [items]="itemsmenu6" routerLink="/contactus">
        </nb-menu>
        <nb-menu [items]="itemsmenu7" routerLink="/faqs">

        </nb-menu>
        
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;
        <!--<nb-actions size="small">
            <nb-action icon="search-outline" nbTooltip="Rechercher un bien" nbTooltipIcon="search-outline"
                nbTooltipPlacement="bottom"></nb-action>
        </nb-actions>-->

        <!--type="modal-move column-curtain"-->
        <nb-search type="modal-move" placeholder="Que désirez-vous?" hint="Terrain bureau appartement studio etc"></nb-search>

        
        <nb-actions size="small">
            <nb-action icon="car-outline" nbTooltip="Déménagements" nbTooltipIcon="car-outline"
                nbTooltipPlacement="bottom" link="/demenagement"></nb-action>

        </nb-actions>
        <nb-actions size="small">
            <nb-action icon="file-add-outline" nbTooltip="Désirs" nbTooltipIcon="file-add-outline"
                nbTooltipPlacement="bottom" link="/desirs"></nb-action>
        </nb-actions>

        <!--<nb-actions size="small">
            <nb-action icon="email-outline" *ngIf="!isconnected()" nbTooltip="Messages" nbTooltipIcon="email-outline"
                nbTooltipPlacement="bottom">Recherche SOS</nb-action>
        </nb-actions>
        <nb-actions size="small">
            <nb-action icon="bell-outline" *ngIf="!isconnected()" nbTooltip="Notifications" nbTooltipIcon="bell-outline"
                nbTooltipPlacement="bottom"></nb-action>
        </nb-actions>
        <nb-actions size="small">
            <nb-action [icon]="disabledIconConfig" disabled *ngIf="!isconnected()"></nb-action>
        </nb-actions>-->
       
                <nb-user  *ngIf="!isconnected()" name="{{nomuser}}" [nbContextMenu]="items" picture="assets/img/agent/agent-4.jpg"
                    nbContextMenuTag="context-user">
                </nb-user>
           


        <button *ngIf="isconnected()" nbButton outline status="primary" (click)="login()">
            <nb-icon icon="log-in-outline" style="color: #2cbdb8;"></nb-icon>Connexion
        </button>
        &nbsp; &nbsp;

        <button *ngIf="isconnected()" nbButton hero status="primary" (click)="register()">
            <nb-icon icon="edit-2-outline" style="color: white;"></nb-icon>Inscription
        </button>

        &nbsp; &nbsp;
        <nb-actions size="small">
            <nb-action *ngIf="isconnected()"></nb-action>

        </nb-actions>

    </div>
</nav>