import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'property-card-list',
  templateUrl: './property-card-list.component.html',
  styleUrls: ['./property-card-list.component.css']
})
export class PropertyCardListComponent implements OnInit {
  @Input() bien;
  show = false;
  constructor() { }

  ngOnInit(): void {
  }

}
