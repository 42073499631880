import { Component, OnInit } from '@angular/core';
import { Categorie } from '../../models/categorie';
import { CategoriesService } from '../../services/categories.service';
import {NbSearchService} from '@nebular/theme';

@Component({
  selector: 'categories',
  templateUrl: './cats.component.html',
  styleUrls: ['./cats.component.css']
})
export class CatsComponent implements OnInit {
  public monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
  "Juilet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
]; //this.mois = this.monthNames[new Date().getMonth()]
public idcategorie: number = 0;
public Categories: Categorie[];
loading = true;
error = "";value="";
math = Math;
 mois;
  constructor(private catRep: CategoriesService, private searchService:NbSearchService) {
   /* this.searchService.onSearchSubmit()
    .subscribe((data: any) => {
      this.value = data.term;
    })*/
   }

  ngOnInit(): void {
    this.mois = this.monthNames[new Date().getMonth()];
    this.getcat();
  }
  public getcat() {
    this.loading = true;
    this.error = "";
    this.catRep
      .getcategories()
      .subscribe(
        (response) => {                           //next() callback
          this.Categories = response;
        },
        (error) => {                              //error() callback
          //console.error('Get categories Request failed with error')
          this.error = error;
          this.loading = false;
        },
        () => {                                   //complete() callback
          //console.error('Get categories Request completed')      //This is actually not needed 
          this.loading = false;
        })
  }
}
