import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {AppglobalService} from './appglobal.service';
import { Vehicule } from '.././models/vehicule';//Typelogement
import {Desir} from '.././models/desir';
import {Client} from '.././models/client';
import {Modelevehicule} from '../models/modelevehicule';
import {Marquevehicule} from '.././models/marquevehicule';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DesirsService {

  constructor(private http: HttpClient, private appglobal: AppglobalService) { }


  //Créer un nouveau client
  public creerClient(client: Client) {
    //console.log("data du Client coté service");//console.log(client);
    return this.http.post<Client>(this.appglobal.ApiUrl+'clients',
          JSON.stringify(client))
            .map((clientRep: Client) => clientRep);
  }
//Soummission d'un desir
  public soumettre(desir: Desir) {
    //console.log("data du désir coté service");//console.log(desir);
    return this.http.post<Desir>(this.appglobal.ApiUrl+'desirs',
          JSON.stringify(desir))
            .map((desir: Desir) => desir);
  }

  
    //Soumission doléance pour déménagement
  public demenager(D) {
    //console.log("data du demenagement coté service");//console.log(D);
    return this.http.post<any>(this.appglobal.ApiUrl+'demenagements',
          JSON.stringify(D))
            .map((demenagement) => demenagement);
  }
/**{ 
                         typelogementarrivee:D.typelogementarrivee,
                          positionimmeubledepart:D.positionimmeubledepart,
                          positionimmeublearrivee:D.positionimmeublearrivee,
                          optionmontage:D.optionmontage,
                          optionnetoyageldepart:D.optionnetoyageldepart,
                          optionnetoyagelarrivee:D.optionnetoyagelarrivee,
                          optionautres:D.optionautres,
                          villedepart:D.villedepart,
                          villearrivee:D.villearrivee,
                          quartierdepart:D.quartierdepart,
                          quartierarrivee:D.quartierarrivee,
                          datedemenagement:D.datedemenagement,
                          heuredemenagement:D.heuredemenagement,
                          idclient:D.idclient,
                          idvehicule: D.idvehicule
                        } */
 //Récuperation de plusieurs voitures de déménagement
 getVehicules(): Observable<any> {
  return this.http
        .get<Vehicule>(this.appglobal.ApiUrl+'vehicules');
}

getPositionimmeubles(): Observable<any> {
  return this.http
        .get<any>(this.appglobal.ApiUrl+'positionimmeubles');
}

 //Récuperation de plusieurs voitures de déménagement par identifiant
 getVehiculeById(id:number): Observable<any> {
  return this.http
        .get<Vehicule>(this.appglobal.ApiUrl+'vehicules/'+id);
}

getModeleVehicule(): Observable<any> {
  return this.http
        .get<Modelevehicule>(this.appglobal.ApiUrl+'modeles');
}
getMarqueVehicule(): Observable<any> {
  return this.http
        .get<Marquevehicule>(this.appglobal.ApiUrl+'marques');
}

}
