<div class="col-lg-4  ">
    <div class="single-blog-item">
        <div class="sb-pic">
            <img src="./assets/img/blog/latest-8.jpg" alt="">
        </div>
        <div class="sb-text">
            <ul>
                <li><i class="fa fa-user"></i> Adam Smith</li>
                <li><i class="fa fa-clock-o"></i> 12th Feb, 2019</li>
            </ul>
            <h4><a href="./blog-details.html">The Small Change That Creates Massive Results</a></h4>
        </div>
    </div>
</div>   