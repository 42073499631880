<!--<section class="howit-works spad">
   <div class="container">

        <div class="row">
      
        </div>
    </div>
</section>-->

<section class="popular__city-large">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 mx-auto">
          <div class="title__head">
            <h2 class="text-center text-capitalize">
              NOS STATISTIQUES !!!
            </h2>
            <p class="text-center text-capitalize"><b>{{mois}}  {{annee}}</b></p>
          </div>
        </div>
  
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6">
            <div class="counter green">
                <div class="counter-icon">
                    <span><i class="fa fa-globe"></i></span>
                </div>
                <h3>Nos Biens</h3>
                <span class="counter-value">{{allbiens}}</span> 
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="counter orange">
                <div class="counter-icon">
                    <span><i class="fa fa-briefcase"></i></span>
                </div>
                <h3>Biens Disponibles</h3>
                <span class="counter-value">{{biensdispo}}</span> 
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="counter pink">
                <div class="counter-icon">
                    <span><i class="fa fa-globe"></i></span>
                </div>
                <h3>Nouveautés</h3>
                <span class="counter-value">{{nouveautes}}</span> 
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="counter blue">
                <div class="counter-icon">
                    <span><i class="fa fa-globe"></i></span>
                </div>
                <h3>Taux de Logement</h3>
                <span class="counter-value">{{tauxLogement}} %</span> 
            </div>
        </div>
      </div>
    </div>
</section>
  <!-- END POPULAR CITY -->

  <!--

      <a [routerLink]="['/properties/ville/', ville?.idville]">
            <div class="card__image-hover-style-v3">
              <div class="card__image-hover-style-v3-thumb h-230">
                <img [src]="ville?.photoville" alt="" class="img-fluid w-100">
              </div>
              <div class="overlay">
                <div class="desc">
                  <h6 class="text-capitalize">{{ville?.nomville}}</h6>
                  <p class="text-capitalize"> </p>
                </div>
              </div>
            </div>
          </a>
  -->

