<nb-card >
    <nb-card-body>
      <section class="feature-section spad">
        <div class="container">
           <div class="row">
               <div class="col-lg-12">
               <div class="section-title">
                   <span>Démenagements</span>
                   <h2>Meilleurs services de démenagement au Cameroun</h2>
               </div>
               </div>
           </div>
           </div>
    </section>
     <section class="product-details-area pt-100 pb-70">
           <div class="container">
               <div class="row">
                   <div class="col-lg-4 col-md-12" >
                       <div class="img-box">
                               <img src="{{Vehicule?.photovehicule}}" alt="Image Voiture ici" class="img-fluid">
                       </div><br /><br />
                      
                   </div>
                   <div class="col-lg-8 col-md-12">
                       <div class="products-details-desc">
   
                           <h3>Véhicule :  <strong class="couleur h1 mb-3">{{Vehicule?.nomvehicule}}</strong></h3>
                           <h3>Lieu : <strong class="h3 couleur font-weight-bold">{{Vehicule?.villevehicule?.nomville}} - {{Vehicule?.quartiervehicule?.nomquartier}}</strong></h3>
                           <h3>Type : <strong class="h3 couleur text-success font-weight-bold">{{Vehicule?.typevehicule}}, {{Vehicule?.matriculevehicule}}</strong></h3>
                        </div>
                         <div class="products-details-desc">
                           <div class="products-details-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                               <div class="card">
                                       <div class="card-header" role="tab" id="headingThree">
                                           <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                               Description 
                                               <nb-icon icon="plus-circle" status="success" style="text-align: right; float: right;"></nb-icon>
                                           </a>
                                       </div>
                                       <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                           <div class="card-body">
                                               <div class="products-review-form">
                                               <div class="bg-white widget border rounded">
                                                   <p> {{Vehicule?.descriptionvehicule}}.</p>
                                               </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div> 
                                   <div class="card">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Détails sur le véhicule    
                                            <nb-icon icon="plus-circle" status="success" style="text-align: right; float: right;"></nb-icon>  
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <table class="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td>Tonnage:</td>
                                                        <td class="data">12,5(000 Kg)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>PV :</td>
                                                        <td class="data">4,5(000 Kg)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Couleur:</td>
                                                        <td class="data">Rouge</td>
                                                    </tr>
                                                    <tr>
                                                        <td>nb place:</td>
                                                        <td class="data">03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dimensions:</td>
                                                        <td class="data">16 x 22 x 123 m</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                               </div>
                           </div>
                      
                   </div>
               </div>
           </div>

        
    </section>

 


    <!--<section class="product-details-area pt-100 pb-100">
           <div class="related-products">
               <div class="container">
                   <div class="section-title">
                       <span class="sub-title">Déménagement</span>
                       <h2>Autres Véhicule de la même gamme</h2>
                   </div>

               </div>
           </div>
     </section>-->
   
       </nb-card-body>
   </nb-card>
  
   <nb-layout>
    <nb-layout-column center width="60%">
        <nb-card>
            <nb-card-header center style="background-color: #2cbdb8; text-align: center;">
                <h4 style="color: white;">
                    Remplissez le formulaire de déménagement pour dévis
                </h4>

                        <!--<p class="text-muted">Afin de faire une demande de déménagement, bien vouloir renseigner correctement chacun des champs ci-après</p>-->
            </nb-card-header>
            <nb-card-body>
                <p style="text-align: center;">Champ Obligatoire <b style="color: red;">*</b></p>
                    <p style="text-align: center;"><b>Informations sur les Locaux de deménagement ?</b></p><br />
                            <form [formGroup]="firstForm" #demenagementForm="ngForm" (ngSubmit)="onFirstSubmit()" class="step-container">
                                     <nb-form-field>
                                         <div class="row">
                                                    <br>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                    <nb-select placeholder="Local Départ" attr.ngModel="{{catdepart}}"  (selectedChange)="selectCatDepart($event)" formControlName="catdepart"  class="form-control"  appearance="hero" size="large"
                                                                            shape="semi-round" [ngClass]="{ 'is-invalid': submitted && f.catdepart.errors }">
                                                                        <nb-option  class="current" [value]="0">Categorie</nb-option>
                                                                        <nb-option  class="current" *ngFor="let cat of Categories" (selectedChange)="selectCatDepart($evente)" value="{{cat?.idcategorie}}">{{cat?.nomcategorie}}</nb-option>
                                                                    </nb-select>
                                                            </div>   
                                                        </div>
                                                        <div class="col-md-2">
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select placeholder="Local Arrivé"   formControlName="typelogementdepart"  class="form-control"  appearance="hero" size="large"
                                                                        shape="semi-round" [ngClass]="{ 'is-invalid': submitted && f.typelogementdepart.errors }" required>
                                                                    <nb-option  class="current" [value]="0">Nature Habitation</nb-option>
                                                                    <nb-option  class="current" *ngFor="let type of TypelogementsD" value="{{type?.idtypelogement}}">{{type?.nomtypelogement}}</nb-option>
                                                                </nb-select>
                                                            </div>
                                                        </div>
                                                       
                                        </div><hr>
                                    </nb-form-field>
                                                
                                                <nb-form-field>
                                                    <div class="row">
                                                        <h5></h5>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select placeholder="Catégorie" attr.ngModel="{{catarrivee}}"  (selectedChange)="selectCatArrivee($event)" formControlName="catarrivee"  class="form-control"  appearance="hero" size="large"
                                                                        [ngClass]="{ 'is-invalid': submitted && f.catarrivee.errors }"   shape="semi-round">
                                                                <nb-option  class="current" [value]="0">Categorie</nb-option>
                                                                <nb-option  class="current" *ngFor="let cat of Categories" (selectedChange)="selectCatArrivee($event)" value="{{cat?.idcategorie}}">{{cat?.nomcategorie}}</nb-option>
                                                            </nb-select>
                                                            </div>
                                                        </div><br><hr><br>
                                                        <div class="col-md-2">
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select placeholder="Local de destination" formControlName="typelogementarrivee"  class="form-control"   appearance="hero" size="large"
                                                                            [ngClass]="{ 'is-invalid': submitted && f.typelogementarrivee.errors }" shape="semi-round" required>
                                                                            <nb-option class="current" [value]="typelogementarrivee">Nature Habitation</nb-option>
                                                                            <nb-option class="current" *ngFor="let type of TypelogementsA" [value]="type?.idtypelogement">{{type?.nomtypelogement}}</nb-option>
                                                                </nb-select> 
                                                            </div>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                </nb-form-field>  
                                                <hr> 
                                        <nb-form-field>
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="input-group mb-4">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><b style="color: red;">*</b></span>
                                                        </div>
                                                        <nb-select placeholder="Niv étage depart" formControlName="positionimmeubledepart"  class="form-control"   appearance="hero" size="large"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.positionimmeubledepart.errors }" shape="semi-round" required>
                                                                    <nb-option class="current" *ngFor="let position of Positionimmeubles" [value]="position?.idpositionimmeuble">{{position?.nompositionimmeuble}}</nb-option>
                                                             
                                                    

                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-2"> </div>
                                                <div class="col-md-5">
                                                    <div class="input-group mb-4">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><b style="color: red;">*</b></span>
                                                        </div>
                                                        <nb-select placeholder="Niv étage d'arrivé" formControlName="positionimmeublearrivee"  class="form-control"   appearance="hero" size="large"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.positionimmeublearrivee.errors }" shape="semi-round" required>
                                                                    <nb-option class="current" *ngFor="let position of Positionimmeubles" [value]="position?.idpositionimmeuble">{{position?.nompositionimmeuble}}</nb-option>
                                                              
                                                   

                                                        </nb-select>
                                                    </div>
                                                </div><br />
                                            </div>
                                        </nb-form-field>
                                                <br>
                                                    <p style="text-align: center;"><b>Commodités/Options de déménagement</b></p><br />
                                                        <div class="row">
                                                            <div class="col-md-7">
                                                                Montage/démontage des objets muraux de plus de 3Kg(TV, Meubles, etc)
                                                            </div>
                                                            <div class="col-md-3">
                                                                <nb-toggle status="info"  formControlName="optionmontage" [(ngModel)]="optionmontage" [ngClass]="{ 'is-invalid': submitted && f.optionmontage.errors }" size="small"></nb-toggle>
                                                            </div>
                                                        </div><br /><br />
                                                        <div class="row">
                                                            <div class="col-md-7">
                                                                Nettoyage local de départ
                                                            </div>
                                                            <div class="col-md-3">
                                                                <nb-toggle status="info"  [ngClass]="{ 'is-invalid': submitted && f.optionnetoyageldepart.errors }" formControlName="optionnetoyageldepart" [(ngModel)]="optionnetoyageldepart" size="small"></nb-toggle>
                                                            </div><br /><br />
                                                        </div><br /><br />
                                                        <div class="row">
                                                            <div class="col-md-7">
                                                                Nettoyage local d'arrivé(de destination)
                                                            </div>
                                                            <div class="col-md-3">
                                                                <nb-toggle status="info"   formControlName="optionnetoyagelarrivee" [(ngModel)]="optionnetoyagelarrivee" size="small"></nb-toggle>
                                                            </div>
                                                        </div><hr>
                                                 <div class="row">
                                                   <div class="col-md-12">
                                                        <input nbInput type="text" formControlName="optionautres"  placeholder="Autres options" class="form-control" size="medium" fullWidth>
                                                    </div>
                                                </div><br /><hr>
                                                <p style="text-align: center;"><b>Situation Géographique du Local</b></p><br />
                                                <nb-form-field>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select placeholder="Ville de départ" formControlName="villedepart" class="form-control"  (selectedChange)="selectVille($event)" appearance="hero" size="large"
                                                                                [ngClass]="{ 'is-invalid': submitted && f.villedepart.errors }" shape="semi-round" required>
                                                                                <nb-option class="current" [value]="0">Villes</nb-option>
                                                                                <nb-option class="current" *ngFor="let v of Villes" [value]="v?.idville">{{v?.nomville}}</nb-option>
                                                                </nb-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select  placeholder="Quartier de départ" formControlName="quartierdepart" class="form-control"   appearance="hero" size="large"
                                                                            [ngClass]="{ 'is-invalid': submitted && f.quartierdepart.errors }" shape="semi-round" required>
                                                                            <nb-option class="current" [value]="0">Quatirer</nb-option>
                                                                            <nb-option class="current" *ngFor="let qt of Quartiers" [value]="qt?.idquartier">{{qt?.nomquartier}}</nb-option>
                                                                </nb-select><br /><hr>
                                                            </div>
                                                        </div><br />
                                                    </div>
                                                </nb-form-field>
                                                <nb-form-field>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select placeholder="Ville d'arrivée" formControlName="villearrivee" class="form-control"  (selectedChange)="selectVille2($event)" appearance="hero" size="large"
                                                                                [ngClass]="{ 'is-invalid': submitted && f.villearrivee.errors }" shape="semi-round" required>
                                                                                <nb-option class="current" [value]="0">Villes</nb-option>
                                                                                <nb-option class="current" *ngFor="let v of Villes" [value]="v?.idville">{{v?.nomville}}</nb-option>
                                                                </nb-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                            
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><b style="color: red;">*</b></span>
                                                                </div>
                                                                <nb-select  placeholder="Quartier d'arrivée" formControlName="quartierarrivee" class="form-control"   appearance="hero" size="large"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.quartierarrivee.errors }" shape="semi-round" required>
                                                                            <nb-option class="current" [value]="0">Quatirer</nb-option>
                                                                            <nb-option class="current" *ngFor="let qt of Quartiers2" [value]="qt?.idquartier">{{qt?.nomquartier}}</nb-option>
                                                                </nb-select><br />
                                                            </div>
                                                        </div><br />
                                                    </div><hr><br />
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><nb-icon icon="calendar-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon> <b style="color: red;">*</b></span>
                                                                </div>
                                                                <input nbInput shape="semi-round" type="date" placeholder="Date prévu de votre déménagement" class="form-control" formControlName="datedemenagement" 
                                                                        [ngClass]="{ 'is-invalid': submitted && f.datedemenagement.errors }" size="large" required>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2"> </div>
                                                        <div class="col-md-5">
                                                            <div class="input-group mb-4">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><nb-icon icon="clock-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon> <b style="color: red;">*</b></span>
                                                                </div>
                                                                <input nbInput shape="semi-round" type="time" placeholder="Heure daménagement" class="form-control" formControlName="heuredemenagement" 
                                                                       [ngClass]="{ 'is-invalid': submitted && f.heuredemenagement.errors }" size="large"  required>
                                                            </div>           
                                                                
                                                        </div>
                                                <hr><br> 
                                                    </div>
                                                </nb-form-field>
                                            
                                        
                                                <hr>
                                                <hr><br />
                                                <div class="row" >
                                                    <div class="col-md-4">
                                                        <button  nbButton type="reset" shape="semi-round" outline status="danger"  class="pull-left">Annuler</button>
                                                    </div>
                                                    <div class="col-md-4">
                                                    </div>
                                                    <div class="col-md-4">
                                                       <button  nbButton shape="semi-round" [disabled]="!demenagementForm.form.valid" outline status="success" type="submit" class="pull-right">Valider</button>
                                                    </div>
                                                </div>
                                                                        
                        </form>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
    <nb-layout-column rigth class="colored-column-info"></nb-layout-column>
    <nb-layout-column left class=""></nb-layout-column>
  </nb-layout>
<nb-card >
    <nb-card-body>
        <section class="feature-section spad">
            <div class="container">
            <div class="row">
                <div class="col-lg-12">
                <div class="section-title">
                    <span><i><b>Suggestion</b></i></span>
                    <h2>Autres véhicules de la même gamme</h2>
                </div>
                </div>
            </div>
            </div>
        </section>
        <div class="row">
            <div class="site-section site-section-sm bg-light">
                <div class="container">
                    <div class="row">  
                            <div class="col-md-4" *ngFor="let veh of Vehicules" style="margin-top: 15px;">
                            <vehicule [voiture]="veh"></vehicule>  
                            </div>
                    </div>
                </div>
            </div>
         </div>
    </nb-card-body>
</nb-card>

