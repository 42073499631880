import { Component, OnInit, Input } from '@angular/core';
import {Bienimmobilier} from '../../models/bienimmobilier';
import {ActivatedRoute} from '@angular/router';
import {BiensService} from '../../services/biens.service';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { NbIconLibraries } from '@nebular/theme'; 
import { Gallery, GalleryRef, GalleryConfig } from 'ng-gallery';
import { GalleryItem, ImageItem } from 'ng-gallery';

@Component({
  selector: 'head-single-property',
  templateUrl: './head-single-property.component.html',
  styleUrls: ['./head-single-property.component.css']
})
export class HeadSinglePropertyComponent implements OnInit {
  @Input() idbien:number;
  public Bien:Bienimmobilier;
  constructor(private _Activatedroute:ActivatedRoute, private bienRep: BiensService, private gallery: Gallery,private iconLibraries: NbIconLibraries) { }

  ngOnInit(): void {
    //console.log('--------------------Header card start--------------------------------');
    //console.log(this.idbien);
    //console.log('--------------------Header card end--------------------------------');
    this.getOneBienImmobiliers(Number(this.idbien));
    
  }
  getOneBienImmobiliers(id:number) {
    let loading = true;
    let error = "";
    this.bienRep
          .getOnebiensimmobiliers(id)
            .subscribe(
              (response) => {                           //next() callback
                this.Bien = response; 
               // console.info('Single real estate found is .......... ') 
                //console.log(this.Bien);
              },
              (error) => {                              //error() callback
               // console.error(' Single real estate request failed with error')
                error = error;
                loading = false;
              },
              () => {                                   //complete() callback
               // console.info('Single real estate Request completed')      //This is actually not needed 
                loading = false; 
              })
  }
}
