import { Injectable } from '@angular/core';
//import { Http, Response } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Categorie } from '.././models/categorie';//Typelogement
import { Typelogement } from '.././models/typelogement';//Typelogement
import {Ville} from '.././models/ville'
//import { Observable, throwError } from 'rxjs'; private http: HttpClient,

import { catchError, retry } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import {AppglobalService} from './appglobal.service';

import {Quartier} from '.././models/quartier'



@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
   error = 'error';
  loading = false;
  Categories:Categorie[];
  TypeLogements:Typelogement[];
  Villes:Ville[];
  Quartiers:Quartier[];
  constructor(private http: HttpClient, private lien: AppglobalService ) {
  
    
  }
  getcategories(): Observable<any> {
    return this.http
          .get<Categorie[]>(this.lien.ApiUrl+'categories');/*
          .map((data: Response) => data.json()); */
  } 

  getTypeLogementsbyId(id:number): Observable<any> {
    return this.http
          .get<Categorie[]>(this.lien.ApiUrl+'typelogements/'+id);/*
          .map((data: Response) => data.json()); */
  }
  getTypeLogements(): Observable<any> {
    return this.http
          .get<Typelogement[]>(this.lien.ApiUrl+'typelogements');
  } 
  /////////////////////////////////////////////////////
  getTypeLogementsLocal(): Observable<any> {
    return this.http
          .get<Typelogement[]>('../../assets/data/typelogements.json');
  }
  getcategoriesLocal(): Observable<any> {
    return this.http
          .get<Categorie[]>('../../assets/data/categories.json');/*
          .map((data: Response) => data.json()); */
  } 
  getVillesLocal(): Observable<any> {
    return this.http
          .get<Ville[]>('../../assets/data/villes.json');
  }
  ///////////////////////////////////////////////////////////////////
  getVilles(): Observable<any> {
    return this.http
          .get<Ville[]>(this.lien.ApiUrl+'villes');
  } 
  
  getQuartier(): Observable<any> {
    return this.http
          .get<Quartier[]>(this.lien.ApiUrl+'quartiers/');
  }
  getQuartierbyVille(id:number): Observable<any> {
    return this.http
          .get<Quartier[]>(this.lien.ApiUrl+'quartiersbyvilles/'+id);
  }
/*
  public getcatToArray() {
    this.loading = true;
    this.error = "";
    this.getcategories()
            .subscribe(
              (response) => {                           //next() callback
                this.Categories = response; 
              },
              (error) => {                              //error() callback
                console.error('Request failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                console.error('Request completed')      //This is actually not needed 
                this.loading = false; 
              })
            return this.Categories;
        }
    public getvillesToArray() {
          this.loading = true;
          this.error = "";
          this.getVilles()
                  .subscribe(
                    (response) => {                           //next() callback
                      this.Villes = response;                      
                    },
                    (error) => {                              //error() callback
                      console.error('Request failed with error')
                      this.error = error;
                      this.loading = false;
                    },
                    () => {                                   //complete() callback
                      console.error('Request completed')      //This is actually not needed 
                      this.loading = false; 
                    })
     }
public getquartiersToArray() {
      this.loading = true;
      this.error = "";
      this.getQuartier()
              .subscribe(
                (response) => {                           //next() callback
                  this.Quartiers = response;                   
                },
                (error) => {                              //error() callback
                  console.error('Request failed with error')
                  this.error = error;
                  this.loading = false;
                },
                () => {                                   //complete() callback
                  console.error('Request completed')      //This is actually not needed 
                  this.loading = false; 
                })
 }
 public gettypelogementToArray() {
  this.loading = true;
  this.error = "";
  this.getQuartier()
          .subscribe(
            (response) => {                           //next() callback
              this.TypeLogements = response;               
            },
            (error) => {                              //error() callback
              console.error('Request failed with error')
              this.error = error;
              this.loading = false;       //This is actually not needed 
            },
            () => {                                   //complete() callback
              console.error('Request completed')      //This is actually not needed 
              this.loading = false; 
            })
}*/
  /*
  getData(){
    this.global = '';
    this.dateTime = '';
    this.http.get('https://api.covid19api.com/summary').pipe(map(data => {
      this.global = data['Global'];
      const respDateTime = new Date(data['Date']);
      this.dateTime = respDateTime.toUTCString();
    })).subscribe(result => {
        result;
    });
  }
 
  getConfig() {
    return this.http.get(this.urlcat)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }*/
}
