import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';//this.router.navigate(['/search']);

import {AppglobalService} from '../../services/appglobal.service';
import {CategoriesService} from '../../services/categories.service';
import {SearchService} from '../../services/search.service';
import { Categorie } from '../../models/categorie';
import { Typelogement } from '../../models/typelogement';
import { Ville } from '../../models/ville';
import { Quartier } from '../../models/quartier';
import { Bienimmobilier } from '../../models/bienimmobilier';
import { Search } from '../../models/search';


@Component({
  selector: 'app-side-search',
  templateUrl: './side-search.component.html',
  styleUrls: ['./side-search.component.css']
})
export class SideSearchComponent implements OnInit {

  public Categories:Categorie[];
  public Typelogements:Typelogement[];
  public Villes : Ville[];
  public Quartiers : Quartier[];
  public id:number;
  public ResultSearch:Bienimmobilier[];
  public idcategorie:number=0;
  public idtypelogement:number=0;
  public idville:number=0;
  public idquartier:number=0;
  public searchData:Search;
  public searchcpy:any;
  public searchparam :Object={};
  error: string = '';
  loading: boolean = false;
  
    value: number = 0;
    minValueprix: number = 5000;
    maxValueprix: number = 200000000;
    optionsprix: Options = {
      floor: 5000,
      ceil: 200000000
    };
    minValuesup: number = 20;
    maxValuesup: number = 5000;
    optionssup: Options = {
      floor: 20,
      ceil: 10000
    };
    mindist: number = 10;
    maxdist: number = 500;
    optionsdist: Options = {
      floor: 10,
      ceil: 1000
    };
  
  
    alouer = 'search-text'; 
    avendre= 'home-text';
    contrat:boolean= false;
   change(){
  
   }
   active1(){
    this.alouer = 'search-text'; 
    this.contrat = false;
    this.avendre= 'home-text';
   }
   active2(){
    this.alouer = 'home-text'; 
    this.avendre= 'search-text';
    this.contrat = true;
   }
    constructor(private router: Router, private searchRep:SearchService, private catRep:CategoriesService ) { }
  
    ngOnInit(): void {
        this.getcat(); 
        this.getvilles();
        this.searchcpy = JSON.parse(localStorage.getItem("searchData"));
    }

    public getcat() {
      this.loading = true;
      this.error = "";
      this.catRep
            .getcategories()
              .subscribe(
                (response) => {                           //next() callback
                  this.Categories = response; 
                },
                (error) => {   
                  this.error = error;                           //error() callback
                  //console.error('Get cat Request failed with error'+ this.error);
                  this.loading = false;
                },
                () => {                                   //complete() callback
                  //console.error('Get cat Request completed')      //This is actually not needed 
                  this.loading = false; 
                })
          }
      public getvilles() {
            this.loading = true;
            this.error = "";
            this.catRep
                  .getVilles()
                    .subscribe(
                      (response) => {                           //next() callback
                        this.Villes = response;  
                        this.Villes.sort((a, b) => (a?.idville > b?.idville ? 1 : -1));  
                      },
                      (error) => {                              //error() callback
                        //console.error('Request failed with error'+error);
                        this.error = error;
                        this.loading = false;
                      },
                      () => {                                   //complete() callback
                        //console.error('Request completed')      //This is actually not needed 
                        this.loading = false; 
                      })
       }
    search(dataform){
     /*  this.searchData = Object.assign( {idcategorie:this.idcategorie } ,{idtypelogement:this.idtypelogement},
                                      {idville:this.idville},{idquartier:this.idquartier},
                                      {mindist:this.mindist},{maxdist:this.maxdist},{avendre:this.contrat},
                                      {minValueprix:this.minValueprix},{maxValueprix:this.maxValueprix},
                                      {minValuesup:this.minValuesup},{maxValuesup:this.maxValuesup},
                                      );*/
      this.searchparam = {};
      if(this.idcategorie !=0){ 
           this.searchparam["category"]=this.idcategorie; 
      }
      if(this.idtypelogement !=0){
        this.searchparam["typelogement"]=this.idtypelogement;  
      }
      if(this.idville !=0){
        this.searchparam["ville"]=this.idville;      
      }
      if(this.idquartier !=0){
        this.searchparam["quartier"]=this.idquartier;      
      }
      if(this.mindist !=0){
        this.searchparam["mindist"]=this.mindist;    
      }
     
     if(this.contrat==false || this.contrat==true){
         this.searchparam["avendre"]=this.contrat; 
    }    
      this.searchparam["minValueprix"]=this.minValueprix;     
      this.searchparam["maxValueprix"]=this.maxValueprix;   
      this.searchparam["maxValuesup"]=this.maxValuesup;
      //console.log('Old data search'); //console.log(JSON.parse(localStorage.getItem("searchData")));
      
      localStorage.removeItem("searchData");
      //localStorage.clear();
     
      if(this.router.url === '/search'){
        localStorage.setItem("searchData", JSON.stringify(this.searchparam));
        //console.log('New data search'); //console.log(this.searchparam);
        this.reloadComponent()
      }else{
        localStorage.setItem("searchData", JSON.stringify(this.searchparam));
        //console.log('New data search'); //console.log(this.searchparam);
        this.router.navigate(['/search']);
      }
    }
    reloadComponent() {
      let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/search']);
      }
  public selectType(id){
     // //console.log('Category selected is : ' + id);
      this.idcategorie =parseInt(id);
      ////console.log('Category selected is : ' +  id);
     
      for(let cat of this.Categories){
        ////console.log('Category that matches is : ' +  cat.idcategorie+'ID is '+ id);
        if(cat.idcategorie==id){
          this.Typelogements=cat.typeslogement;
          ////console.log('Category that matches is : ' +  cat.idcategorie+'     ID is '+ id);
        }
      }
    }
    public selectSubcat(id){
        this.idtypelogement = parseInt(id);
      }
    public selectVille(idville){
     // //console.log('Ville selected is : ' + idville);
      this.idville = parseInt(idville);

      /////////////////////////
      this.loading = true;
      this.error = "";
      this.catRep
            .getQuartierbyVille(parseInt(idville))
              .subscribe(
                (response) => {                           //next() callback
                  this.Quartiers = response; 
                },
                (error) => {                              //error() callback
                  console.error('Request failed with error'+error);
                  this.error = error;
                  this.loading = false;
                },
                () => {                                   //complete() callback
                  //console.error('Request completed')      //This is actually not needed 
                  this.loading = false; 
                })
    }
    public selectQuartier(id){
        this.idquartier = id = parseInt(id);
        
    }
  }
  