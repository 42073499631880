import { Injectable } from '@angular/core';
//import {HttpClient} from '@angular/common/http';

const URL = '../assets/data.json';

@Injectable({
  providedIn: 'root'
})
export class AppglobalService {
  public oldUrl:string  = "https://api-easyhome.destiny-innovation.com/v1/web/app_dev.php/";
  public ApiUrl:string = "https://api-easyhome.destiny-innovation.com/v2/public/";


  public slidervideo =[
    {src: "https://mdbootstrap.com/img/video/Tropical.mp4", type:"video/mp4", titre: "Video une", description: "We’ll Defeat Coronavirus!"},
    {src: "https://mdbootstrap.com/img/video/forest.mp4", type:"video/mp4", titre: "Video deux", description: "We’ll Defeat Coronavirus!"},
    {src: "https://mdbootstrap.com/img/video/Tropical.mp4", type:"video/mp4", titre: "Video trois", description: "We’ll Defeat Coronavirus!"},
    {src: "https://mdbootstrap.com/img/video/forest.mp4", type:"video/mp4", titre: "Video quatre", description: "We’ll Defeat Coronavirus!"},
    {src: "https://mdbootstrap.com/img/video/Tropical.mp4", type:"video/mp4", titre: "Video cinq", description: "We’ll Defeat Coronavirus!"},
    {src: "https://mdbootstrap.com/img/video/forest.mp4", type:"video/mp4", titre: "Video six", description: "We’ll Defeat Coronavirus!"}
    
  ];
  Categories = [
    {
      id:1, nomcat:"Logements", chemin:'categories/', imgcat:'./assets/img/categories/logement.jpg',
      subcat:[{id:1, sub: "Chambre"}, {id:2, sub: "Studio"}, {id:3, sub: "Appartement"}, {id:4, sub: "Villa"}]
    },
    {id:2, nomcat:"Bureaux", chemin:'categories/',imgcat:'./assets/img/categories/bureau.jpg',
    subcat:[{id:1, sub: "01 Pièce"}, {id:2, sub: "02 Pièces"}, {id:3,sub: "03 Pièces"}, {id:4, sub: "+de 4 Pièces"}]
  },
    {id:3, nomcat:"Espaces Meublés", chemin:'categories/',imgcat:'./assets/img/categories/meuble.jpg',
    subcat:[{id:1, sub: "Chambre Meublée"}, {id:2, sub: "Studio Meublé"}, {id:3,sub: "Appartement Meublés"}, {id:4,sub: "Villa Meubléé"}]
  },
    {id:4, nomcat:"Espaces Commerciaux", chemin:'categories/',imgcat:'./assets/img/categories/magasin.jpeg',
    subcat:[{id:1, sub: "Boutique"}, {id:2, sub: "Magasin"}, {id:3, sub: "Villa"}]
  },
    {id:5, nomcat:"Salles fêtes", chemin:'categories/',imgcat:'./assets/img/categories/sallefete.jpg',
    subcat:[{id:1, sub: "Simple"}, {id:2, sub: "avec Climatiseur"}, {id:3,sub: "avec Couverts"}, {id:4, sub: "avec Traiteur"}]
  },
    {id:6, nomcat:"Terrains", chemin:'categories/',imgcat:'./assets/img/categories/terrain1.jpg',
    subcat:[{id:1,sub: "non titré"}, {id:2,sub: "Titré"}, {id:3, sub: "Lotis"},]
  },
    {id:7, nomcat:"Garages", chemin:'categories/',imgcat:'./assets/img/categories/garage2.jpg',
    subcat:[{id:1,sub: "Parking"}, {id:2, sub: "Réparation"}, {id:3, sub: "Vidange"}]
  },
  ];


  public Partenaires = [
    {id:1, nom:"ENSP Yaoundé", site:"https://polytechnique.cm/", logo:"./assets/img/partner/polytechnique.png"},
    {id:2, nom:"Technipole SubValor", site:"https://www.facebook.com/Technipolesupvalor1/", logo:"./assets/img/partner/technipole.jpg"},
    {id:3, nom:"Destiny Sarl", site:"https://www.destiny-innovation.com", logo:"./assets/img/partner/destiny.png"}
 
  ];

  Cathegories =[
    {id:1, classicon :"flaticon-home-plan",img:"assets/img/cathegories/img1.jpg", titre: "Des Habitations", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"},
    {id:2, classicon :"flaticon-hand-wash",img:"assets/img/cathegories/img2.jpg", titre: "Des Espaces Commerciaux", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"},
 ];
  public urlimg="assets/img/cathegories/img1.jpg";
    Newproprietes =[
      {id:1, nature: "maison", url:"/products-details", nom:"Chambre Moderne", prix: "20000", img:"assets/img/img_1.jpg"},
      {id:2, nature: "magasin", url:"/products-details", nom:"Chambre Moderne", prix: "20000", img:"assets/img/stats/magasin.png"},
      {id:12, nature: "garage", url:"/products-details", nom:"Chambre Moderne", prix: "20000", img:"assets/img/stats/garage.jpg"},
    ];
    Newmaison =[];
    Newmagasin =[];
    Newterrain =[];
    Newgarage =[];
    public count=0;
    msg ="Cliquez pour plus de détails statistiques !!!!";
    msgvue:boolean=false;

  serviceclient={
    nom:"easyHome Emmergency",
    pays:"Cameroun",
    indicepays:"+237",
    phone:"+237676006736"
  };
  Agents = [
    {id:1, noms:"L. DA VINCY", img:"./assets/img/agent/agent-1.jpg", ville:"Sangmelima-CMR", zone: "Mokolo elobi", social:"neds",},
    {id:1, noms:"THORES", img:"./assets/img/agent/agent-2.jpg", ville:"Yaoundé-CMR", zone: "Mokolo elobi" , social:"neds",},
    ];
    public  MenuVilles = [
      {
          "idville": 3,
          "nomville": "Bafoussam",
          "descriptionville": "Ouest",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531163632822287-bafoussamok.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      },
      {
          "idville": 4,
          "nomville": "Bertoua",
          "descriptionville": "Est",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531161153890219-bertoua1.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-11T00:00:00+01:00"
      },
      {
          "idville": 2,
          "nomville": "Douala",
          "descriptionville": "Littoral",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531162032677246-douala3.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      },
      {
          "idville": 5,
          "nomville": "Ebolowa",
          "descriptionville": "Sud",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531165651637776-ebolowa2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-23T00:00:00+01:00"
      },
      {
          "idville": 8,
          "nomville": "Garoua",
          "descriptionville": "Nord",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531160442989346-garoua3.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-04-08T00:00:00+02:00"
      },
      {
          "idville": 7,
          "nomville": "Maroua",
          "descriptionville": "Extrême-Nord",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531155710497650-maroua1.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-04-08T00:00:00+02:00"
      },
      {
          "idville": 6,
          "nomville": "Ngaoundéré",
          "descriptionville": "Adamaoua",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531153831834871-ngoundere2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2021-02-26T00:00:00+01:00"
      },
      {
          "idville": 1,
          "nomville": "Yaoundé",
          "descriptionville": "Centre",
          "photoville": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230531154037677578-yaounde2.jpg",
          "activated": true,
          "deleted": false,
          "updated": false,
          "dateajout": "2020-10-11T00:00:00+02:00"
      }
  ];
  MenuMeubles = [
    {
        "idtypelogement": 29,
        "nomtypelogement": "Chambre meublée",
        "descriptiontypelogement": "Chambre meublée",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160027202261-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    },
    {
        "idtypelogement": 30,
        "nomtypelogement": "Studio meublé",
        "descriptiontypelogement": "Studio meublé",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160114062579-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    },
    {
        "idtypelogement": 31,
        "nomtypelogement": "Appartement meublé",
        "descriptiontypelogement": "Appartement meublé",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160145845673-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    },
    {
        "idtypelogement": 32,
        "nomtypelogement": "Penthouse meublé",
        "descriptiontypelogement": "Penthouse meublé",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160241809765-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    },
    {
        "idtypelogement": 33,
        "nomtypelogement": "Duplex meublé",
        "descriptiontypelogement": "Duplex meublé",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160327253629-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    },
    {
        "idtypelogement": 34,
        "nomtypelogement": "Triplex meublé",
        "descriptiontypelogement": "Triplex meublé",
        "phototypelogement": "http://api-easyhome.destiny-innovation.com/v1/web/images/20230603160404882208-appartement.jpg",
        "activated": true,
        "deleted": false,
        "updated": false,
        "dateajout": "2023-06-03T00:00:00+02:00",
        "categorie": null
    }
]
  constructor() { }
 
}
