<footer class="footer-section set-bg" style="background-image: url('assets/img/footer-bg.jpg');" data-setbg="assets/img/footer-bg.jpg">
    <div class="container">
      <div class="footer-text">
        <div class="row">
          <div class="col-lg-3">
            <div class="footer-logo">
              <div class="logo">
                <a href="#"><img src="./assets/img/footer-logo.png" alt=""></a>
              </div>
              <p style=" color: white;">Abonnez-vous à notre Newsletter pour être informé des nouvelles mises à jour.</p>
              <form action="#" class="newslatter-form">
                <input type="text" placeholder="Entrez votre e-mail...">
                <button type="submit"><i class="fa fa-location-arrow"></i></button>
              </form>
              <br>
              <a href="https://play.google.com/store/apps/details?id=com.easyhome.best.vision3d.realestate" target="_blank"><img src="./assets/img/playstore.png" alt="Play Store" style="width:400px;"></a>
            </div>
          </div>
          <div class="col-lg-3 offset-lg-1">
            <div class="footer-widget">
              <h4>Villes couvertes</h4>
              <ul>
                <li  *ngFor="let v of Villes | slice:0:4"><i class="fa fa-caret-right"></i> <a [routerLink]="['/properties/ville/', v?.idville]"> {{v.nomville}}</a></li>
              </ul>
              <ul>
                <li  *ngFor="let v of Villes | slice:4:8"><i class="fa fa-caret-right"></i> <a [routerLink]="['/properties/ville/', v?.idville]"> {{v.nomville}}</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="footer-widget">
              <h4>Nous suivre</h4>
              <ul class="social">
                <li><i class="ti-facebook"></i> <a  target="_blank" href="https://web.facebook.com/easyhome3d">Facebook</a></li>
                <li><i class="ti-linkedin"></i> <a  target="_blank" href="https://www.linkedin.com/company/78547391/">LinkedIn</a></li>
                <li><i class="ti-twitter-alt"></i> <a  target="_blank" href="https://twitter.com/easyhome3d">Twitter</a></li> 
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="footer-widget">
              <h4>Nous contacter</h4>
              <ul class="contact-option">
                <li><i class="fa fa-map-marker"></i>Melen, Yaoundé, Cameroun</li>
                <li><i class="fa fa-phone"></i>  (+237) 6 70 91 21 66/ <br /> (+237) 6 92 94 48 91 </li>
                <li><i class="fa fa-envelope"></i>infos@easyhome3d.com</li>
               </ul>
            </div>
          </div>
         
        </div>
      </div>
      <div class="copyright-text">
        <p> 
          Copyright &copy;
           EasyHome {{annee}}, All rights reserved, powored  by <a href="https://www.destiny-innovation.com" target="_blank">Destiny SARL</a>
        </p> 
      </div>
    </div>
  </footer>
