<!-- Search Form Section Begin -->
<div class="search-form">
  <div class="container">
    <div class="row">


      <div class="col-lg-12">
        <div class="search-form-text ">
             
              <div class="{{alouer}}  col-lg-2 " (click)="active1()" style="cursor: pointer;">
                <i class="fa fa-clock-o fa-lg "></i>
                Location
            </div>
            <div class="{{avendre}}  col-lg-2" (click)="active2()" style="cursor: pointer;">
                <i class="fa fa-money fa-lg "></i>
                Vente
            </div>  

        </div>
        <form class="filter-form" #searchForm="ngForm" (ngSubmit)="search(searchForm.value)" id="formId">
          <div class="first-row ">
            <select attr.ngModel="{{idcategorie}}" name="categorie" (change)="selectType($event.target.value)"
              id="categorie" class="nice-select" required>
              <option class="current" [value]="0">Catégorie du bien</option>
              <option class="current" *ngFor="let cat of Categories; let id = index" [value]="cat.idcategorie">
                {{cat.nomcategorie}}</option>
            </select>
            <select attr.ngModel="{{idtypelogement}}" name="typelogement" (change)="selectSubcat($event.target.value)"
              id="typelogement" class="nice-select">
              <option class="current" [value]="0">Type de bien</option>
              <option (click)="selectType($event.target.value)" class="current" *ngFor="let type of Typelogements"
                [value]="type.idtypelogement">{{type.nomtypelogement}}</option>
            </select>
            <select attr.ngModel="{{idville}}" name="ville" (change)="selectVille($event.target.value)" id="ville"
              class="nice-select" required>
              <option class="current" [value]="0">Villes</option>
              <option class="current" *ngFor="let v of Villes" [(value)]="v.idville">{{v.nomville}}</option>
            </select>
            <select attr.ngModel="{{idquartier}}" name="quartier" (change)="selectQuartier($event.target.value)"
              id="quartier" class="nice-select">
              <option class="current" [value]="0">Quatirer</option>
              <option class="current" *ngFor="let qt of Quartiers" [value]="qt.idquartier">{{qt.nomquartier}}</option>
            </select>
          </div>
          <div class="second-row">
            <div class="price-range-wrap">
              <div class="price-text">
                <label for="priceRange">Distance avec la route:[{{mindist}}-{{maxdist}}] m</label>
                <ng5-slider [(value)]="mindist" [(highValue)]="maxdist" [options]="optionsdist"></ng5-slider>
              </div>
            </div>

            <div class="price-range-wrap">
              <div class="price-text">
                <label for="priceRange">Price:[{{minValueprix}}-{{maxValueprix}}] FCFA</label>
                <ng5-slider [(value)]="minValueprix" [(highValue)]="maxValueprix" [options]="optionsprix"></ng5-slider>
              </div>
              <div id="price-range" class="slider"></div>
            </div>
            <div class="price-range-wrap">
              <div class="price-text">
                <label for="priceRange">Surface:[{{minValuesup}}-{{maxValuesup}}] m<sup>2</sup></label>
                <ng5-slider [(value)]="minValuesup" [(highValue)]="maxValuesup" [options]="optionssup"></ng5-slider>
              </div>
            </div>
            <button class="search-btn" type="submit" [disabled]="!searchForm.form.valid">Search</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Search Form Section End (click)="search()" -->