import { Component, OnInit } from '@angular/core';
import { Gallery, GalleryRef,GalleryConfig ,GalleryItem} from 'ng-gallery';
import {AppglobalService} from '../services/appglobal.service';// this.TopProprietes = this.gData.TopProprietes;
import {BiensService} from '../services/biens.service';
import {CategoriesService} from '../services/categories.service';
import { Bienimmobilier } from '../models/bienimmobilier';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { MediaObserver,  MediaChange } from '@angular/flex-layout';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-couroussel',
  templateUrl: './couroussel.component.html',
  styleUrls: ['./couroussel.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateX(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(0)', opacity: 1 }),
          animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'enterAnimationVetically', [
        transition(':enter', [
          style({ transform: 'translateY(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
        ])]
    )
  ],
})
export class CourousselComponent implements OnInit {
  BiensPromotion:Bienimmobilier[]; 
  BiensNouveaux:Bienimmobilier[]; 
  cpyBiens:Bienimmobilier[];
  TopProprietes = [];
  MeilleursAgents =[];

  error: string = '';
loading: boolean = false;

  galleryId = 'mixedExample';
  galleryConfig$: Observable<GalleryConfig>;




  constructor(private gallery: Gallery,private router: Router, 
    private bienRep:BiensService, private gData:AppglobalService, private catRep:CategoriesService,
    breakpointObserver: BreakpointObserver,
    mediaObserver: MediaObserver) { 

      this.galleryConfig$ = mediaObserver.asObservable().pipe(
        map((res: MediaChange[]) => {
          if (res.some((x => x.mqAlias === 'sm' || x.mqAlias === 'xs'))) {
            return {
              thumbPosition: "top",
              thumbWidth: 80,
              thumbHeight: 80
            };
          }
          return {
            thumbPosition: "left",
            thumbWidth: 120,
            thumbHeight: 90
          };
        })
      );

/*       this.galleryConfig$ = breakpointObserver.observe([
        Breakpoints.HandsetPortrait
      ]).pipe(
        map(res => {
          if (res.matches) {
            return {
              thumbPosition: "bottom",
              thumbWidth: 80,
              thumbHeight: 80
            };
          }
          return {
            thumbPosition: "left",
            thumbWidth: 120,
            thumbHeight: 90
          };
        })
      ); */
    }

  ngOnInit() {
    this.getBiensEnPromotion();//
 
  /*
    galleryRef.addImage({
      src: 'assets/img/hero/hero-1.jpg', 
      address:'9721 Glen Creek Ave. Ballston Spa, NY',
      title: 'Villa 9721 Glen Creek',
      price: 'FCFA 3.000.000',
      area: '5201',
      nbbedroom: '8', 
      nbbath: '7',
      nbgarage: '7',
      thumb: 'assets/img/hero/dot-1.jpg'
    });
    galleryRef.addImage({
      src: 'assets/img/hero/hero-2.jpg', 
      address:'9721 Glen Creek Ave. Ballston Spa, NY',
      title: 'Villa 9721 Glen Creek',
      price: 'FCFA 3.000.000',
      area: '5201',
      nbbedroom: '8', 
      nbbath: '7',
      nbgarage: '7',
      thumb: 'assets/img/hero/dot-2.jpg'
      
    });

    galleryRef.addImage({
      src: 'assets/img/hero/hero-3.jpg', 
      address:'9721 Glen Creek Ave. Ballston Spa, NY',
      title: 'Villa 9721 Glen Creek',
      price: 'FCFA 3.000.000',
      area: '5201',
      nbbedroom: '8', 
      nbbath: '7',
      nbgarage: '7',
      thumb: 'assets/img/hero/dot-3.jpg'
      
    });
    galleryRef.addVideo({
      src: 'VIDEO_URL',
      thumb: '(OPTIONAL)VIDEO_THUMBNAIL_URL',
      poster: '(OPTIONAL)VIDEO_POSTER_URL'
    });
*/
    // Add a video item with multiple url sources
    /*galleryRef.addVideo({
      src: [
        { url: 'MP4_URL', type: 'video/mp4' },
        { url: 'OGG_URL', type: 'video/ogg' }
      ],
      thumb: '(OPTIONAL)VIDEO_THUMBNAIL_URL',
      poster: '(OPTIONAL)VIDEO_POSTER_URL'
    });*/

   /* galleryRef.addYoutube({
      src: 'VIDEO_ID'
    });*/
    /*galleryRef.addIframe({
      src: 'IFRAME_URL',
      thumb: '(OPTIONAL)IMAGE_THUMBNAIL_URL'
    });*/



  }
  public getBiensEnPromotion() {
    this.loading = true;
    this.error = "";
     this.bienRep
          .getbiensEnpromotion()
            .subscribe(
              (response) => {                           //next() callback
                this.BiensPromotion = response; 
                this.buildslide();
              },
              (error) => {                              //error() callback
                console.error('Request failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                console.info('Request completed')      //This is actually not needed 
                this.loading = false; 
              }); 
            /*  this.http.post<any>(this.gData.ApiUrl+'filtrebienimmobiliers', { enprommotion:true }).subscribe(data => {
                this.BiensPromotion = data;
            })*/
          
   
  }
  buildslide(){
    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    //const newGalleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    const config: GalleryConfig = {
      autoPlay: true
    };
    galleryRef.setConfig(config);
    let etat ="Moderne";
    //newGalleryRef.setConfig(config);
    for(let b of this.BiensPromotion ){
      if(!b.estmoderne){
        etat = "Simple";
      }else{
        etat = "Moderne";
      }
      galleryRef.addImage({
          idbienimmo: b.idbienimmo,
          src: b.image2ds[0].srcimg, 
          address:b.ville.nomville+' - '+b?.quartier?.nomquartier,
          title: b.designation+' '+etat+', '+b?.category?.nomcategorie,
          price: b.coutloyermensuel,
          area: b.espacelocatif || 0,
          nbbedroom: b.nbrchambreacoucher, 
          nbbath: b.nbrsallebain,
          nbsalon:b.nbrsalon,
          nbgarage: b.parking,
          thumb: b.image2ds[0].srcimg,
          libeleadjectif: b?.unitecout?.libeleadjectif,

      });
    }
  }
  

  gotoproperty(id:number){
    this.router.navigate(['property/'+id]);
  }


}
