
    <nb-layout>
        <nb-sidebar responsive>
          <app-side-search></app-side-search>
        </nb-sidebar>
        <nb-layout-column  class="colored-column-info">   
                <nb-card>
                    <nb-card-body [nbSpinner]="loading">
                        <div class="row" infinite-scroll  >
                            <div  class= "col-md-4 col-lg-4 mb-4"  *ngFor="let bien of resultSearchT | slice:0:10">
                                <div class="feature-carousel" style="margin-top: 25px;">
                                    <div class="feature-item" >
                                        <app-poperty-card [top]="bien"></app-poperty-card>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!datafound" class="card" style="float:center; align-content: center; width: 30%;">
                                <img src="assets/img/error/r1.jpg" alt="Data not found" />
                                <p>Aucun resultat trouvé <br /></p>
                            </div>
                        </div><hr>
                        <h5> {{msgresult}} <b *ngIf="nbresult>0">{{nbresult}}</b></h5>
                         <!--<div class="top-properties-section spad">
                            <div class="container">
                               <div class="top-properties-carousel owl-carousel">  
                                 <div class="card" *ngIf="datafound">
                                        <property-card-list *ngFor="let bien of resultSearchT | slice:0:10"  [bien]="bien" ></property-card-list>
                                  </div>
                                  <div *ngIf="!datafound" class="card" style="float:center; align-content: center; width: 30%;">
                                      <img src="assets/img/error/r1.jpg" alt="Data not found" />
                                      <p>Aucun resultat trouvé <br /></p>
                                  </div>
                                </div>
                            </div>
                        </div>-->
                    </nb-card-body>
                </nb-card>
      
            <app-footer></app-footer>
        </nb-layout-column>
      </nb-layout>