import { Component, OnInit,HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

import { AlertService } from '../../services/auths/alert.service';
import {AuthenticationService} from '../../services/auths/authentication.service';
import {ClientService} from '../../services/client.service';
import { User } from 'src/app/models/user';
import {Client} from 'src/app/models/client';
@Component({ selector: 'login',
            templateUrl: 'login.component.html',
            styleUrls: ['./login.component.css']}
    )
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    public user:User;
    public client:Client;
    message ='Utilisateur inexistant';
    etatconnexion:boolean=false;
    classes = 'example-items-rows';
 
    positions = NbGlobalPhysicalPosition;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private clientRep: ClientService,
        private toastrService: NbToastrService
    ) {
        /* redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }*/
    }

    ngOnInit() {
        let user = JSON.parse(localStorage.getItem("user"));
        if(user && user.token){
           let rep = window.confirm("Vous êtes déjà connecté, vous-vous changer de compter ?");
            if(!rep){
                this.router.navigate(['/']);
            }else{
                localStorage.removeItem("user");
                this.router.navigate(['/login']);
            }
        }
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        //let datas = JSON.parse(localStorage.getItem("searchData"));
        // get return url from route parameters or default to '/'
        this.returnUrl =  this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                (response) => {        
                    this.user= response;
                    if(response.success && response.token){
                        localStorage.setItem("user", JSON.stringify(this.user));
                        //this.estclient(response.user.id);
                        this.showToast(this.positions.TOP_RIGHT, "Connexion établit avec succès", 'success');
                        this.reloadComponent();
                        this.router.navigateByUrl("/");
                    }else{
                        this.showToast(this.positions.TOP_RIGHT, "Echec de Connexion", 'danger');
                        this.etatconnexion=!this.etatconnexion;
                        this.message = response.message;
                        this.loading =  !this.loading
                        
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading =  !this.loading;;
                });
    }
    showToast(position, msg, status) {
  
        this.toastrService.show(status || 'Success', msg, { position, status });
      }
   public estclient(id:number){
    this.clientRep.estClient(id)
    .pipe(first())
    .subscribe(
        (response) => {        
            this.client= response;
            if(response){
                localStorage.setItem("client", JSON.stringify(this.client));
                //console.log("Client Response Params : ");
                //console.log(response);
            }
        },
        error => {
            this.alertService.error(error);
            this.loading =  !this.loading;;
        });
   } 
   reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
}
