import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import {NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbFormFieldModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {AppglobalService} from './services/appglobal.service';
import {BiensService} from './services/biens.service';
import {CategoriesService} from './services/categories.service';
import {SearchService} from './services/search.service';
import {UsersService} from './services/users.service';
import {AuthenticationService} from './services/auths/authentication.service';
import {AlertService} from './services/auths/alert.service';
import {PreloaderService} from './services/preloader.service';
import {ClientService} from './services/client.service';
import {FaqService} from './services/faq.service';

import { SearchResultComponent } from './pages/search-result/search-result.component';
import { SinglePopertyComponent } from './pages/single-poperty/single-poperty.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SideSearchComponent } from './layouts/side-search/side-search.component';
import { HomeSearchComponent } from './layouts/home-search/home-search.component';
import { PopertyCardComponent } from './layouts/poperty-card/poperty-card.component';
import { FooterComponent } from './footer/footer.component';
import { CourousselComponent } from './couroussel/couroussel.component';
import { ContactusComponent } from './contactus/contactus.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsCardComponent } from './pages/news-card/news-card.component';
import { NewsDetailsComponent } from './pages/news-details/news-details.component';
import { Ng5SliderModule } from 'ng5-slider'; 
import { NbMenuModule,NbSidebarModule, NbContextMenuModule } from '@nebular/theme';
import { NbMenuService ,
  NbUserModule,
  NbActionsModule,
  NbTabsetModule,
  NbAccordionModule,
  NbListModule,
  NbIconModule,
  NbCardModule, NbCheckboxModule,
  NbDialogModule,NbTreeGridModule,
  NbInputModule,NbSelectModule,
  NbButtonModule,NbStepperModule,
  NbTooltipModule,NbDatepickerModule,
  NbToggleModule,//NbPopoverModule,
  NbSpinnerModule,  NbSearchModule,
  NbWindowModule,
  NbToastrModule
} from '@nebular/theme';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule,ReactiveFormsModule  }   from '@angular/forms';

import { TopHeaderComponent } from './layouts/top-header/top-header.component'; 
import { GalleryModule } from 'ng-gallery';
import { CcaComponent } from './layouts/cca/cca.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { CatsComponent } from './layouts/cats/cats.component';
import { AgentComponent } from './layouts/agent/agent.component';
import { PartenairesComponent } from './layouts/partenaires/partenaires.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CallComponent } from './layouts/call/call.component';
import { PropertyCardListComponent } from './layouts/property-card-list/property-card-list.component';
//import { TableauComponent } from './layouts/tableau/tableau.component'; 
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { HeadSinglePropertyComponent } from './layouts/head-single-property/head-single-property.component';
import {DatePipe} from '@angular/common';
import { SoumettreDesirsComponent } from './pages/soumettre-desirs/soumettre-desirs.component';
import { DemenagementComponent } from './pages/demenagement/demenagement.component';
import { DemenagementsComponent } from './pages/demenagements/demenagements.component';
import { DetailsDemenagementComponent } from './pages/details-demenagement/details-demenagement.component';
import { VehiculeComponent } from './layouts/vehicule/vehicule.component';
import { FormDossierComponent } from './layouts/form-dossier/form-dossier.component';
import { PreloaderComponent } from './layouts/preloader/preloader.component';
import { FrameplayerComponent } from './layouts/frameplayer/frameplayer.component';
import { SearchTextResultComponent } from './pages/search-text-result/search-text-result.component';
import {NbWindowRef, NbWindowService, NbDialogService } from '@nebular/theme';
import { FaqsComponent } from './pages/faqs/faqs.component';
/*
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
*/
import { HammerModule } from "../../node_modules/@angular/platform-browser";
import { SlideBienComponent } from './layouts/slide-bien/slide-bien.component';
import { PlayerComponent } from './layouts/player/player.component';
import { StatsComponent } from './layouts/stats/stats.component';
import { CountUpDirective } from './count-up.directive';

@NgModule({
  declarations: [ 
    AppComponent,
    SearchResultComponent,
    SinglePopertyComponent,
    HeaderComponent,
    HomeComponent,
    SideSearchComponent,
    HomeSearchComponent,
    PopertyCardComponent,
    FooterComponent,
    CourousselComponent,
    ContactusComponent,
    NewsComponent,
    NewsCardComponent,
    NewsDetailsComponent,
    TopHeaderComponent,
    CcaComponent,
    PropertiesComponent,
    CatsComponent,
    AgentComponent,
    PartenairesComponent,
    FaqComponent,
    CallComponent,
    //TableauComponent,
    PropertyCardListComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    HeadSinglePropertyComponent,
    SoumettreDesirsComponent,
    DemenagementComponent,
    DemenagementsComponent,
    DetailsDemenagementComponent,
    VehiculeComponent,
    FormDossierComponent,
    FrameplayerComponent,
    SearchTextResultComponent,
    FaqsComponent,
    SlideBienComponent,
    PlayerComponent,
    StatsComponent,
    CountUpDirective,
    //PreloaderComponent, 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbSearchModule,
    NbEvaIconsModule,
    Ng5SliderModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbUserModule,
    NbActionsModule,
    NbTabsetModule,
    NbCardModule,
    GalleryModule,
    NbAccordionModule,   
     NbListModule, 
     NbIconModule,
     NbInputModule,
     NbToggleModule,
     NbFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NbButtonModule,
  NbTooltipModule,NbTreeGridModule,
    NbDialogModule.forRoot(),
    HttpClientModule,
    NbSidebarModule,
    NbSidebarModule.forRoot(),
    NbInputModule,
    NbStepperModule,
    NbSelectModule, 
    NbCheckboxModule,
    NbSpinnerModule,
    NbDatepickerModule.forRoot(),
    NbWindowModule.forRoot(),
    InfiniteScrollModule,
    NbToastrModule.forRoot(),/*
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,*/
  ],
  providers: [
    DatePipe,
    NbMenuService,AppglobalService, BiensService, 
    CategoriesService, UsersService, SearchService,ClientService,
    AuthenticationService, AlertService, PreloaderService,
     NbWindowService, NbDialogService,
     
    /* NbTreeGridDataSource, NbTreeGridDataSourceBuilder*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
