import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {AppglobalService} from '../appglobal.service';
import { map } from "rxjs/operators";
import { User } from "../.././models/user";
import {Login} from  "../.././models/login";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private httpOptions: any;
  public isLoggedIn:boolean=false;
  constructor(private http: HttpClient, private appglobal: AppglobalService) {
    this.httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

      //verifions si l'utilisateur est connecté ou pas
      let currentUser = JSON.parse(localStorage.getItem("user"));
      if(currentUser && currentUser.success){
          this.isLoggedIn=true;
      }else{
        this.isLoggedIn=false;
      }
      //fin verification !!!!!!!!!!!!!!!!!!!!!!!!


  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string) {
    if(this.http
      .post<any>(this.appglobal.ApiUrl+'loginrest',
          JSON.stringify({ 
                            email : username,
                            password: password
                        }))
            .map((user) => user))
      {this.isLoggedIn=!this.isLoggedIn;;
    }else{
      this.isLoggedIn=!this.isLoggedIn;
    }

    return this.http
      .post<any>(this.appglobal.ApiUrl+'loginrest',
          JSON.stringify({ 
                            email : username,
                            password: password
                        }))
            .map((user) => user);
  }
//
  public creationcompte(user: User){
    //console.log('voici les données de création de compte coté service recu du component');
    //console.log(user);
    //const headers = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*"};

      return this.http.post<any>(  this.appglobal.ApiUrl+'createuser', 
             JSON.stringify({   nomuser: user.nomuser,
                                prenomuser: user.prenomuser,
                                datenaisuser: user.datenaisuser,
                                email:user.email,
                                login:user.login,
                                password:user.password,
                                phonenumber:user.phonenumber,
                                sexe:user.sexe,
                                typeuser:user.typeuser,
                          }))
             .map((data) => data); 
    }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    this.currentUserSubject.next(null);
  }
}