<!--<nb-layout>
    <nb-layout-column center class="">
            <nb-card status="success">
                <nb-card-header center>
                  
                    <div class="row">
                        <div class="col-md-5"> </div>
                        <div class="col-md-3" style="float: right;">
                                <form>
                                    <div class="form-group">
                                            <select  (change)="selectFaqCat($event.target.value)" id="idcatfaq" class="form-control">
                                                        <option value="0"> Catégorie Faq</option> 
                                                        <option *ngFor="let c of cats" value="{{c?.idcategoriefaq}}">{{c.nomcategoriefaq}}</option>
                                            </select>
                                            
                                    </div>
                                </form>        
                        </div><div class="col-md-4"></div>
                    </div>
            </nb-card-header>
        </nb-card>
    </nb-layout-column>
</nb-layout>-->
  <div class="section-title">
    <span class="sub-title">Faq's</span>
    <h2>Des Questions que vous vous posez certainement</h2>
  </div>
  <div class="row">
      
    <div class="col-md-4" style="float: right;">
            <form>
                <div class="form-group">
                        <select  (change)="selectFaqCat($event.target.value)" id="idcatfaq" class="form-control">
                                    <option value="0"> Catégorie Faq</option> 
                                    <option *ngFor="let c of cats" value="{{c?.idcategoriefaq}}">{{c.nomcategoriefaq}}</option>
                        </select>
                </div>
            </form>        
    </div>
    <div class="col-md-8">
        <nb-accordion multi>
            <nb-accordion-item *ngFor="let faq of FAQS">
              <nb-accordion-item-header>
                {{faq.question}}
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                {{faq.reponse}}.
              </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </div>
</div>

<section class="faq-section-two ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
           <!--<ul class="tabs d-flex flex-wrap justify-content-center">     
               <li *ngFor="let catfaq of cats | slice:0:6" ><a (click)="getfaqBycat(catfaq.idcategoriefaq)"><i class='bx bx-command'></i><span><nb-icon icon="book-open-outline" status="success"></nb-icon>catfaq.nomcategoriefaq</span></a></li> 
           </ul>-->
           <div class="row">
               <div class="col-lg-6 col-md-12">
                   <div class="tab-content">
                       <div class="tabs-item">
                           <div class="faq-accordion-content" *ngFor="let faq of FAQS | slice:0:5"  id="accordionEx" role="tablist" aria-multiselectable="true">
                               <div class="card" >
                                     <div class="card-header" role="tab" id="headingOne">
                                        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            {{faq.questionfaq}}
                                            <i class="flaticon-add"></i>
                                        </a>
                                    </div>
            
                                    <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>{{faq.reponsefaq}}</p>
                                        </div>
                                    </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="col-lg-6 col-md-12">
                   <div class="tab-content">
                       <div class="tabs-item">
                           <div class="faq-accordion-content" *ngFor="let faq of FAQS | slice:5:10" id="accordionEx" role="tablist" aria-multiselectable="true">
                               <div class="card">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            {{faq.questionfaq}}
                                            <i class="flaticon-add"></i>
                                        </a>
                                    </div>
            
                                    <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>{{faq.reponsefaq}}</p>
                                        </div>
                                    </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</section>

<!--<section class="faq-section-two ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li *ngFor="let d of data | slice:0:6"><a><i class='bx bx-book-reader'></i><span>{{d}}</span></a></li>
                <li *ngFor="let c of cats | slice:0:6"><a><i class='bx bx-book-reader'></i><span><nb-icon icon="book-open-outline" status="success"></nb-icon>{{c.nomcategoriefaq}}</span></a></li> 
            </ul>
            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
  






<!--<section class="faq-area ptb-100 bg-f9f9f9">
 <div class="container">
     <div class="section-title">
         <span class="sub-title">Faq's</span>
         <h2>Des Questions que vous vous posez certainement</h2>
     </div>

     <div class="row">
         <div class="col-lg-5 col-md-12">
             <div class="faq-image faq-bg1">
                 <img src="assets/img/icon1.png" alt="image">
              
                 <a  class="video-btn popup-youtube" (click)="openWindow()"><i class="flaticon-play-button"></i><nb-icon icon="play-circle-outline"></nb-icon></a>
               
             </div>
         </div>
       
            <ng-template #videotemplate let-data>
                <frameplayer [urlvideo]="videoToken"></frameplayer>
            </ng-template>
        
            

         <div class="col-lg-7 col-md-12">
            <div class="card" style="text-align: center;">
                <h5>Les Faqs les plus solicités</h5>
            </div>
            <nb-accordion multi>
                <nb-accordion-item *ngFor="let faq of Faqbycat">
                  <nb-accordion-item-header>
                    {{faq.question}}
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    {{faq.reponse}}.
                  </nb-accordion-item-body>
                </nb-accordion-item>
              </nb-accordion>
             <div class="faq-accordion"  *ngFor="let faq of FAQ" id="accordionEx" role="tablist" aria-multiselectable="true">
                 <div class="card">
                     <div class="card-header" role="tab" [id]="faq.id">
                         <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" [href]="'#'+faq.labelid" aria-expanded="false" attr.aria-controls="{{faq.labelid}}">
                             {{faq.question}}
                             <nb-icon icon="plus-circle" status="success" style="text-align: right; float: right;"></nb-icon>
                         </a>
                     </div>
                     <div [id]="faq.labelid" class="collapse" role="tabpanel" attr.aria-labelledby="{{faq.id}}" data-parent="#accordionEx">
                         <div class="card-body">
                             <p>{{faq.reponse}}.</p>
                         </div>
                     </div>
                 </div>

             </div>
         </div>
     </div>
 </div>
</section>
<hr>


<service client/ <centre d'appel -->
<br /> 

    <call></call>
                 <!--
                 <div class="tabs-item">
                     <div class="faq-accordion-content" id="accordionExTwo" role="tablist" aria-multiselectable="true">
                         <div class="card">
                             <div class="card-header" role="tab" id="headingSix">
                                 <a data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                     What is the source of the virus?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseSix" class="collapse show" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionExTwo">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingSeven">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                     How does the virus spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionExTwo">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingEight">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                     Who has had COVID-19 spread the illness to others?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionExTwo">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingNine">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                     Will warm weather stop the outbreak of COVID-19?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionExTwo">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                     What is community spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionExTwo">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
 
                 <div class="tabs-item">
                     <div class="faq-accordion-content" id="accordionExThree" role="tablist" aria-multiselectable="true">
                         <div class="card">
                             <div class="card-header" role="tab" id="headingEleven">
                                 <a data-toggle="collapse" data-parent="#accordionExThree" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                     What is the source of the virus?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseEleven" class="collapse show" role="tabpanel" aria-labelledby="headingEleven" data-parent="#accordionExThree">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwelve">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                     How does the virus spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwelve" class="collapse" role="tabpanel" aria-labelledby="headingTwelve" data-parent="#accordionExThree">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingThirteen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                     Who has had COVID-19 spread the illness to others?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseThirteen" class="collapse" role="tabpanel" aria-labelledby="headingThirteen" data-parent="#accordionExThree">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingFourteen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                     Will warm weather stop the outbreak of COVID-19?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseFourteen" class="collapse" role="tabpanel" aria-labelledby="headingFourteen" data-parent="#accordionExThree">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingFifteen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                     What is community spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseFifteen" class="collapse" role="tabpanel" aria-labelledby="headingFifteen" data-parent="#accordionExThree">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
 
                 <div class="tabs-item">
                     <div class="faq-accordion-content" id="accordionExFour" role="tablist" aria-multiselectable="true">
                         <div class="card">
                             <div class="card-header" role="tab" id="headingSixteen">
                                 <a data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                     What is the source of the virus?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseSixteen" class="collapse show" role="tabpanel" aria-labelledby="headingSixteen" data-parent="#accordionExFour">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingSeventeen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                     How does the virus spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseSeventeen" class="collapse" role="tabpanel" aria-labelledby="headingSeventeen" data-parent="#accordionExFour">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingEighteen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                     Who has had COVID-19 spread the illness to others?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseEighteen" class="collapse" role="tabpanel" aria-labelledby="headingEighteen" data-parent="#accordionExFour">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingNineteen">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                     Will warm weather stop the outbreak of COVID-19?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseNineteen" class="collapse" role="tabpanel" aria-labelledby="headingNineteen" data-parent="#accordionExFour">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwenty">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                     What is community spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwenty" class="collapse" role="tabpanel" aria-labelledby="headingTwenty" data-parent="#accordionExFour">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
 
                 <div class="tabs-item">
                     <div class="faq-accordion-content" id="accordionExFive" role="tablist" aria-multiselectable="true">
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwentyOne">
                                 <a data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                     What is the source of the virus?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwentyOne" class="collapse show" role="tabpanel" aria-labelledby="headingTwentyOne" data-parent="#accordionExFive">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwentyTwo">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                     How does the virus spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwentyTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwentyTwo" data-parent="#accordionExFive">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwentyThree">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                     Who has had COVID-19 spread the illness to others?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwentyThree" class="collapse" role="tabpanel" aria-labelledby="headingTwentyThree" data-parent="#accordionExFive">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwentyFour">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                     Will warm weather stop the outbreak of COVID-19?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwentyFour" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFour" data-parent="#accordionExFive">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
     
                         <div class="card">
                             <div class="card-header" role="tab" id="headingTwentyFive">
                                 <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                     What is community spread?
                                     <i class="flaticon-add"></i>
                                 </a>
                             </div>
     
                             <div id="collapseTwentyFive" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFive" data-parent="#accordionExFive">
                                 <div class="card-body">
                                     <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div> </section>-->

