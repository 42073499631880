import { Component, OnInit } from '@angular/core';
import { Gallery,ImageItem, GalleryRef,GalleryConfig ,GalleryItem} from 'ng-gallery';
import {AppglobalService} from '../../services/appglobal.service';// this.TopProprietes = this.gData.TopProprietes;
import {BiensService} from '../../services/biens.service';
import {CategoriesService} from '../../services/categories.service';
import { Bienimmobilier } from '../../models/bienimmobilier';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'; 
import {   trigger, state, style, animate, transition } from '@angular/animations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MediaObserver,  MediaChange } from '@angular/flex-layout';



@Component({
  selector: 'slidebien',
  templateUrl: './slide-bien.component.html',
  styleUrls: ['./slide-bien.component.css']
})
export class SlideBienComponent implements OnInit {
  images: GalleryItem[];
  BiensSuggeres:Bienimmobilier[]; 
  

  error: string = '';
loading: boolean = false;

  galleryId = 'mixedExample';
  galleryConfig$: Observable<GalleryConfig>;
  constructor(private gallery: Gallery,private router: Router, 
    private bienRep:BiensService, private gData:AppglobalService, private catRep:CategoriesService,
    breakpointObserver: BreakpointObserver,
    mediaObserver: MediaObserver) { }



  ngOnInit() {
    // Set items array
    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    //const newGalleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    const config: GalleryConfig = {
      autoPlay: true
    };
    this.getBienSuggeres();
    for(let b of this.BiensSuggeres ){
      console.log(b);
      this.images.push( new ImageItem({ src: b.image2ds[0].srcimg }));
      /*this.gallery.load([
        new ImageItem({ src: 'IMAGE_SRC_URL', thumb: 'IMAGE_THUMBNAIL_URL' }),
        // ... more items
      ]);*/
    }
    
     
      // ... more items
    //this.images = [];
  }
  public getBienSuggeres() {
    this.loading = true;
    this.error = "";
     this.bienRep
          .getbiensEnpromotion()
            .subscribe(
              (response) => {                           //next() callback
                this.BiensSuggeres = response; 
                //this.buildslide();
              },
              (error) => {                              //error() callback
                //console.error('Request failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.info('Request completed')      //This is actually not needed 
                this.loading = false; 
              }); 
      
          
   
  }
}
