<nb-layout>
    <nb-layout-column center>
        
<nb-card>
    <nb-card-header center class="fond">
                <h3 style="color:white;">Soumettre un désir </h3>
    </nb-card-header>
    <nb-card-body>
                <h5  style="text-align: center;">Veuillez renseigner les caractéristiques du bien recherché</h5>
                <p style="text-align: center;"> Champ Obligatoire  : <b class="obli">*</b></p>
                <form [formGroup]="secondForm" #desirForm="ngForm"  (ngSubmit)="onSubmitDesir($event.target.value)" class="step-container">
                    <nb-form-field><br>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><b class="obli">*</b></span>
                                    </div>
                                        <nb-select  placeholder="Categorie du bien désiré" formControlName="idcategorie" class="form-control" ([ngModel])="idcategorie"  (selectedChange)="selectType($event)"  appearance="filled"  size="large"
                                                        [ngClass]="{ 'is-invalid': submitted && f1.idcategorie.errors }" required>
                                                        <!--<nb-option class="current"  [value]="0">Type Proprietés</nb-option>-->
                                                        <nb-option class="current" *ngFor="let cat of Categories; let id = index" value="{{cat.idcategorie}}">{{cat.nomcategorie}}</nb-option>
                                        </nb-select>
                                </div>
                            </div> 
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-5">
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><b class="obli">*</b></span>
                                    </div>
                                    <nb-select placeholder="Sous catégorie" formControlName="idtypelogement" ([ngModel])="idtypelogement" class="form-control" (selectedChange)="selectSubcat($event)"  appearance="hero" size="large"
                                                [ngClass]="{ 'is-invalid': submitted && f1.idtypelogement.errors }" required>
                                                <!--<nb-option  class="current" [value]="0">Sous catégorie</nb-option>-->
                                                <nb-option (click)="selectType($event.target.value)" class="current" *ngFor="let type of Typelogements" value="{{type.idtypelogement}}">{{type.nomtypelogement}}</nb-option>
                                    </nb-select><br />
                                </div>
                            </div><br />
                        </div>
                    </nb-form-field>
                    <hr><br>
                    <nb-form-field>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><b class="obli">*</b></span>
                                    </div>
                                    <nb-select placeholder="Ville du bien désiré" formControlName="idville" class="form-control" ([ngModel])="idville" (selectedChange)="selectVille($event)" appearance="hero" size="large"
                                                    [ngClass]="{ 'is-invalid': submitted && f1.idville.errors }" required>
                                                    <!--<nb-option class="current">Villes</nb-option>-->
                                                    <nb-option class="current" *ngFor="let v of Villes" [(value)]="v.idville">{{v.nomville}}</nb-option>
                                    </nb-select>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-5">
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><b class="obli">*</b></span>
                                    </div>
                                    <nb-select multiple placeholder="Quartier(s)" formControlName="idquartier" [formControl]="secondForm.controls['idquartier']" class="form-control" ([ngModel])="idquartier" (selectedChange)="selectQuartier($event)" appearance="hero" size="large"
                                            [ngClass]="{ 'is-invalid': submitted && f1.idquartier.errors }" required>
                                            <!--<nb-option class="current">Quatirer</nb-option>-->
                                            <nb-option class="current" *ngFor="let qt of Quartiers" [value]="qt.idquartier">{{qt.nomquartier}}</nb-option>
                                    </nb-select><br />
                                </div>
                            </div><br />
                        </div>
                    </nb-form-field>
                    <hr><br>
                    <nb-form-field>
                       <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><b class="obli">*</b></span>
                                        </div>
                                        <nb-select  placeholder="Contrat" formControlName="typecontrat"  class="form-control" ([ngModel])="typecontrat" (selectedChange)="selectTypeContrat($event)" appearance="hero" size="large"
                                                    [ngClass]="{ 'is-invalid': submitted && f1.typecontrat.errors }" required>
                                                    <nb-option value="A vendre">Vente</nb-option>
                                                    <nb-option value="A louer">Location</nb-option>
                                        </nb-select>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                </div>
                       </div>
                    </nb-form-field>
                    <hr><br>
                    <div class="second-row">
                        <div class="price-range-wrap">
                            <div class="price-text">
                              <label for="priceRange">Définir la distance avec la route :[{{mindist}}-{{maxdist}}] m</label>
                              <ng5-slider [(value)]="mindist" [(highValue)]="maxdist" [options]="optionsdist"></ng5-slider>
                            </div>
                          </div>
                          <br><hr><br>
                      <div class="price-range-wrap">
                        <div class="price-text">
                          <label for="priceRange">Quelle est votre plage de Prix (Price range) :[{{minValueprix}}-{{maxValueprix}}] FCFA</label>
                          <ng5-slider [(value)]="minValueprix" [(highValue)]="maxValueprix" [options]="optionsprix"></ng5-slider>
                        </div>
                        <div id="price-range" class="slider"></div>
                      </div>
                      <hr><br>
                      <div class="price-range-wrap">
                        <div class="price-text">
                          <label for="priceRange">Superficie du bien recherché [{{minValuesup}}-{{maxValuesup}}] m<sup>2</sup></label>
                          <ng5-slider [(value)]="minValuesup" [(highValue)]="maxValuesup" [options]="optionssup"></ng5-slider>
                        </div>
                      </div>
                     </div>
                     <hr><br>
                        <div class="row">
                            <div class="col-md-4">
                                <button nbButton shape="semi-round" outline status="danger"  class="btn btn-danger  px-4 pull-left">Annuler</button>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button nbButton type="ngSubmit" [disabled]="!desirForm.form.valid" shape="semi-round" outline status="success" class="btn btn-success  px-4 pull-right">Valider</button>
                            </div>
                        </div>
                    
            
        </form>
    </nb-card-body>
  </nb-card>
    </nb-layout-column>
    <nb-layout-column rigth class="colored-column-info"></nb-layout-column>
    <nb-layout-column left class=""></nb-layout-column>
  </nb-layout>
