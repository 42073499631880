import { Component, OnInit, Input } from '@angular/core';
import {Vehicule} from '../../models/vehicule'

@Component({
  selector: 'vehicule',
  templateUrl: './vehicule.component.html',
  styleUrls: ['./vehicule.component.css']
})
export class VehiculeComponent implements OnInit {
@Input() voiture:Vehicule
  constructor() { }

  ngOnInit(): void {
    //console.log(this.voiture);
  }

}
