import { Component, OnInit , Input} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Bienimmobilier} from '../../models/bienimmobilier';


@Component({
  selector: 'app-poperty-card',
  templateUrl: './poperty-card.component.html',
  styleUrls: ['./poperty-card.component.css']
})
export class PopertyCardComponent implements OnInit { 
  @Input() top;
  bienimmo:Bienimmobilier;
  constructor(private router: Router) {
    
   }

  ngOnInit(): void {
    // //console.log('--------------------Property card start--------------------------------');
     //this.top = this.traiterBien(this.bienimmo)
    // //console.log(this.top);
    // //console.log('--------------------Property card end----------------------------------')
  }
  gotoproperty(){
      this.router.navigate(['/property']);
  }

  public traiterBien(B:Bienimmobilier){

      /*if(typeof(B.typelogement)===undefined){
       // B.typelogement = "Logement Moderne";
       B = Object.assign(B, {typelogement:"Logement Moderne"} );
      }else{
        B.typelogement = "Logement Moderne";
      }
  
      if(B.image2ds[0].srcimg){
          B.image2ds = B.image2ds[0].srcimg;
        }else{  
          B = Object.assign(B, {image2ds:"assets/img/img_2.jpg"} );
        }
      if(B.idcategorie.nomcategorie){
          B.idcategorie = B.idcategorie.nomcategorie;
        }else{
          B = Object.assign(B, {idcategorie: "Indisponible"} );
        }
      if(typeof(B.idquartier.nomquartier)!==undefined){
        B = Object.assign(B, {idquartier: "IndisponiB.le"} );//B.idquartier = B.idquartier.nomquartier; 
        }else{
          //console.log("done !!!");
        }
      if(B.idville.nomville){
          B.idville = B.idville.nomville;
        }else{
          B = Object.assign(B, {idville: "Indisponible"} );
        }
       
      if(B.avendre==true){B.avendre='à vendre'}else{B.avendre='à louer'}
      if(B.nouveaute==true){B.nouveaute='Nouveau'}else{B.nouveaute='Disponible'}
      if(B.estmoderne==true){B.estmoderne='Moderne'}else{B.estmoderne='Simple'}
      let bien = Object.assign( {idbienimmo:B.idbienimmo } ,{idcategorie:B.idcategorie.nomcategorie},
          {image2ds:B.image2ds[0].srcimg},{avendre:B.avendre},{nouveaute:B.nouveaute},
          {designation:B.designation},{estmoderne:B.estmoderne},
          {idcategorie:B.idcategorie.nomcategorie},{typelogement:B.typelogement},
          {idville:B.idville.nomville},{idquartier:B.idquartier.nomquartier},
          {courtedescription:B.courtedescription},{coutloyermensuel:B.coutloyermensuel},
          {niveauaccessibilite:B.niveauaccessibilite}
        );
      
       //console.log('******************************************************');
       //console.log( B.image2ds);//console.log( B.idquartier); 
       //console.log( B.idcategorie);//console.log( B.idville);
       //console.log('*****************************************************-');
      return bien; */
  }
}
