import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';//this.router.navigate(['/search']);

import { AppglobalService } from '../../services/appglobal.service';
import { CategoriesService } from '../../services/categories.service';
import { SearchService } from '../../services/search.service';
import { Categorie } from '../../models/categorie';//public Categories: Categorie[];
import { Typelogement } from '../../models/typelogement';
import { Ville } from '../../models/ville';
import { Quartier } from '../../models/quartier';
import { Bienimmobilier } from '../../models/bienimmobilier';
import { Search } from '../../models/search';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.css']
})
export class HomeSearchComponent implements OnInit {


  public Categories: Categorie[];
  public Typelogements: Typelogement[];
  public Villes: Ville[];
  public Quartiers: Quartier[];
  public id: number;
  public ResultSearch: Bienimmobilier[];
  public idcategorie: number = 0;
  public idtypelogement: number = 0;
  public idville: number = 0;
  public idquartier: number = 0;
  public searchData: Search;
  public searchparam :Object={};
  error: string = '';
  loading: boolean = false;

  value: number = 100;
  minValueprix: number = 5000;
  maxValueprix: number = 200000000;
  optionsprix: Options = {
    floor: 5000,
    ceil: 200000000
  };
  minValuesup: number = 20;
  maxValuesup: number = 10000;
  optionssup: Options = {
    floor: 10,
    ceil: 10000
  };
  mindist: number = 10;
  maxdist: number = 1000;
  optionsdist: Options = {
    floor: 10,
    ceil: 1000
  };


  alouer = 'search-text';
  avendre = 'home-text';
  contrat: boolean=false;
 
  active1() {
    this.alouer = 'search-text';
    this.contrat = false; // this.contrat ||true = a vendre; false= a louer
    this.avendre = 'home-text';
  }
  active2() {
    this.alouer = 'home-text';
    this.avendre = 'search-text';
    this.contrat = true;
  }
  constructor(private router: Router, private searchRep: SearchService, private catRep: CategoriesService) { }

  ngOnInit(): void {
    this.getcat();
    this.getvilles();
  }


  public getcat() {
    this.loading = true;
    this.error = "";
    this.catRep
      .getcategories()
      .subscribe(
        (response) => {                           //next() callback
          this.Categories = response;
          // //console.log('response received')
          //console.log("************************************************");
          //console.log(this.Categories);
          //console.log("***********************************************************");

        },
        (error) => {                              //error() callback
          console.error('Request failed with error'+error);
          this.error = error;
          this.loading = false;
        },
        () => {                                   //complete() callback
          //console.error('Request completed')      //This is actually not needed 
          this.loading = false;
        })
  }
  public getvilles() {
    this.loading = true;
    this.error = "";
    this.catRep
      .getVilles()
      .subscribe(
        (response) => {                           //next() callback
          this.Villes = response;
          this.Villes.sort((a, b) => (a?.idville > b?.idville ? 1 : -1)); //Trier villes
        },
        (error) => {                              //error() callback
          console.error('Request failed with error'+error)
          this.error = error;
          this.loading = false;
        },
        () => {                                   //complete() callback
          //console.error('Request completed')      //This is actually not needed 
          this.loading = false;
        })
  }
search(dataform) {
    /*   this.searchData = Object.assign({ idcategorie: this.idcategorie }, { idtypelogement: this.idtypelogement },
      { idville: this.idville }, { idquartier: this.idquartier },
      { mindist: this.mindist }, { maxdist: this.maxdist }, { avendre: this.contrat },
      { minValueprix: this.minValueprix }, { maxValueprix: this.maxValueprix },
      { minValuesup: this.minValuesup }, { maxValuesup: this.maxValuesup },
    ); 
    this.searchData ={};*/
  
    if(this.idcategorie !=0){ 
        this.searchparam["category"]=this.idcategorie;  
        console.info("this.categorie *************= "+this.searchparam["category"])  ;
      }
    if(this.idtypelogement !=0){
      this.searchparam["typelogement"]=this.idtypelogement;   
    }
    console.info("this.idville*************= "+this.idville)  ;
    if(this.idville !=0){
      this.searchparam["ville"]=this.idville;     
    }
    console.info("this.idquartier*************= "+this.idquartier)  ;
    if(this.idquartier !=0){
      this.searchparam["quartier"]=this.idquartier;      

    }
 
    if(this.mindist !=0){
      this.searchparam["mindist"]=this.mindist;  
      console.info("this.idville*************= "+this.searchparam["category"])  ;    

    }
    if(this.maxdist !=0){
      this.searchparam["maxdist"]=this.maxdist;   
      console.info("this.maxdist *************= "+this.searchparam["maxdist"])  ;   
         

    }
     this.searchparam["avendre"]=this.contrat;     
     this.searchparam["minValueprix"]=this.minValueprix;     
     this.searchparam["maxValueprix"]=this.maxValueprix;   
     this.searchparam["maxValuesup"]=this.maxValuesup; 
    
    console.info("this.searchparam*************")  ;
    console.info(this.searchparam)  

    //localStorage.setItem("searchData", JSON.stringify(this.searchData));
    localStorage.removeItem("searchData");
    localStorage.setItem("searchData", JSON.stringify(this.searchparam));
    
    this.router.navigate(['/search']);

  }

  public selectType(id) {
    
    this.idcategorie = parseInt(id);

    for (let cat of this.Categories) {
      ////console.log('Category that matches is : ' +  cat.idcategorie+'ID is '+ id);
      if (cat.idcategorie == id) {
        this.Typelogements = cat.typeslogement;
        //console.log('Category that matches is : ' + cat.idcategorie + '     ID is ' + id);
      }
    }

  }
  public selectSubcat(id) {
    // //console.log('Type logement selected is : ' + id);
    this.idtypelogement = parseInt(id);
    // //console.log('--------------------------------------------------------------');
  }
  public selectVille(idville) {
    // //console.log('Ville selected is : ' + idville);
    this.idville = parseInt(idville);
    this.loading = true;
    this.error = "";
    this.catRep
      .getQuartierbyVille(parseInt(idville))
      .subscribe(
        (response) => {                           //next() callback
          this.Quartiers = response;
        },
        (error) => {                              //error() callback
          //console.error('Request failed with error'+error)
          this.error = error;
          this.loading = false;
        },
        () => {                                   //complete() callback
          //console.error('Request completed')      //This is actually not needed 
          this.loading = false;
        })
  }
  public selectQuartier(id) {
    this.idquartier = id = parseInt(id);
    // //console.log('--------------------------------------------------------------');
  }
}
