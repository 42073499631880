





import { ChangeDetectionStrategy,HostListener, ElementRef, ChangeDetectorRef,HostBinding, Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AuthenticationService} from '../../services/auths/authentication.service';
import {CategoriesService} from '../../services/categories.service'
import {Typelogement} from '../../models/typelogement'
import { first } from 'rxjs/operators';
import { DatePipe, Time } from '@angular/common';
import {Vehicule} from '../../models/vehicule';
import {Client} from '../../models/client';
import {User} from '../../models/user';
import {ClientService} from '../../services/client.service';
import {DesirsService} from '../../services/desirs.service';
import { DOCUMENT } from '@angular/common';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'details-demenagement',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './details-demenagement.component.html',
  styleUrls: ['../demenagement/demenagement.component.css']
})
export class DetailsDemenagementComponent implements OnInit {
  public Vehicule:Vehicule=new Vehicule();
  public Vehicules:Vehicule[];
  linearMode = true;
  redirectDelay: number = 5000;
  redirect:string = '/'
  showMessages: any = {};
  strategy: string = '';
  loading = false;
  loaderUneVoiture  =  false;
  loaderVoiture  =  false;
  myalt:string = '';
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  Typelogements:Typelogement[]=[];
  TypelogementsD:Typelogement[]=[];
  TypelogementsA:Typelogement[]=[];
  Villes:any[]=[];
  Positionimmeubles:any[]=[];
  Quartiers:any[]=[];
  Quartiers2:any[]=[];
  Categories:any[]=[];
  CategoriesD:any[]=[];
  CategoriesA:any[]=[];
  error ="";
  user: User;
  client:Client;
  firstForm: FormGroup;
  secondForm: FormGroup;
  etatconnexion=false;
  typelogementdepart;
  typelogementarrivee;
  positionimmeubledepart;
  positionimmeublearrivee;
  catdepart;catarrivee;
  optionautres ="";
  optionmontage=false;
  optionnetoyageldepart=false;
  optionnetoyagelarrivee=false;
  datedemenagement:Date;
      heuredemenagement:Time;
  villedepart;
  villearrivee;
  quartierdepart;
  quartierarrivee;
  datnaiss;
  validateur:boolean=false
  idclient=0; public idvehicule ='';
  message = 'Problème de connexion, Impossible de poursuivre votre requête';
  public loader = false;


  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')

  positions = NbGlobalPhysicalPosition;
  constructor(protected service: AuthenticationService,    
              protected catRep: CategoriesService,private voitRep:DesirsService,
              protected cd: ChangeDetectorRef,    protected router: Router,
              private fb: FormBuilder,    private datePipe: DatePipe, 
              @Inject(DOCUMENT) private _document: Document,
              private _Activatedroute:ActivatedRoute,
              private clientRep: ClientService, private DesirsRep: DesirsService, 
              private toastrService: NbToastrService ) { 
                /*
              if(this.firstForm.valid){
                  this.validateur=true;
                }*/
       }
             

public  toggleLoadingAnimation() {
        this.loader = true;
        setTimeout(() => {this.loader = false; console.info("Chargement en cours")}, 3000)
  }

  ngOnInit(): void {
  this.toggleLoadingAnimation()
  //recupération du paramètre    ////console.log(this._Activatedroute.snapshot.url[0].path)  this._document.defaultView.location.reload();
     
      this._Activatedroute.paramMap.subscribe(params => { 
          this.idvehicule = (params.get('id')); 
         });
      this.getVoitures();
      this.getcat();
      this.getVehiculeById(Number(this.idvehicule));
      this.getvilles();
      this.getPositionimmeubles();
      this.gettypelogementToArray();
      
      this.scroller(10, 10);


      let user = JSON.parse(localStorage.getItem("user"));
      if(user && user.token){
        this.idclient = user.client.idclient;
      }else{
        window.alert("Bien vouloir vous connectez ou de créer un compte si vous n'en disposez pas un. Merci");
        this.router.navigate(['/login']);
      }
              
      
      this.firstForm = this.fb.group({
            typelogementdepart: ['', Validators.required],
            typelogementarrivee: ['', Validators.required],
            catdepart: ['', ''],
            catarrivee: ['', ''],
            positionimmeubledepart: ['', Validators.required],
            positionimmeublearrivee: ['', Validators.required],

            optionmontage: ['', Validators.nullValidator],
            optionnetoyageldepart: ['', Validators.nullValidator],
            optionnetoyagelarrivee: ['', Validators.nullValidator],
            optionautres: ['', Validators.nullValidator],
            
            villedepart: ['', Validators.required],
            villearrivee: ['', Validators.required],
            quartierdepart: ['', Validators.required],
            quartierarrivee: ['', Validators.required],

            
            datedemenagement: ['', Validators.required],
            heuredemenagement: ['', Validators.required],
      
      });
}
  public getVoitures() {
    ///Get all Vehicule
    //this.loaderVoiture  =  true;
    this.DesirsRep
          .getVehicules()
            .subscribe(
              (response) => {   
                let result= response; 
                this.Vehicules = result.filter(v => v.idvehicule !== Number(this.idvehicule));
              },
              (error) => {   
                //console.info('Véhicules de demenagements autres  Request failed with error');
                //this.error = error;
                //this.loaderVoiture  =  false;
              },
            () => {                                   //complete() callback
            //console.info('Véhicules de demenagements Request completed');      //This is actually not needed 
            //this.loaderVoiture  =  false
            //console.log(this.Vehicules);
    })
  }
  showToast(position, msg, status) {
  
    this.toastrService.show(status || 'Success', msg, { position, status });
  }
  public getVehiculeById(id:number){
    this.loaderUneVoiture  =  true;
     this.error = "";
     this.voitRep
           .getVehiculeById(id)
             .subscribe(
               (response) => {                           //next() callback
                 // this.Vehicule = response; 
                 this.scroller(100, 100);
                  this.Vehicule  = response; 
                  this.scroller(0, 0);
                  
             },
               (error) => {                              //error() callback
                // console.error('Request failed with error')
                 this.error = error;
                 //this.loaderUneVoiture  =  false;
               },
               () => {                                   //complete() callback
                 //console.error('Request completed')      //This is actually not needed 
                  // this.loaderUneVoiture  =  false; 
               })
   }
   public selectCatDepart(id) {
    // //console.log('Category selected is : ' + id);
    this.catdepart = parseInt(id);

    for (let cat of this.CategoriesD) {
      ////console.log('Category that matches is : ' +  cat.idcategorie+'ID is '+ id);
      if (cat.idcategorie == id) {
        this.TypelogementsD = cat.typeslogement;
        //console.log('Category that matches is : ' + cat.idcategorie + '     ID is ' + id);
      } 
    }
  }
  public selectCatArrivee(id) {
    // //console.log('Category selected is : ' + id);
    this.catarrivee = parseInt(id);
    //console.log('Category selected is : ' + id);

    for (let cat of this.CategoriesA) {
      ////console.log('Category that matches is : ' +  cat.idcategorie+'ID is '+ id);
      if (cat.idcategorie == id) {
        this.TypelogementsA = cat.typeslogement;
        //console.log('Category that matches is : ' + cat.idcategorie + '     ID is ' + id);
      }
    }
   // window.alert('Category selected is : ' + this.TypelogementsA.length);
  }
  public selectVille(idville){
    this.Quartiers =[];
     this.error = "";
     this.catRep
           .getQuartierbyVille(Number(idville))
             .subscribe(
               (response) => {                           //next() callback
                 this.Quartiers = response; 
             },
               (error) => {                              //error() callback
                 //console.error('Request failed with error')
                 this.error = error;
                 //////this.loader = = false;
               },
               () => {                                   //complete() callback
                // console.error('Request completed')      //This is actually not needed 
                 //////this.loader = =  false; 
               })
   }
   public selectVille2(idville){
    this.Quartiers2 =[];
     this.error = "";
     this.catRep
           .getQuartierbyVille(Number(idville))
             .subscribe(
               (response) => {                           //next() callback
                 this.Quartiers2 = response; 
             },
               (error) => {                              //error() callback
                 //console.error('Request failed with error')
                 this.error = error;
                 //////this.loader = = false;
               },
               () => {                                   //complete() callback
                // console.error('Request completed')      //This is actually not needed 
                 //////this.loader = =  false; 
               })
   }
   public getcat() {
    //////this.loader = = true;
    this.error = "";
    this.catRep
          .getcategories()
            .subscribe(
              (response) => {                           //next() callback
                this.Categories = response;
                this.CategoriesD = response;
                this.CategoriesA = response;
              },
              (error) => {                              //error() callback
               // console.error('Request failed with error')
                this.error = error;
                //////this.loader = =  false;
              },
              () => {                                   //complete() callback
                //console.error('Request completed')      //This is actually not needed 
                //////this.loader = =  false;
              })
        }
    public getvilles() {
      ////this.loader = =  true;
          this.error = "";
          this.catRep
                .getVilles()
                  .subscribe(
                    (response) => {                           //next() callback
                      this.Villes = response;    
                    },
                    (error) => {                              //error() callback
                      //console.error('Request failed with error')
                      this.error = error;
                    },
                    () => {                                   //complete() callback
                      //console.error('Request completed')  
                    })
     }
     public getPositionimmeubles() {
      ////this.loader = =  true;
          this.error = "";
          this.voitRep
                .getPositionimmeubles()
                  .subscribe(
                    (response) => {                           //next() callback
                      this.Positionimmeubles = response;    
                    },
                    (error) => {                              //error() callback
                      //console.error('Request failed with error')
                      this.error = error;
                    },
                    () => {                                   //complete() callback
                      //console.error('Request completed')  
                    })
     }
  onFirstSubmit1() {
    this.firstForm.markAsDirty();
    //this.datnaiss = this.datePipe.transform(this.f.datenaisuser.value, 'dd-MMM-yyyy'); //whatever format you need.
    //window.alert(this.datnaiss);
    ////console.log(this.f.nomuser.value+'--'+this.f.prenomuser.value+'--'+this.datnaiss+'--'+this.f.sexe.value+'--')
  }

  onSecondSubmit1() {
    this.secondForm.markAsDirty();
    //console.log(this.f1.login.value+'--'+this.f1.password1.value+'--'+this.f1.password2.value+'--'+this.f1.typeuser.value+'--')
    }


  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }
  get f() { return this.firstForm.controls; }
  get f1() { return this.secondForm.controls; }

  onSubmit(value){
      console.log(value)
  }
  public onFirstSubmit(): void {
    ////console.log(this.f..value+'--'+this.f1.password1.value+'--'+this.f1.password2.value+'--'+this.f1.typeuser.value+'--')
    this.errors = this.messages = [];
    this.submitted = true;
    let datDem = this.datePipe.transform(this.f.datedemenagement.value+' '+this.f.heuredemenagement.value+':00', 'dd-MMM-yyyy hh:mm:ss');
    //console.log("*******"); console.log(datDem); console.log("*******");
   let demenagementData = Object.assign( {typelogementdepart:this.f.typelogementdepart.value } ,
                              {typelogementarrivee:this.f.typelogementarrivee.value},
                              {positionimmeubledepart:this.f.positionimmeubledepart.value},
                              {positionimmeublearrivee:this.f.positionimmeublearrivee.value},
                              {optionmontage:this.f.optionmontage.value},
                              {optionnetoyageldepart:this.f.optionnetoyageldepart.value},
                              {optionnetoyagelarrivee:this.f.optionnetoyagelarrivee.value},
                              {optionautres:this.f.optionautres.value},
                              {villedepart:this.f.villedepart.value},
                              {villearrivee:this.f.villearrivee.value},
                              {quartierdepart:this.f.quartierdepart.value},
                              {quartierarrivee:this.f.quartierarrivee.value},
                              {datedemenagement:datDem},
                              {idclient:this.idclient},
                              {idvehicule:this.idvehicule}         
    );

    ////this.loader = =   true;
        this.voitRep.demenager(demenagementData)
            .subscribe(
                (response) => {        
                    let Rep= response;
                    //console.log(Rep); 
                    this.showToast(this.positions.TOP_RIGHT, "Reservation Démenagement effectuée avec succès", 'success');
                 
                  },
                error => {
                    console.error(error);
                    ////this.loader = =  false; 
                    this.showToast(this.positions.TOP_RIGHT, "Erreur, verifiez votre connexion internet", 'danger');
                },
                () => {                                   //complete() callback
                  //console.error('Request completed')      //This is actually not needed 
                  ////this.loader = =  false;
                  
                })
  }
  public gettypelogementToArray() {
    ////this.loader = =  true; 
    this.error = "";
    this.catRep.getTypeLogements()
            .subscribe(
              (response) => {                           //next() callback
                this.Typelogements = response; 
                //console.table(this.Typelogements);
              },
              (error) => {                              //error() callback
                //console.error('Request failed with error')
                this.error = error;
                ////this.loader = =  false; 
              },
              () => {                                   //complete() callback
                //console.error('Request completed')      //This is actually not needed 
                ////this.loader = =  false;
              })
  }

//////////////////////////////////////////////////////////////////////////////


// TODO: Cross browsing
scroller(t, l) {
    window.scroll({ 
      top: t, 
      left: l, 
      behavior: 'smooth' 
    });
}




/////////////////////////////////////////////////////////////////

}

