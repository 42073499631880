import { Component,ChangeDetectionStrategy,ChangeDetectorRef, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';//this.router.navigate(['/search']);
import {CategoriesService} from '../../services/categories.service';
import { Categorie } from '../../models/categorie';
import { Typelogement } from '../../models/typelogement';
import { Ville } from '../../models/ville';
import { Quartier } from '../../models/quartier';
import { Bienimmobilier } from '../../models/bienimmobilier';
import { Search } from '../../models/search';
import {DesirsService} from '../../services/desirs.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {} from '../../services/auths/authentication.service';
import { AlertService } from '../../services/auths/alert.service';
import {User} from '../../models/user';
import {Client} from '../../models/client';
import { first } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'soumettre-desirs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './soumettre-desirs.component.html',
  styleUrls: ['./soumettre-desirs.component.css']
})
export class SoumettreDesirsComponent implements OnInit {
  public Categories:Categorie[];
  public Typelogements:Typelogement[];
  public Villes : Ville[];
  public Quartiers : Quartier[];
  public ResultDesir:any;
  public idcategorie:number=1;
  public idtypelogement:number=1;
  public idville:number=1;
  public idquartier:any="1";
  public searchData:Search;
  public typecontrat='Tout';
  public compteclient:number=0;
  public client : Client;
  error: string = '';
  
  positions = NbGlobalPhysicalPosition;
  linearMode = true;
  redirectDelay: number = 5000;
  redirect:string = '/'
  showMessages: any = {};
  strategy: string = '';
  loading = false;
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: User;
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  etatconnexion=false;
  datnaiss; interval:any;
  message = 'Problème de connexion, Impossible de soumettre votre doléance';
  
    value: number = 100;
    minValueprix: number = 5;
    maxValueprix: number = 23000;
    optionsprix: Options = {
      floor: 5,
      ceil: 200000000
    };
    minValuesup: number = 10;
    maxValuesup: number = 1000;
    optionssup: Options = {
      floor: 50,
      ceil: 100500
    };
    mindist: number = 1;
    maxdist: number = 500;
    optionsdist: Options = {
      floor: 10,
      ceil: 2000
    };
  
  
    alouer = 'search-text'; 
    avendre= 'home-text';
    contrat:boolean = false;
   change(){
  
   }
   active1(data: string){
    this.alouer = 'search-text'; 
    this.contrat = false;
    this.avendre= 'home-text';
    this.typecontrat="A louer";
   }
   active2(data: string){
    this.alouer = 'home-text'; 
    this.avendre= 'search-text';
    this.contrat = true;
    this.typecontrat="A vendre";
   }
  constructor(private router: Router, 
              protected cd: ChangeDetectorRef,
              private fb: FormBuilder,
              private desirService: DesirsService,
              private toastrService : NbToastrService,
              private clientRep: ClientService,
               private catRep:CategoriesService) { }

  ngOnInit(): void {
    this.getcat(); 
    this.getvilles();
    let lien = window.location.href;
    localStorage.setItem("currentUrl", lien);
    let user = JSON.parse(localStorage.getItem("user"));
   if(user && user.token){
      this.compteclient = user?.client?.idclient;
      this.user=user;
    }else{
     this.compteclient=0;
    }
   /*this.firstForm = this.fb.group({
      nomclient: ['', Validators.required],
     prenomclient: ['', Validators.required],
      adressclient: ['', Validators.required],
      phonenumber: ['', Validators.required],
      sexe: ['', Validators.required],
    });*/

    this.secondForm = this.fb.group({
      idcategorie: ['', Validators.required],
      idtypelogement: ['', Validators.required],
      idville: ['', Validators.required],
      idquartier: [[null], Validators.required],
      typecontrat: ['', Validators.required],
    });
  }
  //get f() { return this.firstForm.controls; }
  get f1() { return this.secondForm.controls; }

public creerClient() {
  let client = Object.assign( {nomclient:this.user?.nomuser } , 
                              {prenomclient:this.user?.prenomuser},
                              {adressclient:this.user?.adressuser},
                              {phonenumber:"+237"+this.user?.phonenumber},
                              {sexe:this.user?.sexe},
                              {compteclient: this.user?.id}
    );
    this.clientRep
          .creerClient(client)
            .subscribe(
              (response) => {                           //next() callback
                this.client = response; 
            },
              (error) => {        
                window.alert('Aucune client crée, BV recommencer');                      //error() callback
                //console.error('Request Client creation failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.error('Request Client creation completed');      //This is actually not needed 
                //console.log(this.client);
                this.loading = false; 
              })
}
 public  onSubmitDesir(datassss): void {
    this.errors = this.messages = []; //adresseuser phonenumber  sexe
    this.submitted = true;
    //window.alert(this.arraytostring(this.f1.idquartier.value));
      let desir = Object.assign( {idclient:this.compteclient},
                                  {idcategorie:parseInt(this.f1?.idcategorie?.value, 10)},{idtypelogement:parseInt(this.f1?.idtypelogement?.value, 10)},
                                  {idville:this.f1?.idville.value},{idquartier:this.arraytostring(this.f1?.idquartier.value)},
                                  {prixmin:this.minValueprix},{prixmax:this.maxValueprix},
                                  {distanceroute:this.mindist},{typecontrat:this.typecontrat}
                                   /*,{distmax:this.maxdist},
                                  {surfacemin:this.minValuesup},{surfacemax:this.maxValuesup},*/
                                  
        );
        this.desirService
        .soumettre(desir)
          .subscribe(
            (response) => {                      
              this.ResultDesir = response; 
            },
              
            (error) => {                              
              this.showToast(this.positions.TOP_RIGHT, "Erreur, Merci de réessayer ulterieurement", 'danger');
              error = error;
            },
            () => {         
             // console.error('desir Request completed') ;     //This is actually not needed 
              //window.alert("Votre Désire a été effetué avec success");
              //this.patienter(10000);
              this.showToast(this.positions.TOP_RIGHT, "Votre désir a été pris en compte. Nous vous contenterons de sous peu/ Merci", 'success');
              //this.router.navigateByUrl('/');
              
            })
}
showToast(position, msg, status) {
  
  this.toastrService.show(status || 'Success', msg, { position, status });
}
patienter(t:number){
  this.interval = setInterval(() => {
    console.log('attendre'+ t + 'seconde');
  }, t);
}
public arraytostring(items){
  let chaine:string='';
  let cpt=0;
  for(let item in items){
      if(items[cpt+1]){
        chaine= chaine+items[cpt]+',';
      }else{
        chaine= chaine+items[cpt]
      }
      cpt++;
  }
  return chaine;
}
// this. register();
  public getcat() {
    this.loading = true;
    this.error = "";
    this.catRep
          .getcategories()
            .subscribe(
              (response) => {                           //next() callback
                this.Categories = response; 
              },
              (error) => {                              //error() callback
                //console.error('Request failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                //console.error('Request completed');      //This is actually not needed 
                this.loading = false; 
              })
        }
    public getvilles() {
          this.loading = true;
          this.error = "";
          this.catRep
                .getVilles()
                  .subscribe(
                    (response) => {                           //next() callback
                      this.Villes = response;    
                    },
                    (error) => {                              //error() callback
                      // console.error('Request failed with error');
                      this.error = error;
                      this.loading = false;
                    },
                    () => {                                   //complete() callback
                      //console.error('Request completed');      //This is actually not needed 
                      this.loading = false; 
                    })
     }
  public selectType(id){
    // //console.log('Category selected is : ' + id);
     this.idcategorie =parseInt(id);
     //console.log('Category selected is : ' +  id);
   // this.cd.detectChanges();
     for(let cat of this.Categories){
       //console.log('Category that matches is : ' +  cat.idcategorie+'ID is '+ id);
       if(cat.idcategorie==id){
         this.Typelogements=cat.typeslogement;
         //console.log('Category that matches is : ' +  cat.idcategorie+'     ID is '+ id);
       }
     }
   }
   public selectSubcat(id){
    // //console.log('Type logement selected is : ' + id);
     this.idtypelogement = parseInt(id);
     //window.alert( this.idtypelogement);
    //console.log('--------------------------------------------------------------');
   }
  public selectVille(idville){
    //console.log('Ville selected is : ' + idville);
     this.idville = parseInt(idville);
     this.loading = true;
     this.error = "";
     this.catRep
           .getQuartierbyVille(Number(idville))
             .subscribe(
                (response) => {
                      this.Quartiers = response; 
             },
               (error) => {                            
                 this.error = error;
                 this.loading = false;
               },
               () => {                                   
                 this.loading = false; 
               })
   }
   public selectQuartier(id){
     this.idquartier = id = parseInt(id);
   }
   public selectTypeContrat(contrat:string){
      this.typecontrat = contrat;
   }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }

}

