<div class="single-top-properties responsive">
  <div class="row">
    <div class="col-lg-6">
      <div class="stp-pic survoler" *ngFor="let image2d of bien?.image2ds | slice:0:1">
        <a [routerLink]="['/property', bien?.idbienimmo]" >  
          <img [src]="image2d?.srcimg" alt="" style="max-width: 100%; height: 300px; width: 100%; border-radius: 5px;">
        </a>  
        </div>
    </div>
    <div class="col-lg-6">
      <div class="stp-text">
          <div class="pic-tag">
             <a [routerLink]="['/property', bien?.idbienimmo]">
                  <div class="s-text" style="float: right;"  ><i class="fa fa-eye"></i> Détails</div>
              </a> <!--[routerLink]="['/property', bien?.idbienimmo]"-->
              <div class="s-text"><b>{{bien?.avendre ? 'à vendre':'à louer'}}</b></div>
          </div>
          <h4>{{bien?.designation}} - {{bien?.estmoderne? 'Moderne': 'Simple'}}</h4>
          <h4>{{bien?.category?.nomcategorie}} - {{bien?.typelogement?.nomtypelogement}}</h4>
        <div class="room-price">
          <span>Coût <!--{{bien.unitecout?.libeleadjectif}}--> :</span>
          <h5 color="red">{{bien?.coutloyermensuel | currency:'XAF'}}</h5>
        </div>
        <div class="properties-location"><i class="icon_pin"></i>{{bien?.ville?.nomville}} -{{bien?.quartier?.nomquartier}}</div>
        <div class="properties-location"><i class="fa fa-tag"></i>{{bien?.courtedescription}} </div>
        <!--
       <div class="container" [class.show]="show">
          {{bien?.longuedescription}}
       </div>
          <a style="float: right; color: blue;;"  (click)="show = !show">
              <button nbButton ghost status="primary" style="font-style:oblique ; border-radius: 10px;" >
                 <u> <nb-icon icon="eye-outline"></nb-icon> 
                  {{ show ? "Reduire": "Lire plus"}} </u>
              </button>
          </a>
        -->
        <ul class="room-features">
          <li>
            <i class="fa fa-arrows"></i>
            <h5> {{bien?.espacelocatif}}m<sup>2</sup></h5>
          </li>
          <li>
            <i class="fa fa-bed"></i>
            <p>{{bien?.nbrchambreacoucher}} Chambres</p>
          </li>
          <li>
            <i class="fa fa-bath"></i>
            <p>{{bien?.nbrsallebain}} Douches</p>
          </li>
          <li>
            <i class="fa fa-car"></i>
            <p>{{(bien?.parking || bien?.garage)?'1':'0'}} Parking</p>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</div>
<hr>
<br />
