<nb-layout>
    <nb-layout-column center class="">
                    
            <nb-card status="success">
                <nb-card-header center style="background-color: #2cbdb8;">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 style="color: white;"> Services de démenagement</h4>
                            Sélectionnez le type de véhicule qui vous convient
                        </div>
                        <div class="col-md-4" style="float: right;" infinite-scroll>
                                <form>
                                    <div class="form-group">
                                            <select  (change)="selectOption($event.target.value)" id="typevehicule" class="form-control"   required>
                                                        <option value="0">Type/Model de véhicule</option> 
                                                        <option *ngFor="let model of Modelevehicules" value="{{model?.idmodele}}">{{model?.marquemodele?.nommarque}} - {{model?.nommodele}}, {{model?.anneemodele}}</option>
                                            </select>
                                            
                                    </div>
                                </form>        
                        </div>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <div class="site-section site-section-sm bg-light">
                        <div class="container">
                            <div class="row">  
                                    <div class="col-md-4" *ngFor="let veh of Vehicules" style="margin-top: 15px;">
                                       <vehicule [voiture]="veh"></vehicule>  
                                    </div>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </nb-layout-column>
    </nb-layout>

