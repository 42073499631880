
import {Router, ActivatedRoute} from '@angular/router';
import {AppglobalService} from '../../services/appglobal.service';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import { Component, OnInit, Input,TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import {BiensService} from '../../services/biens.service';
import {ClientService} from '../../services/client.service';
import { Bienimmobilier } from 'src/app/models/bienimmobilier';
import { Dossier } from 'src/app/models/dossier';
import { FormDossierComponent } from '../../layouts/form-dossier/form-dossier.component';
import {NbWindowRef, NbWindowService, NbDialogService } from '@nebular/theme'; 
import { NbToastrService,NbGlobalPhysicalPosition ,NbComponentStatus } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, Time } from '@angular/common';//private datePipe:DatePipe,
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { NbIconLibraries } from '@nebular/theme';  
import { Gallery, GalleryRef,GalleryConfig ,GalleryItem,ImageItem} from 'ng-gallery'; 

@Component({
  selector: 'app-single-poperty',
  templateUrl: './single-poperty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./single-poperty.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateX(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(0)', opacity: 1 }),
          animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'enterAnimationVetically', [
        transition(':enter', [
          style({ transform: 'translateY(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
        ])]
    )
  ],
})
export class SinglePopertyComponent implements OnInit {
  @Input() Infos =["Détailes", "/property", "assets/img/banner-img4.png"];
  public idprop='1';
  public TopProprietes = [];
  public descriptifs ="Description de votre bien en coursq de chargement";
  public nbrchambreacoucher =0;nbrsallebain=0;nbrpiece=0;nbrsalon=0;parking=true;
  public unitecout:any;
  coutloyermensuel=0;avendre=true;ville="Bayangam";
  urlSafe: SafeResourceUrl;
  public RelatedBiens:Bienimmobilier[];
  public Bien:Bienimmobilier;
  public Dossier:Dossier;
  public visite360:boolean;
  public disabled:boolean;
  public idclient=0;
  public idbienimmo = 0; datedescente; heuredescente;
  public descriptiondossier:string="RAS";
  public relatedData:Object={};
  public mystatus:NbComponentStatus ;
  positions = NbGlobalPhysicalPosition;
  public nbcomo = 0;
  infodossier;
  desciption:string;//descriptifs
  myText = "Appartement meublé haut standing Yaoundé - Carrefour entrée Simbock 2 chambres 2 douches 1 cuisine Salle à manger 1 balcon Parking Gardien Propreté de rigueur Calme Confort Sécurité Convivialité Wifi Internet Service de nettoyage Concierge Buanderie"; 
           
  @ViewChild('contentTemplate1') contentTemplate1: TemplateRef<any>;



  firstForm: FormGroup;

  constructor(  private _Activatedroute:ActivatedRoute,
                private gData:AppglobalService, 
                public sanitizer: DomSanitizer, 
                private bienRep: BiensService, 
                private gallery: Gallery,
                private iconLibraries: NbIconLibraries, 
                private windowService: NbWindowService,
                private dialogService: NbDialogService,
                private fb: FormBuilder, 
                private datePipe:DatePipe,
                private clientRep: ClientService,
               // protected windowRef: NbWindowRef,
                private router:Router,
                private toastrService: NbToastrService) { 
    this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa' });

  }
  images: GalleryItem[];
  windowRef: any;
  galleryId = 'propertyGallery';
  gallerytourId = 'propertytourGallery';
 
  ngOnInit(): void {
    this.firstForm = this.fb.group({
      datedescente: ['', Validators.required],
      heuredescente : ['',Validators.required],
      descriptiondossier:['',Validators.nullValidator],
      datedebutreservation: ['', Validators.nullValidator],
      datefinreservation: ['', Validators.nullValidator],
      telclient: ['', Validators.nullValidator]
     /* phonenumber: ['', Validators.required],
      sexe: ['', Validators.required],*/
    });

    /*
    this.secondForm = this.fb.group({
      idcategorie: ['', Validators.required],
      idtypelogement: ['', Validators.required],
      idville: ['', Validators.required],
      idquartier: [[null], Validators.required],
    });*/

    this._Activatedroute.paramMap.subscribe(params => { 
      this.idprop = (params.get('id')); 
     });
    
    this.getOneBienImmobiliers(Number(this.idprop));
 
 /* 
   galleryRef.addImage({
    src: 'assets/img/properties/property-details-b1.jpg',  
    title: 'Façade', 
    thumb: 'assets/img/properties/thumb-1.jpg'
  });
  galleryRef.addImage({
    src: 'assets/img/properties/property-details-b2.jpg', 
    title: 'Chambre', 
    thumb: 'assets/img/properties/thumb-2.jpg'
    
  });
  galleryRef.addImage({
    src: 'assets/img/properties/property-details-b3.jpg', 
    title: 'Salon', 
    thumb: 'assets/img/properties/thumb-3.jpg'
    
  });
  galleryRef.addImage({
    src: 'assets/img/properties/property-details-b4.jpg',
    thumb: 'assets/img/properties/thumb-4.jpg', 
    title: 'Cuisine' 
    
  });
  

  galleryRef.addImage({
    src: 'assets/img/properties/property-details-b5.jpg', 
    thumb: 'assets/img/properties/thumb-5.jpg', 
    title: 'Douche' 
    
  });
 galleryRef.addVideo({
    src: 'VIDEO_URL',
    thumb: '(OPTIONAL)VIDEO_THUMBNAIL_URL',
    poster: '(OPTIONAL)VIDEO_POSTER_URL'
  });
*/
  // Add a video item with multiple url sources
  /*galleryRef.addVideo({
    src: [
      { url: 'MP4_URL', type: 'video/mp4' },
      { url: 'OGG_URL', type: 'video/ogg' }
    ],
    thumb: '(OPTIONAL)VIDEO_THUMBNAIL_URL',
    poster: '(OPTIONAL)VIDEO_POSTER_URL'
  });*/

 /* galleryRef.addYoutube({
    src: 'VIDEO_ID'
  });*/


  }
  openWithScroll() {
    this.open(true);
  }
  protected open(hasScroll: boolean) {
    this.dialogService.open(this.contentTemplate1, { hasScroll });
  }
  get f() { return this.firstForm.controls; }

  openWindow() { //(click)="ref.close()"
    let lien = this.router.url;
    localStorage.setItem("currentUrl", lien);
    //console.log(lien)
    let user = JSON.parse(localStorage.getItem("user"));
    if(user && user.token){

      if(user && user.client){
        this.idclient = user.client.idclient;
        /////////////////////////////////////
        this.infodossier={};
        this.infodossier["designation"] = this.Bien?.designation;
        this.infodossier["estmoderne"] = this.Bien?.estmoderne;
        this.infodossier["email"] = user.user.email;
        this.infodossier["telclient"] = user.user.phonenumber;
        this.infodossier["coutloyermensuel"]= this.Bien.coutloyermensuel;
        this.infodossier["quartier"]=this.Bien?.quartier?.nomquartier
        this.infodossier["ville"]=this.Bien.ville?.nomville
        if(this.Bien?.estmeuble){
          this.infodossier["estmeuble"]=this.Bien?.estmeuble
        } /* 
        console.log("this.infodossier");
        console.log(this.infodossier);
         //////////////////////////////
        this.infodossier = Object.assign({designation:this.Bien?.designation },
                                      {estmoderne:this.Bien?.estmoderne},
                                      {estmeuble:this.Bien?.estmeuble},
                                      {ville:this.Bien.ville?.nomville},
                                      {quartier: this.Bien?.quartier?.nomquartier},
                                      {coutloyermensuel: this.Bien.coutloyermensuel},
                                      {telclient:user.user.phonenumber},
                                      {email: user.user.email});
        //console.log(this.infodossier);*/
        this.windowRef= this.windowService.open( this.contentTemplate1,
          { title: 'Prendre un rendez-vous pour visiter', context: this.infodossier}
        );
      }else{
        window.alert("Bien vouloir contacter l'administrateur, car vous n\'êtes pas enregistré en tant que cleint. Merci !!!");
      }  
    
      //.onClose.subscribe(descriptiondossier => descriptiondossier && window.alert("Détails autres : "+this.descriptiondossier));;

 }else{
      window.alert("Bien vouloir vous connectez ou de créer un compte si vous n'en disposez pas un. Merci");
      this.router.navigate(['/login']);
    }
  /*
    this.windowService.open( this.contentTemplate,
      { title: 'Formulaire de prise de rendez-vous', context: { text: 'some text to pass into template' } },
    );*/
  }
  closeWindow(){
    //this.openWindow(true)
  }
  rdvSubmit(data){
      //window.alert("Bien N° "+this.idprop+ "Client N° "+this.idclient);
      ////console.log(this.f.datedescente.value+'--')
     this.datedescente = this.datePipe.transform(this.f.datedescente.value, 'dd-MMM-yyyy');
     //this.heuredescente = this.datePipe.transform(this.f.heuredescente.value, 'hh:mm:ss');
     let datetime =this.datedescente+' '+this.f.heuredescente.value+':00';
     datetime = this.datePipe.transform(datetime, 'dd-MMM-yyyy hh:mm:ss');
     //window.alert("Desciption "+this.f.descriptiondossier.value+"  Date descente"+datetime );
     let dossier:Object={}; //let dd = this.f.datedebutreservation.value; let df = this.f.datefinreservation.value
       dossier["idclient"]=this.idclient;
       dossier["idbienimmo"]=this.idprop;
       dossier["descriptiondossier"]="Tel ==> "+this.f.telclient.value+", Description ==> "+this.f.descriptiondossier.value;
       dossier["datedescente"]=datetime;
       if(this.Bien?.estmeuble){ 
        dossier["datedebutreservation"]=this.datePipe.transform(this.f.datedebutreservation.value, 'dd-MMM-yyyy hh:mm:ss'); 
        dossier["datefinreservation"]=this.datePipe.transform(this.f.datefinreservation.value, 'dd-MMM-yyyy hh:mm:ss'); 
       }
       
     let loading = true;
    let error = "";
    this.clientRep
          .ouvrirDossier(dossier)
            .subscribe(
              (response) => {     
                this.Dossier = response; 
                if(response){
                   console.log("Dossier client");
                   console.log(response);
                }
              },
              (error) => { 
                this.showToast(this.positions.TOP_RIGHT, "Echec de prise de Rendez-vous!", 'danger');

                error = error;
                loading = false;
              },
              () => {  
                //console.info('Dossier Requests completed ');    
                this.showToast(this.positions.TOP_RIGHT, "Rendez-vous de visite pris avec succès!", 'success');
                loading = false; 
               // window.alert('toto');
                //console.log(this.Dossier);
              })
     
  }

 
public showToast(position, msg, status) {
    this.toastrService.show(status || 'Success', msg, { position, status });
}

  getRelatedBienImmobiliers() {
    let loading = true;
    let error = "";
    this.RelatedBiens=[];
    this.relatedData = Object.assign( {category:this.Bien.category?.idcategorie } ,
                                      {ville:this.Bien.ville?.idville},/*
                                      {typelogement:this.Bien.typelogement?.idtypelogement},
                                      {quartier:this.Bien.quartier?.idquartier},*/
                                      {avendre:this.Bien.avendre}
                                    );
    this.bienRep
          .getRelatedbiensimmobiliers(this.relatedData)
            .subscribe(
              (response) => {                           
                this.RelatedBiens = response; 
              },
              (error) => {     
                //console.error('Request of related failed with internet error error')
                error = error;
                loading = false;
              },
              () => {       
                //console.info('All Related real estate Requests completed '); 
                //console.log(this.RelatedBiens); 
                loading = false; 
              })
}
getOneBienImmobiliers(id:number) {
  let loading = true;
  let error = "";
  this.bienRep
        .getOnebiensimmobiliers(id)
          .subscribe(
            (response) => {                           //next() callback
              this.Bien = response; 
              this.buildslide();
              this.desciption = this.Bien.longuedescription;
              this.getRelatedBienImmobiliers();
           },
            (error) => {                              //error() callback
              //console.error(' Single real estate request failed with error')
              error = error;
              loading = false;
            },
            () => {                                   //complete() callback
             // console.info('Single real estate Request completed')      //This is actually not needed 
              loading = false; 
              //descriptifs
            })
}
public traiterBien(DesBiens:Bienimmobilier[]){
  //console.log("Nombre de biens recu  est : "+DesBiens.length);
  //console.log("Biens recu  sont : ");
  console.table(DesBiens);
 
  for(let B of DesBiens){
    if(B.typelogement===null){
      B.typelogement.nomtypelogement = "Habitation";
    }

    if(B.image2ds[0].srcimg){
        B.image2ds[0].srcimg = B.image2ds[0].srcimg;
      }else{  
        B = Object.assign(B, {image2ds:"assets/img/img_2.jpg"} );
      }
      if(B.category.nomcategorie){
        B.category.nomcategorie = B.category.nomcategorie;
      }else{
        B = Object.assign(B, {category: {nomcategorie: "Indisponible"}} );
      }
      if(!(B.quartier)){//hasOwnProperty(prop)
        B = Object.assign(B, {quartier:{nomquartier: "Indisponible"}} );       
      }else{
        B.quartier.nomquartier = B.quartier.nomquartier; 
      }
      if(B.ville.nomville){
        B.ville.nomville = B.ville.nomville;
      }else{
        B = Object.assign(B, {ville: {nomville: "Indisponible"}} );
      }
  }
  //console.log('*****************************************************-');
  //console.log("Biens traités  sont : ");
  console.table(DesBiens); 
}

buildslide(){
    
  const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
  const gallerytourRef: GalleryRef = this.gallery.ref(this.gallerytourId);
let tour360=(!this.Bien.image3d ?("https://api-easyhome.destiny-innovation.com/v1/web/app_dev.php/page/completetourimg/"+this.Bien.idbienimmo): this.Bien.image3d );
  gallerytourRef.addIframe({
    src:tour360
  });
  const config: GalleryConfig = {
    autoPlay: true
  };
  //Initialisation du contenu du bien immobilier
  this.nbrchambreacoucher = this.Bien.nbrchambreacoucher
  this.descriptifs =this.Bien.longuedescription;
  this.nbrsallebain = this.Bien.nbrsallebain
  this.nbrsalon = this.Bien.nbrsalon;
  this.parking = this.Bien.parking;
  this.nbrpiece=this.Bien.nbrpiece;
  this.coutloyermensuel = this.Bien.coutloyermensuel; //{{bien.unitecout?.libeleadjectif}}
  this.unitecout = this.Bien.unitecout?.libeleadjectif;
  this.avendre = this.Bien.avendre;
  this.ville = this.Bien.ville.nomville;
 
  if(this.Bien.image3d==null){
      this.visite360=true;
      //console.log('Vue 3D : '+this.Bien.image3d)
  }else{
    this.visite360=false;
  }
  galleryRef.setConfig(config);
  //newGalleryRef.setConfig(config);
  //console.log(this.Bien.image2ds);
  if(this.Bien.image2ds.length==0){ 
    galleryRef.addImage({
      src:"", 
      title: "Aucune image disponible!",
      thumb: ""
  });
  }
  for(let b of this.Bien.image2ds ){
    galleryRef.addImage({
        src: b.srcimg, 
        title: b.nomimg,
        thumb: b.srcimg,
    });
  }
}

recupererParam(){
    //get id of single property
    this._Activatedroute.paramMap.subscribe(params => { 
      this.idprop = (params.get('id')); 
     });
     /*window.alert(this.idprop);
     this.bien = this.gData.TopProprietes[+(this.idprop)-1];
     //console.log('-------------------------------------------------');
     //console.log(this.bien);
     //console.log('-------------------------------------------------');
     this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.bien.vue360);*/
  }

  close() {
    this.windowRef.close();
  }

  
}
