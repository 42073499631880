import { Component, OnInit,  Input,TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NbWindowService , NbDialogRef} from '@nebular/theme'; 

import {AppglobalService} from '../services/appglobal.service';// this.TopProprietes = this.gData.TopProprietes;
import {BiensService} from '../services/biens.service';
import {CategoriesService} from '../services/categories.service';
import { Bienimmobilier } from '../models/bienimmobilier';
import { Categorie } from '.././models/categorie';//Typelogement
import { Typelogement } from '.././models/typelogement';//Typelogement
import {Ville} from '.././models/ville';
import {Quartier} from '.././models/quartier';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    //slideAnimation
  ]
})
export class HomeComponent implements OnInit {
  @ViewChild('videotemplate') videotemplate: TemplateRef<any>;
 public videoToken='d6QWPAGYH44';
  Biens:Bienimmobilier[]; 
  BiensPromotion:Bienimmobilier[]; 
  BiensNouveaux:Bienimmobilier[]; 
  cpyBiens:Bienimmobilier[];
  TopProprietes = [];
  MeilleursAgents =[];
  Typelogements:Typelogement[];
  Villes:Ville[];
  Categories:Categorie[];
  Quartiers:Quartier[];

  error: string = '';
loading: boolean = false;
  public monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
  "Juilet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
]; annee:any;

   mois:string='';
  constructor(private bienRep:BiensService, 
              private gData:AppglobalService, 
              private catRep:CategoriesService,
              private windowService: NbWindowService) { 
        /*this.bienRep.array.forEach(function(data){
          //console.log(data[0], data[1].length);
        });
        this.getBienImmobiliers();*/

  }

  ngOnInit(): void {
 
    this.MeilleursAgents = this.gData.Agents;
    this.mois = this.monthNames[new Date().getMonth()]
    this.annee = new Date().getFullYear();
    this.getBienImmobiliers();
    this.getBienNouveaux();
    this.getBiensEnPromotion();
    this.getvillebiens();
 }
 title = 'YTIFrameAPI-with-Angular';

 /* 1. Some required variables which will be used by YT API*/
 public YT: any;
 public video: any;
 public player: any;
 public reframed: Boolean = false;

 openWindow() {
  
  this.windowService.open( this.videotemplate,
    { title: 'Lecture de la Vidéo', context: { text: 'some text to pass into template' } },
  );
}



  public getBienImmobiliers() {
    this.loading = true;
    this.error = "";
    this.bienRep
          .getbiensimmobiliers()
            .subscribe(
              (response) => {                           //next() callback
                this.Biens = response; 
              },
              (error) => {                              //error() callback
                //console.info('Request failed with error');
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                ////console.info('Request completed') ;     //This is actually not needed 
                this.loading = false; 
              })
}
public getBienNouveaux() {
  this.loading = true;
  this.error = "";
  this.bienRep
        .getbiensNouveaute()
          .subscribe(
            (response) => {                           //next() callback
              this.BiensNouveaux = response; 
            },
            (error) => {                              //error() callback
              //console.info('Request failed with error');
              this.error = error;
              this.loading = false;
            },
            () => {                                   //complete() callback
              ////console.info('Request completed');      //This is actually not needed 
              this.loading = false; 
            })
}
public getBiensEnPromotion() {
  this.loading = true;
  this.error = "";
   this.bienRep
        .getbiensEnpromotion()
          .subscribe(
            (response) => {                           //next() callback
              this.BiensPromotion = response; 
            },
            (error) => {                              //error() callback
              //console.info('Request failed with error')
              this.error = error;
              this.loading = false;
            },
            () => {                                   //complete() callback
              //////console.info('Request completed')      //This is actually not needed 
              this.loading = false; 
            }); 
          /*  this.http.post<any>(this.gData.ApiUrl+'filtrebienimmobiliers', { enprommotion:true }).subscribe(data => {
              this.BiensPromotion = data;
          })*/
        


}


  public itemsToString(value:Array<any> = []):string {
    return value
      .map((item:any) => {
        return item.text;
      }).join(',');
  }
  public getvillebiens() {
            this.catRep.getVilles()
                      .subscribe(
                        (response) => {                           //next() callback
                          this.Villes = response;  
                          this.Villes.sort((a, b) => (a?.idville > b?.idville ? 1 : -1));
                        },
                        (error) => {                              //error() callback
                          //console.info('Request Villes failed with error')
                          this.error = error;
                          this.loading = false;
                        },
                        () => {                                   //complete() callback
                          //console.warn('Request Villes top header completed')      //This is actually not needed 
                          this.loading = false; 
                        })
                      
                      }
  


}
