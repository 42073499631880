import { Component, OnInit, Input,TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AppglobalService} from '../../services/appglobal.service';
import {FaqService} from '../../services/faq.service';
import {HomeComponent} from '../../home/home.component';
import { NbIconConfig, NbSidebarService , NbTreeGridModule,  } from '@nebular/theme';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { Faq } from './../../models/faq';
import { CategorieFaq } from './../../models/categoriefaq';
import { NbWindowService , NbDialogRef} from '@nebular/theme'; 


@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild('videotemplate') videotemplate: TemplateRef<any>;
  @Input() Infos =["FAQ's", "/faq", "assets/img/banner-img4.png"];

  public AllFaq=[];
  public FAQS; 
  public Faq:Faq[]; public Faqbycat:Faq[];  
  public Faqcpy:Faq[]; public FaqPop:Faq[];
  public idcatfaq = 1;
public cats:CategorieFaq[];
public data:String[]=["data1","data2","data3"] ;
  public error='';
  public loading:boolean;
  public videoToken = 'lggPvd8Bj-w';
  constructor(private gData:AppglobalService,
              private faqRep: FaqService, 
              private _Activatedroute:ActivatedRoute,
              private router : Router,
              private windowService: NbWindowService) {
                //this.getCategorieFaq();
              }

  ngOnInit(): void {
      this.getCategorieFaq();
      this.getAllFaq();
  }
  openWindow() {
  
    this.windowService.open( this.videotemplate,
      { title: 'Lecture de la Vidéo', context: { text: 'some text to pass into template' } },
    );
  }
  toggle = false;
  status = 'Enable'; 


/**
 *   public filterFaq(id:number){
      this.Faqcpy = [];
      this.toggle = !this.toggle;
      this.status = this.toggle ? 'Enable' : 'Disable';
      for(let f of this.Faq){
        if(id==f.categoriefaq.idcategoriefaq){
          this.Faqcpy.push(f);
        }
      }
      console.table( this.Faqcpy);
  }
 */
  public selectFaqCat(id){
    window.alert("Cat Faq ID selected is "+id);
    this.idcatfaq=parseInt(id);
}
public getfaqBycat(id:number){
  this.Faqbycat = [];
  this.toggle = !this.toggle;
  this.faqRep
      .getFaqBycategorie(Number(id))
        .subscribe(
          (response) => {                           //next() callback
            this.Faqbycat = response; 
            document.body.scrollTop = 10; // For Safari
            document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
          },
          (error) => {                              //error() callback
            console.error('Request des FAQ By cat failed with error'+error)
            this.error = error;
            this.loading = false;
          },
          () => {                                   //complete() callback
            console.info('Request des FAQ By cat( '+id+' ) completed')      //This is actually not needed 
            console.table(this.Faq);
            //this.fullfilFac();
            this.loading = false; 
      });
  console.table( this.Faqbycat);
}
  public getAllFaq(){
    this.loading = true;
    this.Faq =[];
    this.error = "";
     this.faqRep
          .getFaqLocal()
            .subscribe(
              (response) => {                           //next() callback
                this.FAQS = response; 
                document.body.scrollTop = 20; // For Safari
                document.documentElement.scrollTop = -20; // For Chrome, Firefox, IE and Opera
                document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Oper
              },
              (error) => {                              //error() callback
                console.error('Request des FAQ failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
                console.info('Request des Faq completed')      //This is actually not needed 
                //this.filterFaq(1);
                //this.fullfilPopFac();
                console.table(this.FAQS);
                this.loading = false; 
              }); 
  }

  public getCategorieFaq(){
    this.loading = true;
   // this.CategorieFaq =[];
    this.error = "";
     this.faqRep
          .getcategorieFaq()
            .subscribe(
              (response) => {                           //next() callback
                this.cats = response; 
                document.body.scrollTop = 20; // For Safari
                document.documentElement.scrollTop = -20; // For Chrome, Firefox, IE and Opera
                document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Opera
        },
              (error) => {                              //error() callback
                //console.error('Request des categories  failed with error')
                this.error = error;
                this.loading = false;
              },
              () => {                                   //complete() callback
               // console.info('Request des  Categories completed')      //This is actually not needed 
                this.loading = false; 
               this.getfaqBycat(Number(this.cats[1].idcategoriefaq))
              }); 
  }





  
fullfilFac(){
  this.Faqcpy = [];
  let i=0;
  for(let f of this.Faqbycat){
    switch (i) {
      case 0:
          f["id"]="headingOne";   f["labelid"]="collapseOne"; f["visible"]="collapse show";  f["controls"]="collapseOne"; 
          break;
      case 1:
          f["id"]="headingTwo";   f["labelid"]="collapseTwo"; f["visible"]="collapse show";  f["controls"]="collapseOne"; 
          break;
      case 2:
          f["id"]="headingThree";   f["labelid"]="collapseThree"; f["visible"]="collapse show";  f["controls"]="collapseOne"; 
          break;
      case 3:
          f["id"]="headingFour";   f["labelid"]="collapseFour"; f["visible"]="collapse show";  f["controls"]="collapseOne"; 
          break;
      case 4:
          f["id"]="headingFive";   f["labelid"]="collapseFive"; f["visible"]="collapse show";  f["controls"]="collapseOne"; 
          break;
      default:
          break;
  }
  this.Faqcpy.push(f);  i++;
  }
 
}
fullfilPopFac(){
  let FaqPopcpy = [];
  FaqPopcpy = this.Faq;
  let i=0;
  for(let f of FaqPopcpy){
    switch (i) {
      case 0:
        f["id"]="headingSix";   f["labelid"]="collapseSix"; f["visible"]="collapse show";  f["controls"]="collapseSix"; 
          break;
      case 1:
        f["id"]="headingSeven";   f["labelid"]="collapseSeven"; f["visible"]="collapse show";  f["controls"]="collapseSeven"; 
          break;
      case 2:
        f["id"]="headingEight";   f["labelid"]="collapseEight"; f["visible"]="collapse show";  f["controls"]="collapseEight"; 
          break;
      case 3:
        f["id"]="headingNine";   f["labelid"]="collapseNine"; f["visible"]="collapse show";  f["controls"]="collapseNine"; 
          break;
      case 4:
        f["id"]="headingTen";   f["labelid"]="collapseTen"; f["visible"]="collapse show";  f["controls"]="collapseTen"; 
          break;
      default:
          break;
  }
  FaqPopcpy.push(f);  i++;
  }
  this.FaqPop = FaqPopcpy;
  console.log('***************************************');console.table(this.FaqPop);console.log('***************************************');
}
}
