import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 menuoverlay='';
 showmenuwraper='';
 items = [
  { title: 'Profile' },
  { title: 'Logout' },
];

  constructor() { }
showmenu(){

  this.menuoverlay='active';
  this.showmenuwraper='show-offcanvas-menu-wrapper'
}
closemenu(){

  this.menuoverlay='';
  this.showmenuwraper=''
}
  ngOnInit(): void {
  }

}
